import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { noop, map, isEmpty } from "lodash";
import { Button } from "../../components/base";
import { normalizeAmount, commaFormatted } from "../../utils/transformer.util";
import { Select, FileInput } from '../../components/base/index';

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class ImportPendaftaranSiswa extends PureComponent {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this.getYearsOfEducationsOptions = this.getYearsOfEducationsOptions.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    this.onUpload = this.onUpload.bind(this);
    this.onRemoveFile = this.onRemoveFile.bind(this);
    this._initializeData = this._initializeData.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id, va_code } = school_unit;

    this.state = {
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {
          periods_id: "",
          organizations_id,
          units_id: school_unit.id,
          levels_id,
          va_code,
          levels: [],
          classrooms_id: "",
        },
        error: {
          classrooms: "",
          levels: "",
        },
      },
      param: {},
      filtered: false,
    };
  }

  componentDidMount() {
    this._initializeData();
  }

  async _initializeData() {
    const { user } = this.props;
    const { content } = this.state;
    const { school_unit } = user;
    await this.getYearsOfEducationsOptions({ units_id: school_unit.id });
    await this._getPeriodsOptions({ units_id: school_unit.id });
  }

  onSubmit(e) {
    e.preventDefault();
    const { form } = this.state;
    const { handleUploadExcelStudents, history } = this.props;

    const res = handleUploadExcelStudents(form.value);
    if (res) {
      console.log(res);
      window.scrollTo(0, 0);
      this.setState({
        page: 1,
      });
      this.onRemoveFile();
    }
  }

  async getYearsOfEducationsOptions(filters = {}) {
    const { getYearsOfEducationsOptions } = this.props;
    const res = await getYearsOfEducationsOptions(filters);

    map(res, (data, idx) => {
      var date = new Date();
      var start_date = new Date(data.attributes.start_date);
      if (date > start_date) {
        // tahun ajaran sekarang
        this.setState((prevState) => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            entrance_year: data.value,
          },
          form: {
            ...prevState.form,
            value: {
              ...prevState.form.value,
              entrance_year: data.value,
            },
          },
        }));
      }
    });

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async _getPeriodsOptions(filters = {}) {
    const { getPeriodsOptions } = this.props;
    filters.organizations_id = 3;
    const res = await getPeriodsOptions(filters);

    map(res, (data, idx) => {
      var date = new Date();
      var start_date = new Date(data.attributes.start_date);
      if (date > start_date) {
        // tahun ajaran sekarang
        this.setState((prevState) => ({
          ...prevState,
          form: {
            ...prevState.form,
            value: {
              ...prevState.form.value,
              periods_id: data.value,
            },
          },
        }));
      }
    });
  }

  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked;
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
    );
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
    );
  }

  async onUpload(event) {
    const { name, value, dataset, files } = event.target;
    const { inputType = "text" } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      if (inputType === "number") {
        formattedValue = commaFormatted(value);
      }
      if (inputType === "file") {
        formattedValue = files[0];
      }

      return {
        form: {
          value: {
            ...prevState.form.value,
            [name]: formattedValue,
          },
          error: {
            ...prevState.form.error,
            [name]: "",
          },
        },
      };
    });
  }

  onRemoveFile(event) {
    this.setState((prevState) => {
      return {
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            file: "",
          },
        },
      };
    });
  }

  render() {
    const { param, filters } = this.state;
    const { entrance_year = "" } = filters;

    return (
      <div className="fund-request">
        <br></br>
        <div className="isi-bidang__custom-form">
          <div className="isi-bidang__custom-form-column">
            <div className="isi-bidang__custom-form-row__field">
              <Select
                name="entrance_year"
                label="Tahun Ajaran"
                placeholder={"Pilihan"}
                onChange={this._onChangeFilter}
                data={param.periods}
                // value={entrance_year?entrance_year:years_now}
                value={entrance_year}
                disabled
              />
              <div className="isi-bidang__custom-form-row__field"></div>
            </div>
          </div>
        </div>
        <form onSubmit={this.onSubmit}>
          <br></br>
          <div className="manage-registration__custom-form-row">
            <div className="manage-registration__custom-form-row__field">
              <FileInput
                data-input-type="file"
                noMargin
                type="file"
                name="file"
                label="Upload File Excel"
                onChange={this.onUpload}
                onRemoveFile={this.onRemoveFile}
                // fileName={value.file ? value.file : ""}
                // displayName={
                //   value.file ? value.file : "default"
                // }
              />
            </div>
            <div className="manage-registration__custom-form-row__field"></div>
          </div>
          <br></br>
          <div className="add-upload-rapbu">
            <Button type="submit" title="Simpan" />
          </div>
        </form>
      </div>
    );
  }
}
ImportPendaftaranSiswa.propTypes = {
  handleDelete: PropTypes.func,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
  onRemoveFile: PropTypes.func,
  onFormChange: PropTypes.func,
};
ImportPendaftaranSiswa.defaultProps = {
  handleDelete: noop,
  user: null,
  onFormChange: noop,
  onRemoveFile: noop,
};
