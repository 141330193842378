import language from '../../languages/index';

export const FINANCE_CASH_JOURNAL_FORM_FIELDS = [{
  type: 'date',
  name: 'date',
  label: 'Tanggal',
}, {
  type: 'select',
  name: 'type',
  data: [
    { label: 'Kas Masuk', value: 'KAS_MASUK' },
    { label: 'Kas Keluar', value: 'KAS_KELUAR' },
  ],
  label: language.translate.FINANCE_BOOKKEEPINGCASH_JOURNAL_TRANSACTION_TYPE_LABEL,
  placeholder: 'Pilih tipe',
}, {
  type: 'text',
  name: 'number',
  label: 'No. Bukti',
  placeholder: 'Diisi otomatis oleh sistem',
  disabled: true,
}, {
  type: 'text',
  name: 'submitted_by',
  label: 'Diserahkan oleh',
  placeholder: 'Isi diserahkan oleh',
}, {
  type: 'text',
  name: 'received_by',
  label: 'Diterima oleh',
  placeholder: 'Isi diterima oleh',
}, {
  type: 'select',
  name: 'tipe',
  data: [
    { label: 'Standard', value: '1' },
    { label: 'Rekonsiliasi', value: '2' },
  ],
  label: 'Tipe',
  placeholder: 'pilih tipe',
}];

export const FINANCE_CASH_JOURNAL_RECONCILIATION_FORM = {
  tableHead: ['No.', 'Kode', 'Keterangan', 'Nominal', 'Unit Lawan Transaksi'],
  name: [{}, {
    type: 'selectCoa',
    name: 'code_of_account',
    label: 'Kode Akun',
    placeholder: 'Pilih kode akun',
  }, {
    name: 'description',
  }, {
    type: 'text',
    inputType: 'number',
    name: 'nominal',
  }, {
    name: 'transaction',
  }],
};

export const FINANCE_CASH_JOURNAL_STANDARD_FORM = {
  tableHead: ['No.', 'Kode', 'Keterangan', 'Nominal'],
  name: [{}, {
    type: 'selectCoa',
    name: 'code_of_account',
    label: 'Kode Akun',
    placeholder: 'Pilih kode akun',
  }, {
    name: 'description',
  }, {
    type: 'text',
    inputType: 'number',
    name: 'nominal',
  }],
};

export const FINANCE_CASH_JOURNAL_TABLE_FIELDS = {
  label: [
    'No',
    'Tanggal',
    'Nomor Journal',
    'Disubmit Oleh',
    'Diterima Oleh',
    '',
  ],
  value: [
    {},
    { type: 'date', attribute: 'date' },
    { type: 'string', attribute: 'journal_number' },
    { type: 'string', attribute: 'submitted_by' },
    { type: 'string', attribute: 'accepted_by' }],
};

export const FINANCE_BANK_JOURNAL_FORM_FIELDS = [{
  type: 'date',
  name: 'date',
  label: 'Tanggal',
}, {
  type: 'select',
  name: 'type',
  data: [
    { label: 'Bank Masuk', value: 'BANK_MASUK' },
    { label: 'Bank Keluar', value: 'BANK_KELUAR' },
  ],
  label: language.translate.FINANCE_BOOKKEEPINGBANK_JOURNAL_TRANSACTION_TYPE_LABEL,
  placeholder: 'Pilih tipe',
}, {
  type: 'text',
  name: 'number',
  label: 'No. Bukti',
  placeholder: 'Diisi otomatis oleh sistem',
  disabled: true,
}, {
  type: 'text',
  name: 'submitted_by',
  label: 'Diserahkan oleh',
  placeholder: 'Isi diserahkan oleh',
}, {
  type: 'text',
  name: 'received_by',
  label: 'Diterima oleh',
  placeholder: 'Isi diterima oleh',
}, {
  type: 'searchSelect',
  name: 'rekening',
  label: 'Rekening',
}, {
  type: 'select',
  name: 'tipe',
  data: [
    { label: 'Standard', value: '1' },
    { label: 'Rekonsiliasi', value: '2' },
  ],
  label: 'Tipe',
  placeholder: 'pilih tipe',
}];

export const FINANCE_BANK_JOURNAL_RECONCILIATION_FORM = {
  tableHead: ['No.', 'Kode', 'Keterangan', 'Nominal', 'Unit Lawan Transaksi'],
  name: [{}, {
    type: 'selectCoa',
    name: 'code_of_account',
    label: 'Kode Akun',
    placeholder: 'Pilih kode akun',
  }, {
    name: 'description',
  }, {
    type: 'text',
    inputType: 'number',
    name: 'nominal',
  }, {
    name: 'transaction',
  }],
};

export const FINANCE_BANK_JOURNAL_STANDARD_FORM = {
  tableHead: ['No.', 'Kode', 'Keterangan', 'Nominal'],
  name: [{}, {
    type: 'selectCoa',
    name: 'code_of_account',
    label: 'Kode Akun',
    placeholder: 'Pilih kode akun',
  }, {
    name: 'description',
  }, {
    type: 'text',
    inputType: 'number',
    name: 'nominal',
  }],
};

export const FINANCE_BANK_JOURNAL_TABLE_FIELDS = {
  label: [
    'No',
    'Tanggal',
    'Nomor Journal',
    'Disubmit Oleh',
    'Diterima Oleh',
    '',
  ],
  value: [
    {},
    { type: 'date', attribute: 'date' },
    { type: 'string', attribute: 'journal_number' },
    { type: 'string', attribute: 'submitted_by' },
    { type: 'string', attribute: 'accepted_by' }],
};
export const ADD_FOLDER_FROM_FIELDS = [{
  type: 'text',
  name: 'folder_code',
  label: 'Kode Folder *',
  full: true,
}, {
  type: 'text',
  name: 'folder_name',
  label: 'Nama Folder *',
  full: true,
}, {
  type: 'text',
  name: 'folder_description',
  label: 'Deskripsi Folder *',
  full: true,
}, {
  type: 'select',
  name: 'folder_active',
  data: [
    { label: 'Y', value: 'y' },
    { label: 'N', value: 'n' },
  ],
  label: 'Folder Aktif *',
  full: true,
  placeholder: 'pilih Folder Aktif',
}];
export const LIST_TAMBAH_JURUSAN_LIST_TABLE_FIELDS = {
  label: [
    'No',
    'Jurusan',
    'Kelompok',
    'Opsi',
  ],
  value: [
    {},
    { type: 'string', attribute: 'major' },
    { type: 'string', attribute: 'major_groups_name' },
  ],
};
export const LIST_KENAIKAN_KELAS_LIST_TABLE_FIELDS = {
  label: [
    'No',
    'NISN',
    'No VA',
    'Nama',
    'Akan Naik ke Kelas',
    'Sisa Kursi',
  ],
  value: [
    {},
    { type: 'string', attribute: 'nisn' },
    { type: 'string', attribute: 'no_va' },
    { type: 'string', attribute: 'name' },
    { type: 'string', attribute: 'classrooms_id'},
    { type: 'string', attribute: 'capacity'},
  ],
};

export const LIST_CREATE_INOVICE_LIST_TABLE_FIELDS = {
  label: [
    'No',
    'NISN',
    'No VA',
    'Nama Siswa',
    'Tahun Akademik',
    'Juli',
    'Agustus',
    'September',
    'Oktober',
    'November',
    'Desember',
    'Januari',
    'Februari',
    'Maret',
    'April',
    'Mei',
    'Juni',
    'Total Tagihan',


  ],
  value: [
    {},
    { type: 'string', attribute: 'nisn' },
    { type: 'string', attribute: 'no_va' },
    { type: 'string', attribute: 'name' },
    { type: 'string', attribute: 'academics_year'},
    { type: 'string', attribute: 'status', value: 'Juli' },
    { type: 'string', attribute: 'status', value: 'Agustus' },
    { type: 'string', attribute: 'status', value: 'September' },
    { type: 'string', attribute: 'status', value: 'Oktober' },
    { type: 'string', attribute: 'status', value: 'November' },
    { type: 'string', attribute: 'status', value: 'Desember' },
    { type: 'string', attribute: 'status', value: 'Januari' },
    { type: 'string', attribute: 'status', value: 'Februari' },
    { type: 'string', attribute: 'status', value: 'Maret' },
    { type: 'string', attribute: 'status', value: 'April' },
    { type: 'string', attribute: 'status', value: 'Mei' },
    { type: 'string', attribute: 'status', value: 'Juni' },
    { type: 'string', attribute: 'nominal'},
  ],
};

export const LIST_TAMBAH_TAHUN_AJARAN_LIST_TABLE_FIELDS = {
  label: [
    'No',
    'Tahun Ajaran',
    'Mulai',
    'Sampai',
    'Opsi',
  ],
  value: [
    {},
    { type: 'string', attribute: 'name_period' },
    { type: 'date', attribute: 'start_date' },
    { type: 'date', attribute: 'end_date' },

  ],
};

