import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop, map } from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted,normalizeAmount, formatData } from '../../../utils/transformer.util';
import { Button, Pagination } from '../../../components/base/index';
import language from '../../../languages';
import {ADD_FOLDER_FROM_FIELDS} from '../../../constants/Akademi/Akademi.constant';
import { FOLDER_LIST_TABLE_FIELDS } from '../../../constants/kurikulum/kurikulum.constant';

export default class TambahFolder extends Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this._setForm = this._setForm.bind(this);
        this._onClickSubmit = this._onClickSubmit.bind(this);
        this._getParamOptions = this._getParamOptions.bind(this);
        this._getStudentOptions = this._getStudentOptions.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
        this._onClearForm = this._onClearForm.bind(this);
        this.onClickEdit = this.onClickEdit.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
        this._renderButtons = this._renderButtons.bind(this);
        this.onChangePage = this.onChangePage.bind(this);


        this.state= {
            loading: true,
            list: [],
            listAmount: 5,

          form: {
            value: {
              folder_code: '',
              folder_name:'',
              folder_description:'',
              folder_active: '',
              organizations_id: '3',
            },
            error: {},
          },
          param: {},
        };

      }

      componentDidMount() {
        const { location } = this.props;
        const { state = {} } = location;
        const {isEdit =  false, data} = location.state;
        const { id } = state.data || '';
       
        const paramTypes = ['classrooms', 'levels', 'classes'];
        if (isEdit && id) {
          this.setState({id:id})
          this._setForm(data.id);
        }
        
        this.handleGetData();
        // if (id) {
        //   this.setState({id:id})
        //   this._handleLoadData(id)
        // }
        

      }

      async _setForm(id) {
        const { handleGetAnnouncementFolder } = this.props;
        try {
          const payload = await handleGetAnnouncementFolder({ id });
          this.setState({
            form: {
              value: {
                id: payload.id,
                folder_code: payload.folder_code,
                folder_name:payload.folder_name,
                folder_description: payload.folder_description,
                folder_active: payload.folder_active,
                
              },
              error: {
                // details: [],
              },
            },
          });
        } catch (err) {
          // err action
        }
      }

      async handleGetData(payload = {}) {
        const { handleListAnnouncementFolder, user } = this.props;
        const { units_id, workingUnit, organizations_id } = user;
        let unit_id = units_id;
    
        if (unit_id === null && workingUnit && workingUnit.id) {
          unit_id = workingUnit.id;
        }
    
        const res = await handleListAnnouncementFolder({...payload, organizations_id: 3});
    
        this.setState({
          list: res,
        });
        console.log(this.state.list)
      }

      onClickEdit(val) {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/tambah-folder', { data: val, isEdit: true });
        history.go(0)
      }


      async onClickDelete(id) {
        const { handleDeleteAnnouncementFolder } = this.props;
        const res = await handleDeleteAnnouncementFolder(id);
        if(res){
          this.handleGetData();
        }
      }



      onChangePage(page) {
        const { listAmount, keywords } = this.state;
        const offset = listAmount * (page - 1);
        this.handleGetData({
          limit: listAmount,
          keywords,
          offset,
          page,
        });
      }

      _onClearForm() {
        this.setState({
          form: {
            value: {},
            error: {},
          },
        });
      }

      _renderButtons(data) {
        const { user } = this.props;
        const button = {};
        
        return (
          <td>
            <div className="table__actions">
             
              <Button
                onClick={() => this.onClickEdit(data)}
                title='Edit'
              />
              <Button
                onClick={() => this.onClickDelete(data)}
                title='Hapus'
              />
            </div>
          </td>
        );
      }
      
  componentDidUpdate(prevProps, prevState) {
    const { form: prevForm } = prevState;
    const { value: prevValue } = prevForm;
    const { form } = this.state;
    const { value } = form;
    const paramType = ['levels', 'classrooms', 'classes'];
    paramType.forEach((type) => {
      let filter = '';
      let paramName = ''
      if (prevValue[type] !== value[type]) {
        if (type === 'levels') {
          filter = 'levels_id';
          paramName = 'levels';
        } else if (type === 'classrooms') {
          filter = 'classrooms_id';
          paramName = 'classrooms';
        } 
        else {
          filter = 'classes_id';
          paramName= 'classes';
        }
        this._getParamOptions( paramName, { filters: { [filter]: value[type] }} );
      }
    });
  }

      async _getStudentOptions(classrooms) {
        const { getStudentOptions } = this.props;
        const res = await getStudentOptions(classrooms);
        // console.log(res)
        this.setState(prevState => ({
          ...prevState,
          param: {
            ...prevState.param,
            student: res,
          },
        }));
       
      }

      async _getParamOptions(type, filters={}) {
        const { getParamOptions } = this.props;
        const res = await getParamOptions(filters, type);
        this.setState(prevState => ({
          ...prevState,
          param: {
            ...prevState.param,
            [type]: res,
          },
        }));
      }
      async onSubmit() {
        const { history, handleSaveAnnouncementFolder } = this.props;
        const { form } = this.state;
        const { value } = form;        
        let canSaveA = false;
        const dataForm = form.value;
        if(dataForm.folder_code && dataForm.folder_name && dataForm.folder_description && dataForm.folder_active){
          canSaveA = true;
        } else {
          canSaveA = false;
        }
        if (canSaveA == false){
          alert("Harap lengkapi data form. Bagian yang harus diisi ditandai dengan tanda bintang (*).");
        } else {
          await handleSaveAnnouncementFolder(value);
          history.push('/dashboard/kurikulum/pengumuman');
        }
      }
      async onEdit() {
        const { history, handleEditAnnouncementFolder } = this.props;
        const { form } = this.state;
        const { value } = form;
        const res = await handleEditAnnouncementFolder(value);        
        history.push('/dashboard/kurikulum/pengumuman');
      }
      _onClickSubmit() {
        const { history } = this.props;
        //history.push('/dashboard/kurikulum/laporan/daftar-nilai-siswa-ktsp');
        //history.push('/dashboard/kurikulum/laporan/daftar-nilai-siswa-k13');
        //history.push('/dashboard/kurikulum/laporan/pengumuman');
        // history.push('/dashboard/kurikulum/laporan/psikomotorik-siswa');
      }
      _onFormChange(event) {
        const {
          name,
          value,
          dataset,
          checked,
          type,
          files
        } = event.target;
        const {
          inputType = 'text', inputArray = false, arrayPosition = 0,
          fieldName,
        } = dataset;

        this.setState((prevState) => {
          let newList = [];
          let newListError = [];
          let formattedValue = value;          
          if(type==="file"){
            formattedValue = files[0]
          }
          if (inputType === 'number') {
            formattedValue = normalizeAmount(value);
          }
          if (inputArray) {
            if (type === 'checkbox') {
              formattedValue = checked;
            }
            newList = prevState.form.value[fieldName];
            newListError = prevState.form.error[fieldName];
            newList[arrayPosition][name] = formattedValue;
            if (name === 'code_of_account') {
              newList[arrayPosition].isCredit = value.type;
            }
            if (!isEmpty(newListError[arrayPosition])) {
              newListError[arrayPosition][name] = '';
            }
          }
          return {
            form: {
              value: {
                ...prevState.form.value,
                ...(inputArray
                  ? { [fieldName]: newList }
                  : { [name]: formattedValue }),
              },
              error: {
                ...prevState.form.error,
                ...(inputArray
                  ? { [fieldName]: newListError }
                  : { [name]: '' }),
              },
            },
          };
        }, () => {
          if (name === 'classrooms') {
            this._getStudentOptions(value);
          }
        });
      }
      render() {
        const { list = {} } = this.state;
        const {form, param, name, publish_date, tgl_selesai, levels, classrooms, student} = this.state;
        const { handleGetKelas, handleUploadAnnouncement, location} = this.props;
        const { isEdit = true } = location.state;
        return (
          <div className="student-list">
             <form>
               {this.state.id?
               <h1>Edit Folder</h1>
               :
               <h1>Tambah Folder</h1>
               }
              
              {/* <AddFolderForm
              form={form}
              onFormChange={this._onFormChange}
              param={param}
              handleUploadAnnouncement = {handleUploadAnnouncement}
              /> */}
              <ListForm
              form={form}
              onFormChange={this._onFormChange}
              formFields={ADD_FOLDER_FROM_FIELDS}
              />
              <br></br>
              <div className="manage-registration__footer">
                {this.state.id?
              <Button
                type="button"
                title="Update"
                onClick={this.onEdit}
              />:
              <Button
                type="button"
                title="Simpan"
                onClick={this.onSubmit}
              />}
                </div>
                </form>

                <div className="fund-request__content">
          <table className="table">
            <thead>
              <tr>
                {
                    map(FOLDER_LIST_TABLE_FIELDS.label, (field, idx) => (
                      <th key={`table_th_${idx}`}>{field}</th>
                   ))
                  }
              </tr>
            </thead>
            <tbody>
              {
                  map(list.result, (data, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {
                      map(FOLDER_LIST_TABLE_FIELDS.value, (field, fieldIdx) => {
                        if (fieldIdx === 0) {
                          return (
                            <td key={`table_index_${fieldIdx}_${idx}`} style={{textAlign:"center"}}>{idx + 1}</td>
                          );
                        }
                        return (
                          <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                            {formatData(data, field)}
                          </td>
                        );
                      })
                    }
                      {this._renderButtons(data)}
                    </tr>
                  ))
                }
            </tbody>
          </table>
        </div>
        <div className="user-management__table-footer">
          <p className="user-management__page-info">
            {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${list.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${list.totalPage}`}
          </p>
          <Pagination
            totalPage={list.totalPage}
            currentPage={list.currentPage}
            onClick={this.onChangePage}
          />
        </div>
          </div>
        );
      }
}
TambahFolder.propTypes = {
  getParamOptions: PropTypes.func,
  handleListAnnouncementFolder: PropTypes.func,
  handleGetAnnouncement: PropTypes.func,
  getStudentOptions: PropTypes.func,
  handleGetAnnouncementFolder: PropTypes.func,
  history: PropTypes.object.isRequired,
};
TambahFolder.defaultProps = {
  handleListAnnouncementFolder: noop,
  getStudentOptions: noop,
  getParamOptions: noop,
  handleGetStudent: noop,
  handleGetAnnouncementFolder: noop,
};
