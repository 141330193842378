import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, isEqual, map, capitalize } from "lodash";
import { validateRequiredFields } from "../../utils/validation.util";
import { normalizeAmount, formatData } from "../../utils/transformer.util";
import {
  Button,
  Select,
  Pagination,
  SearchSelect,
  Input,
} from "../../components/base/index";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MySwal = withReactContent(Swal);

export default class ResetPass extends Component {
  constructor(props) {
    super(props);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this.onReset = this.onReset.bind(this);
    this._getUsernameOptions = this._getUsernameOptions.bind(this);

    const { user } = this.props;
    const { school_unit } = user;
    this.state = {
      page: 1,
      filters: {},
      form: {
        value: {
          listuser: [],
        },
        error: {},
      },
      param: {},
    };
  }

  componentDidMount() {
    this._onSearchContent()
    this._getUsernameOptions();
  }

  // componentDidUpdate(prevProps) {
  //   // const { user } = this.props;
  //   // const { workingUnit } = user;
  //   // const { user: prevUser } = prevProps;
  //   // const { workingUnit: prevWorkingUnit } = prevUser;

  //   // if (!isEqual(workingUnit, prevWorkingUnit)) {
  //   //   this.handleGetData();
  //   // }
  //   if (!isEqual(prevProps, this.props)) {
  //     this._onSearchContent({ page: 1 });
  //   }
  // }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    if (name === "user_groups_id") {
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          username: "",
        }
      }));
    }
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        if (name === "user_groups_id") {
          this._getUsernameOptions();
        }
        this._onSearchContent();
      }
    );
  }

  async _onSearchContent(params = {}) {
    const { filters } = this.state;

    if (filters.user_groups_id === "") {
      delete filters.user_groups_id;
    }
    if (filters.username === "" || !isEmpty(filters.username) && filters.username.value === "") {
      delete filters.username;
    }

    this.setState(
      async () => {
        try {
          const {
            handleListResetPassword,
            user,
          } = this.props;
          const { school_unit, organizations_id } = user;
          const result = await handleListResetPassword({
            organizations_id: organizations_id,
            units_id: school_unit.id,
            filters,
          });
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listuser: result,
              },
            },
          }));
        } catch (err) {
          // if (this.isMount) {
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listuser: [],
              },
            },
          }));
        }
        // }
      }
    );
  }

  async onReset(data) {
    const { handleResetPassword } = this.props;
    // MySwal.fire({
    //   title: <p>Rincian Pembayaran</p>,
    //   // showCancelButton: false,
    //   // showConfirmButton: false,
    //   title: "Reset Kata Sandi",
    //   text: "Username : " + data.username + "<br> Nama",
    //   icon: 'warning',
    //   showCancelButton: true,
    //   showConfirmButton: true,
    // });
    const res = await handleResetPassword(data);
  }

  async _getUsernameOptions() {
    const { getUsernameOptions, user } = this.props;
    const { school_unit } = user;
    const { filters } = this.state;
    if (filters.user_groups_id === "") {
      delete filters.user_groups_id
    }
    const res = await getUsernameOptions({
      units_id: school_unit.id,
      filters,
    });
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        user: res,
      },
    }));
  }

  _renderButtons(data) {
    const { user } = this.props;

    return (
      <td>
        <div className="table__actions">
          <Button onClick={() => this.onReset(data)} title="Reset" />
        </div>
      </td>
    );
  }

  render() {
    const {
      form,
      filters,
      param,
    } = this.state;
    const { listuser } = form.value;

    const prm_user = [
      { label: 'User Siswa', value: '1'},
      { label: 'User Guru', value: '2'},
    ];

    return (
      <div className="manage-registration">
        <h1>Atur Ulang Kata Sandi</h1>
        <hr></hr>
        <div className="absensi-rekap__custom-form">
          <div className="absensi-rekap__custom-form-row">
            <div className="absensi-rekap__custom-form-row__field">
              <Select
                name="user_groups_id"
                label="Pilih Jenis User"
                onChange={this._onChangeFilter}
                data={prm_user}
                placeholder={"Pilihan"}
                value={filters.user_groups_id}
              />
            </div>
            <div className="absensi-rekap__custom-form-column__field">
              <SearchSelect
                noMargin
                async={false}
                name="username"
                list={param.user}
                inputArray
                onClick={this._onChangeFilter}
                placeholder="Pilihan"
                value={filters.username}
                rightIcon="icon-search"
                label="Pilih User"
              />
            </div>
          </div>
        </div>
        <div className="manage-registration">
          <div className="fund-request__content">
            <table className="table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Username</th>
                  <th>Nama</th>
                  <th>Opsi</th>
                </tr>
              </thead>
              <tbody>
                {map(listuser, (list, idx) => (
                  <tr key={`budget_row_${idx}`}>
                    <td style={{ textAlign: "center" }} key={`table_$${idx}`}>
                      {idx + 1}
                    </td>
                    <td key={`table_${idx}`} style={{textAlign:"center"}}>
                      {list.username}
                    </td>
                    <td key={`table_${idx}`} style={{textTransform:"capitalize"}}>
                      {capitalize(list.name)}
                    </td>
                    {this._renderButtons(list)}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
ResetPass.propTypes = {
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
ResetPass.defaultProps = {
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
