import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, keys } from 'lodash';
import logo from '../../assets/img/Spin.png';
import { HOME_INFO } from '../../variables/home.variable';
import language from '../../languages';

class Home extends Component {
  render() {
    const { user } = this.props;
    const { school_unit, workingUnit = {}, perwakilan = {} } = user;
    let unitName = '';
    if(keys(workingUnit).length > 0) {
      unitName = workingUnit.title;
    } else if(keys(perwakilan).length > 0) {
      unitName = perwakilan.name;
    } else {
      unitName = school_unit ? school_unit.name : '';
    }
    console.log(school_unit.phone)

    return (
      <div className="home">
        <img 
          // className="home__logo" 
          src={logo} alt="Logo" width={320} height={220}/>
        <div className="home__info">
          {
            <table>
              <tbody>
                <tr>
                  <td style={{verticalAlign:"top", whiteSpace: "nowrap"}}><h1>Unit Kerja</h1></td>
                  <td style={{verticalAlign:"top", padding:"0 15px"}}><h1>:</h1>  </td>
                  <td style={{verticalAlign:"top"}}><h1>{unitName}</h1></td>
                </tr>
                {school_unit.address?
                <tr>
                <td style={{verticalAlign:"top", whiteSpace: "nowrap"}}><h1>Alamat</h1></td>
                <td style={{verticalAlign:"top", padding:"0 15px"}}><h1>:</h1> </td>
                <td style={{verticalAlign:"top"}}><h1>{school_unit.address}</h1></td>
                </tr>:null
                }
                {school_unit.phone?
                <tr>
                <td style={{verticalAlign:"top", whiteSpace: "nowrap"}}><h1>No. Telp</h1></td>
                <td style={{verticalAlign:"top", padding:"0 15px"}}><h1>:</h1></td>
                <td style={{verticalAlign:"top"}}><h1>{school_unit.phone}</h1></td>
                </tr>:null
                }
              {school_unit.email?
                <tr>
                  <td style={{verticalAlign:"top", whiteSpace: "nowrap"}}><h1>Email</h1></td>
                  <td style={{verticalAlign:"top", padding:"0 15px"}}><h1>:</h1></td>
                  <td style={{verticalAlign:"top"}}><h1>{school_unit.email}</h1></td>
                </tr>:null
              }
              {school_unit.website?
                <tr>
                <td style={{verticalAlign:"top", whiteSpace: "nowrap"}}><h1>Website</h1></td>
                <td style={{verticalAlign:"top", padding:"0 15px"}}><h1>:</h1></td>
                <td style={{verticalAlign:"top"}}><h1>{school_unit.website}</h1></td>
              </tr>:null
              }
              </tbody>
            </table>

            // map(HOME_INFO, (list, index) => (
            //   <div key={`home__info__${index}`} className="home__info-list">
            //     <p>{list.label}</p>
            //     {
            //       (list.label == language.translate.HOME__INFO__LABEL_1)? (
            //         <p>:{unitName}</p>
            //       ):''
            //     }
            //     {
            //       (list.label == language.translate.HOME__INFO__LABEL_2)? (
            //         <p>:{school_unit.address}</p>
            //       ):''

            //     }
            //     {
            //       (list.label == language.translate.HOME__INFO__LABEL_3)? (
            //         <p>:{school_unit.phone}</p>
            //       ):''

            //     }
            //      {
            //       (list.label == language.translate.HOME__INFO__LABEL_4)? (
            //         <p>:{school_unit.fax}</p>
            //       ):''
            //     }
            //      {
            //       (list.label == language.translate.HOME__INFO__LABEL_5)? (
            //         <p>:{school_unit.website}</p>
            //       ):''
            //     }
            //       {
            //       (list.label == language.translate.HOME__INFO__LABEL_6)? (
            //         <p>:{school_unit.email}</p>
            //       ):''
            //     }



            //      {/* {
            // //       ((unitName !== '')) && (
            // //         // <p>: {unitName}</p>
            // //         <p></p>
            // //       )
            // //     } */}
            //      {
            //       ((school_unit === null)) && (
            //         <p></p>
            //       )
            //     }
            //   </div>
            // ))
          }
        </div>
      </div>
    );
  }
}

Home.propTypes = {
  user: PropTypes.object.isRequired,
};

export default function App(props) {
  return (
    <Home {...props} />
  );
}
