import { connect } from 'react-redux';
import DaftarPesertaEkskul from '../../../views/Student/ekskul/DaftarPesertaEkskul.student.view';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses, getParamOptions, getPeriodsOptions, getParamOptionsAcademics, getPrmLevels } from '../../../states/thunks/options.thunk';
import { listStudentEkskul, saveAttendance, editAttendance, getAttendance } from '../../../states/thunks/academics.thunk';

function mapStateToProps(state){
  return{
    classes: state.options.classes,
    result: state.result,
    user: state.user || {},
  }
}

function mapDispatchToProps(dispatch) {
  return {
    handleListlistStudentEkskul: (payload, goback) => dispatch(listStudentEkskul(payload, goback)),
    handleGetClasses: payload => dispatch(getClasses(payload)),
    handleGetStudent: () => dispatch(getStudent()),
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DaftarPesertaEkskul);


