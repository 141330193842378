import { connect } from 'react-redux';
import MutasiKeluar from '../../../views/Student/DataSiswa/MutasiKeluar.student.view';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses } from '../../../states/thunks/options.thunk';
import { getParamOptions, getStudentMoveOptions, getStudentOptions, getParamOptionsAcademics, getPeriodsOptions } from '../../../states/thunks/options.thunk';
import {listMoveRecord} from '../../../states/thunks/academics.thunk';


function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    user: state.user,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    handleGetClasses: payload => dispatch(getClasses(payload)),
    handleGetStudent: () => dispatch(getStudent()),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleListMoveRecord: (payload, goback) => dispatch(listMoveRecord(payload, goback)),
    // getStudentOptions: (payload) => dispatch(getStudentOptions(payload)),
    getStudentMoveOptions: (payload) => dispatch(getStudentMoveOptions(payload)),
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MutasiKeluar);

