import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map } from "lodash";
import {
  commaFormatted,
  normalizeAmount,
} from "../../../../utils/transformer.util";
import {
  Select,
  SearchSelect
} from "../../../../components/base";

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class RincianPembayaran extends PureComponent {
  constructor(props) {
    super(props);

    this._onFormChange = this._onFormChange.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._getStudentSPPOptions = this._getStudentSPPOptions.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._getYearsOfEducationsOptions = this._getYearsOfEducationsOptions.bind(this);

    const { user } = this.props;
    const { school_unit, profile } = user;
    const { id, levels_id } = school_unit;
    this.state = {
      expanded: null,
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {
          total: "",
          classes: [],
          levels_id,
          periods: "",
          listhistory: [],
          listhistory2: [
            {
              paymentdetails: [],
            },
          ],
          classes_id: "",
          payload: [],
        },
        error: {
          classes: "",
          levels: "",
          listhistory: "",
          listhistory2: "",
        },
      },
      param: {},
      filtered: false,
    };
    this.checkboxRefs = [];
  }

  
  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  componentDidMount() {
    const { user } = this.props;
    const { school_unit } = user;
    const paramTypes = ["classes", "levels", "classrooms"];
    const { content } = this.state;
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
    this._getStudentSPPOptions({units_id: [school_unit.id] });
    this._getYearsOfEducationsOptions({units_id: school_unit.id});
  }

  // componentDidUpdate(prevProps) {
  //   if (!isEqual(prevProps, this.props)) {
  //     this._onSearchContent({ page: 1 });
  //   }
  // }

  _onFormChange(event) {
    const { name, value, dataset} = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {}
    );
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => this._onSearchContent()
    );
  }

  async _onSearchContent(params = {}) {
    const { filters, form } = this.state;
    const { period, jenis, students_id, year } = filters;
    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const { handleHistoryPaymentsRutin, handleHistoryPaymentsNonRutin } = this.props;
          
          let result = {};
          let result2 = {};

          if (jenis === "rutin") {
            result = await handleHistoryPaymentsRutin({
              ...params,
              temps_id: students_id.value,
              currents_year: period ? period : year,
              filters,
            });
          } else if (jenis === "nonrutin") {
            result2 = await handleHistoryPaymentsNonRutin({
              ...params,
              temps_id: students_id.value,
              filters,
            });
          }

          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listhistory: result,
                listhistory2: result2,
              },
            },
          }));
        } catch (err) {
          if (this.isMount) {
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                listhistory: [],
                listhistory2: [],
              },
            },
          }));
          }
        }
      }
    );
  }

  async _getStudentSPPOptions(filters = {}) {
    const { getStudentSPPOptions } = this.props;
    const res = await getStudentSPPOptions(filters);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id } = user;
    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getYearsOfEducationsOptions(filters={}) {
    const { getYearsOfEducationsOptions } = this.props;
    const res = await getYearsOfEducationsOptions(filters);

    map(res, (data, idx) => {
      var date = new Date();
      var start_date = new Date(data.attributes.start_date)
      if (date > start_date) {
        // tahun ajaran sekarang
        this.setState(prevState => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            year: data.value,
          }
        }))
      }
    });

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  render() {
    const { param, form, filters, expanded } = this.state;
    const { listhistory, listhistory2 } = form.value;
    const { period = "", jenis, students_id, year } = filters;
    const { user } = this.props;

    const prm_jenis = [
      { label: 'SPP', value: 'rutin'},
      { label: 'DPP/UPP', value: 'nonrutin'},
    ];

    var cicilan = 0;
    var last = ""
    var totalnon = 0
    var empty = 0
    var emptynon = 0

    if (listhistory.length != undefined && listhistory.length == 0) {
      empty = 1
    }

    if (listhistory2.length != undefined) {
      if (listhistory2.length == 0) {
        emptynon = 1
      } else {
        emptynon = 1
        map(listhistory2, (data, idx) => {
          if(data.paymentdetails.length != 0) {
            emptynon = 0
          }
        })
      }
    }

    return (
      <div className="manage-registration">
        <div className="budget__title">
          <h1>Rincian Pembayaran</h1>
          <hr></hr>
        </div>

        <div className="absensi-rekap__custom-form">
          <div className="absensi-rekap__custom-form-row">
            <div className="absensi-rekap__custom-form-row__field">
              <SearchSelect
                noMargin
                async={false}
                name="students_id"
                list={param.student}
                inputArray
                onClick={this._onChangeFilter}
                placeholder="Pilih Siswa"
                value={students_id}
                rightIcon="icon-search"
                label="Siswa"
              />
            </div>
            <div className="absensi-rekap__custom-form-column__field">
              {students_id && (
                <Select
                  name="jenis"
                  label="Jenis Pembayaran"
                  onChange={this._onChangeFilter}
                  data={prm_jenis}
                  placeholder="Pilihan"
                  value={jenis}
                />
              )}
            </div>
          </div>
          <br></br>
          <div className="absensi-rekap__custom-form-row">
            <div className="absensi-rekap__custom-form-row__field">
              {(jenis === "rutin") && (
                <Select
                  name="period"
                  label="Tahun Ajaran"
                  onChange={this._onChangeFilter}
                  data={param.periods}
                  placeholder={"Pilihan"}
                  value={period ? period : year}
                />
              )}
            </div>
            <div className="absensi-rekap__custom-form-column__field">
            </div>
          </div>

        </div>

        <br></br>

        {(() => {
          if (jenis === "rutin") {
            return (
              <table className="manage-rapbs-plafon__form-table table-invoice">
                <thead>
                  <tr>
                    <th colSpan="2">SPP</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{border: "unset"}}>
                      <div>
                        {(() => {
                          if (empty == 1) {
                            return (
                              <p>Belum ada rincian pembayaran untuk jenis pembayaran SPP.</p>
                            )
                          } else {
                            return (
                              map(listhistory, (data, idx) => (
                                <ExpansionPanel expanded={expanded === 'panel'+idx} onChange={this.handleChange('panel'+idx)} style={{backgroundColor: "#f5f5f5"}}>
                                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography><h2>{data.month}</h2></Typography>
      
                                  </ExpansionPanelSummary>
                                  <ExpansionPanelDetails style={{flexDirection: "column"}}>
                                    <Typography>
                                      <table className="cetak-buku-induk2__table-test" style={{padding : "10px"}}>
                                        <thead>
                                          <tr>
                                            <th>Nama Pembayaran</th>
                                            <th style={{ textAlign: "right" }}>Total &emsp;</th>
                                          </tr>
                                        </thead>
                                        {map(data.tr_payments_details, (data_detail, idx_detail) => (
                                          <tr>
                                            <td style={{ textAlign: "left" }}>
                                              {data_detail.name}
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                              Rp. {commaFormatted(data_detail.nominal)}
                                            </td>
                                          </tr>
                                        ))}
                                      </table>
                                      <table className="manage-rapbs-plafon__form-table table-invoice" style={{border: "unset", backgroundColor: "#ccc"}}>
                                        <tr>
                                          <th style={{ textAlign: "left", border: "unset" }}>
                                            Total
                                          </th>
                                          <th style={{ textAlign: "right", border: "unset" }}>
                                            Rp. {commaFormatted(data.total)}
                                          </th>
                                        </tr>
                                        <tr>
                                          <td style={{ textAlign: "left", border: "unset" }}>
                                            Waktu Pembayaran
                                          </td>
                                          <td style={{ textAlign: "right", border: "unset" }}>
                                            {data.payments_date}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td style={{ textAlign: "left", border: "unset" }}>
                                            Tipe Pembayaran
                                          </td>
                                          <td style={{ textAlign: "right", border: "unset" }}>
                                            {data.payments_type}
                                          </td>
                                        </tr>
                                      </table>
                                    </Typography>
                                  </ExpansionPanelDetails>
                                </ExpansionPanel>
                              ))
                            )
                          }
                        })()}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            )
          } else if (jenis === "nonrutin") {
            return (
              <table className="manage-rapbs-plafon__form-table table-invoice">
                <thead>
                  <tr>
                    <th colSpan="2">DPP/UPP</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{border: "unset"}}>
                      <div>
                        {(() => {
                          if (emptynon == 1) {
                            return (
                              <p>Belum ada rincian pembayaran untuk jenis pembayaran DPP/UPP.</p>
                            )
                          } else {
                            return (
                              map(listhistory2, (data, idx) => (
                                (() => {
                                  if (data.paymentdetails.length !== 0) {
                                    return (
                                      <ExpansionPanel expanded={expanded === 'panelnon'+idx} onChange={this.handleChange('panelnon'+idx)} style={{backgroundColor: "#f5f5f5"}}>
                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                          <Typography><h2>{data.payment_name}</h2></Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails style={{flexDirection: "column"}}>
                                          <Typography>
                                            <table className="cetak-buku-induk2__table-test" style={{padding : "10px"}}>
                                              {(() => {
                                                totalnon = 0
                                                return (
                                                  <thead>
                                                    <tr>
                                                      <th>Cicilan</th>
                                                      <th>Waktu Pembayaran</th>
                                                      <th>Tipe Pembayaran</th>
                                                      <th style={{ textAlign: "right" }}>Total &emsp;</th>
                                                    </tr>
                                                  </thead>
                                                )
                                              })()}
                                              {map(data.paymentdetails, (data_detail, idx_detail) => (
                                                (() => {
                                                  last = data_detail.invoices_id.substr(data_detail.invoices_id.length-2, data_detail.invoices_id.length)
                                                  if (last === "00") {
                                                    cicilan = 0
                                                    data_detail.name = "DP"
                                                  } else {
                                                    cicilan++
                                                    data_detail.name = "Cicilan " + cicilan
                                                  }
                                                  totalnon = totalnon + Number(data_detail.nominal)
                                                  data.nominal = totalnon
                                                  return (
                                                    <tr>
                                                      <td>
                                                        {data_detail.name}
                                                      </td>
                                                      <td>
                                                        {data_detail.payments_date}
                                                      </td>
                                                      <td>
                                                        {data_detail.payments_type}
                                                      </td>
                                                      <td style={{ textAlign: "right" }}>
                                                        Rp. {commaFormatted(data_detail.nominal)}
                                                      </td>
                                                    </tr>
                                                  )
                                                })()
                                              ))}
                                            </table>
                                            <table className="manage-rapbs-plafon__form-table table-invoice" style={{border: "unset", backgroundColor: "#ccc"}}>
                                              <tr>
                                                <th style={{ textAlign: "left", border: "unset" }}>
                                                  Total
                                                </th>
                                                <th style={{ textAlign: "right", border: "unset" }}>
                                                  
                                                  Rp. {commaFormatted(data.nominal)}
                                                </th>
                                              </tr>
                                            </table>
                                          </Typography>
                                        </ExpansionPanelDetails>
                                      </ExpansionPanel>
                                    )
                                  }
                                })()
                              ))
                            )
                          }
                        })()}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            )
          }
        })()}

        <br></br>

      </div>
    );
  }
}
RincianPembayaran.propTypes = {
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
RincianPembayaran.defaultProps = {
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
