import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { map, noop } from 'lodash';
import CollapsibleMenu from '../CollapsibleMenu/CollapsibleMenu.component';
import Menu from '../Menu/Menu.component';
import homeIcon from '../../assets/img/menu/beranda.png';

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.setRef = this.setRef.bind(this);
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.navEl = null;

    this.state = {
      collapsed: false,
    }
  }

  setRef(el) {
    this.navEl = el;
  }

  toggleNavbar(ev) {
    const { currentTarget } = ev;
    const contentEl= currentTarget.getElementsByClassName('navbar_toggle_content')[0];

    this.setState(prevState => ({
      collapsed: !prevState.collapsed,
    }));
  }

  renderToggleButton() {
    const { collapsed } = this.state;
    const className = collapsed ? 'collapsed' : 'expanded';
    const content = collapsed ? ' > ' : ' < ';

    if(this.navEl) {
      if(collapsed) {
        this.navEl.classList.remove('expanded');
        this.navEl.classList.add('collapsed');
      } else {
        this.navEl.classList.remove('collapsed');
        this.navEl.classList.add('expanded');
      }
    }

    return (
      <button className="navbar_toggle_button" onClick={this.toggleNavbar}>
        <span className="navbar_toggle_content">{content}</span>
      </button>
    );
  }

  render() {
    const {
      show, onClickMenu, onLogout,
      sidebarMenu, showLogout, user,
    } = this.props;

    if(user.user_group.id === 1){
      user.user_group.permissions = ['view_student']
    }

    if(user.school_unit.ppdb_online == 0) {
      if (user.user_group.permissions == undefined) {
        user.user_group.permissions = ['ppdb_offline']
      } else {
        user.user_group.permissions.push('ppdb_offline')
      }
    }

    const { permissions } = user.user_group;
    const sidebarClassnames = classnames([
      'sidebar',
      show && 'sidebar--show',
      'expanded'
    ]);
    const menuStyles = {
      paddingLeft: 0,
    };
    return (
      <div className="sidebar__wrapper expanded" ref={this.setRef}>
        <nav className={sidebarClassnames}>
          {this.renderToggleButton()}
          <ul className="sidebar__menu-container__collapsed">
            {
              map(sidebarMenu, (list, index) =>
                (typeof list.permission === 'undefined'
                || permissions.includes(list.permission)) &&
                (
                  <li className="sidebar__menu" key={`collapse_menu_${index}`}>
                    <img className="menu-icon" src={list.iconImage}/>
                  </li>
                )
              )
            }
            {
              showLogout && (
                <li className="sidebar__menu">
                  <img className="menu-icon" src={homeIcon}/>
                </li>
              )
            }
          </ul>
          <ul className="sidebar__menu-container">
            {
              map(sidebarMenu, (list, index) => (
                (typeof list.permission === 'undefined'
                || permissions.includes(list.permission)) &&
                (
                  <li className="sidebar__menu" key={`collapse_menu_${index}`}>
                    <CollapsibleMenu
                      title={list.title}
                      iconImage={list.iconImage}
                      icon={list.icon}
                      path={list.path}
                      menu={list.menu}
                      padding={list.padding}
                      onClickMenu={onClickMenu}
                      permission={list.permission}
                      userPermission={permissions}
                      attributes={list.attributes}
                      visible={(typeof list.permission === 'undefined'
                      || permissions.includes(list.permission))}
                    />
                  </li>
                  )
              ))
            }
            {
              showLogout && (
                <li className="sidebar__menu">
                  <Menu
                    onClick={onLogout}
                    title="Logout"
                    leftIconImage={homeIcon}
                    style={menuStyles}
                  />
                </li>
              )
            }
          </ul>
        </nav>
      </div>
    );
  }
}
Sidebar.propTypes = {
  sidebarMenu: PropTypes.array.isRequired,
  showLogout: PropTypes.bool,
  show: PropTypes.bool,
  onClickMenu: PropTypes.func,
  onLogout: PropTypes.func,
};
Sidebar.defaultProps = {
  showLogout: true,
  show: true,
  onClickMenu: noop,
  onLogout: noop,
};
