import { connect } from 'react-redux';
import CatatSiswaPindah2 from '../../../views/Student/DataSiswa/CatatSiswaPindah2.student.view';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses, getStudentOptions, getParamOptions, getParamOptionsAcademics } from '../../../states/thunks/options.thunk';
import { saveMoveRecord, listMoveRecord, getMoveRecord, editMoveRecord } from '../../../states/thunks/academics.thunk';


function mapStateToProps(state) {
  return {
    user: state.user,
    classes: state.options.classes,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    handleGetClasses: payload => dispatch(getClasses(payload)),
    handleGetStudent: () => dispatch(getStudent()),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleGetMoveRecord: payload => dispatch(getMoveRecord(payload)),
    handleSaveMoveRecord: (payload, goback) => dispatch(saveMoveRecord(payload, goback)),
    handleListMoveRecord: (payload, goback) => dispatch(listMoveRecord(payload, goback)),
    handleEditMoveRecord: (payload, goback) => dispatch(editMoveRecord(payload, goback)), 
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),
    getStudentOptions: (payload) => dispatch(getStudentOptions(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CatatSiswaPindah2);


