import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop, map, fromPairs } from 'lodash';
import { Button, Input } from '../../../components/base';
import ReactToPrint from "react-to-print";
import logo from '../../../assets/img/Spin.png';
import noimg from '../../../assets/img/no-img-profile.png';
import * as commonActions from '../../../states/actions/common.action';
import { VerticalAlignTop } from '@material-ui/icons';

export default class LihatIdentitas extends Component {
  constructor(props) {
    super(props);

    this._getParamOptions = this._getParamOptions.bind(this);
    this._getDistrictOptions = this._getDistrictOptions.bind(this);
    this._setForm = this._setForm.bind(this);

    this.state = {
      page: 1,
      form: {
        value: {},
        error: {
          plafon: '',

        },
      },
      param: {},
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const paramTypes = ['unit_types', 'nisn_options', 'report_periods', 'highest_grade', 'citizenships', 'religions', 'genders'];
    const { state = {} } = location;
    const { isEdit =  false, data } = state;
    const { id } = state.data || '';
    
    paramTypes.forEach((type) => {
      this._getParamOptions(type);
    });

    if (isEdit && id) {
      this.setState({id:id})
      this._setForm(data.id);
    }

    this._getDistrictOptions();
  }

  async _getParamOptions(type, filters={}) {
    const { getParamOptions } = this.props;
    const res = await getParamOptions(filters, type);
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getDistrictOptions() {
    const {
      handleGetCities,
      handleGetDistricts,
      handleGetProvinces,
      handleGetSubDistricts
    } = this.props;
    const { param } = this.state;

    const provinces = await handleGetProvinces();
    const cities = await handleGetCities();
    const districts = await handleGetDistricts();
    const subDistricts = await handleGetSubDistricts();
    this.setState(prevState => ({
      ...prevState,
      param: {
        ...prevState.param,
        provinces: provinces,
        cities: cities,
        districts: districts,
        subDistricts: subDistricts,
      },
    }));
   
  }

  async _setForm(id) {
    const { handleGetUnits, user } = this.props;
    const { organizations_id, school_unit } = user;
    try {
      const payload = await handleGetUnits({ id });
      this.setState({
        form: {
          value: {
           id: id,
           name: payload.name,
           unit_type: payload.unit_type,
           phone: payload.phone,
           address: payload.address,
           provinces_id: payload.provinces_id,
           provinces_name: payload.provinces_name,
           cities_id: payload.cities_id,
           cities_name: payload.cities_name,
           districts_id: payload.districts_id,
           districts_name: payload.districts_name,
           sub_districts_id: payload.sub_districts_id,
           sub_districts_name: payload.sub_districts_name,
           type_of_cities: payload.type_of_cities,
           village: payload.village,
           postal_code: payload.postal_code,
           unit_code: payload.unit_code,
           rayon: payload.rayon,
           website: payload.website,
           email: payload.email,
           nds: payload.nds,
           nss: payload.nss,
           npsn: payload.npsn,
           school_status: payload.school_status,
           headmaster_id: payload.headmaster_id,
           namakepalasekolah: payload.namakepalasekolah,
           school_accreditation: payload.school_accreditation,
           school_time: payload.school_time,
           school_cluster: payload.school_cluster,
           school_category: payload.school_category,
           school_based_management: payload.school_based_management,
           school_curriculum: payload.school_curriculum,
           school_internet_connection: payload.school_internet_connection,
           school_boss_fund: payload.school_boss_fund,
           study_time: payload.study_time,
           study_group: payload.study_group,
          },
          error: {
            // details: [],
          },
        },
      });
    } catch (err) {
      // err action
    }
    finally {
      commonActions.setLoading({ show: false });
    }
  }

  render() {
    const { param, form, page, data } = this.state;
    const {provinces ={}, cities = {}, districts = {}, sub_districts = {}, user} = this.props;
    const { organization, school_unit } = user;

    var year = new Date().getFullYear();
    var month = new Date().getMonth() + 1;
    if (month < 7) {
      year -= 1;
    }

    const componentRef = React.createRef();

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <h1>Laporan Kesiswaan</h1>
                  <hr></hr>
                  <div>
                    <ReactToPrint
                      trigger={() => (
                        <div className="manage-registration__footer">
                          <Button title="Cetak" />
                        </div>
                      )}
                      content={() => componentRef.current}
                    />
        
                    <form style={{ padding: '20px' }} ref={componentRef}>
                      <div id="cetak-buku-induk2" className="cetak-buku-induk2">
                        <table className="table-report-header">
                          <tr>
                            <th rowSpan={4}>
                              <img style={{width:"70px"}} src={logo} />
                            </th>
                            <th colSpan={5}>
                              <h2>LAPORAN IDENTITAS SEKOLAH</h2>
                            </th>
                            <td width="10%">
                              <Input
                                type="text"
                                value="LI-SD/MI"
                                readOnly
                                align="center"
                                style={{fontSize:"11px", height:"3rem", textAlign:"center", padding:"0.5rem"}}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td width="10%" rowSpan={3}>&emsp;</td>
                            <td>TAHUN PELAJARAN</td>
                            <td className="titik2"> : </td>
                            <td>{year+"/"+(Number(year)+1)}</td>
                            <td width="10%" rowSpan={3}>&emsp;</td>
                            <td rowSpan={3}></td>
                          </tr>
                          <tr>
                            <td>KEADAAN 31 JULI</td>
                            <td className="titik2"> : </td>
                            <td>{year}</td>
                          </tr>
                          <tr>
                            <td>KODE KECAMATAN</td>
                            <td className="titik2"> : </td>
                            <td></td>
                          </tr>
                        </table>
                        <div className="cetak-buku-induk2__content">
                          <table className="cetak-buku-induk2__table-view-identitas">
                            <tr>
                              <td colSpan={9}></td>
                              <td className="label_side" width="25%">Nomor Statistik Sekolah</td>
                            </tr>
                            <tr>
                              <td className="listb"><h3>A. </h3></td>
                              <td colSpan={8}><h3>IDENTITAS</h3></td>
                              <td width="20%">
                                <Input
                                  type="text"
                                  name="nss"
                                  value={form.value.nss}
                                  readOnly
                                  align="center"
                                  style={{textAlign:"center"}}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td className="list">1. </td>
                              <td className="label_12" colSpan={3}>Nama Sekolah</td>
                              <td className="titik2"> : </td>
                              <td className="contents field" colSpan={3}>
                                <Input
                                  type="text"
                                  name="name"
                                  value={form.value.name}
                                  error={form.error.name || ""}
                                  readOnly
                                />
                              </td>
                              <td className="label_side" width="25%">Nomor Pokok Sekolah</td>
                            </tr>
                            <tr>
                              <td></td>
                              <td className="list">2. </td>
                              <td className="label_12" colSpan={3}>Alamat Sekolah</td>
                              <td className="titik2"></td>
                              <td className="contents field" colSpan={3}></td>
                              <td className="label_side" width="25%">Nasional (NPSN)</td>
                            </tr>
                            <tr>
                              <td></td>
                              <td></td>
                              <td className="list">a. </td>
                              <td className="label_ab" colSpan={2}>Jalan</td>
                              <td className="titik2"> : </td>
                              <td className="contents field" colSpan={3}>
                                <Input
                                  type="text"
                                  name="address"
                                  value={form.value.address}
                                  error={form.error.address || ""}
                                  readOnly
                                />
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  name="npsn"
                                  value={form.value.npsn}
                                  readOnly
                                  align="center"
                                  style={{width:"75%"}}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td></td>
                              <td className="list">b. </td>
                              <td className="label_ab" colSpan={2}>Desa/Kelurahan</td>
                              <td className="titik2"> : </td>
                              <td className="contents field" colSpan={3}>
                                <Input
                                  type="text"
                                  name="sub_districts_name"
                                  value={form.value.sub_districts_name}
                                  error={form.error.sub_districts_name || ""}
                                  readOnly
                                />
                              </td>
                              <td className="label_side" width="25%">Jenis Sekolah</td>
                            </tr>
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td className="label_ab" colSpan={2}></td>
                              <td className="titik2"> : </td>
                              <td className="contents" colSpan={3}>
                                <td className="xsfield" width="10%">
                                  <Input
                                    type="text"
                                    name="village"
                                    value={form.value.village}
                                    readOnly
                                    align="center"
                                    style={{textAlign:"center"}}
                                  />
                                </td>
                                <td className="opt" width="30%">1. Desa</td>
                                <td className="opt" width="60%">2. Kelurahan</td>
                              </td>
                              <td>
                                <td className="xsfield" width="18%">
                                  <Input
                                    type="text"
                                    name="unit_type"
                                    value={form.value.unit_type}
                                    readOnly
                                    align="center"
                                    style={{textAlign:"center"}}
                                  />
                                </td>
                                <td className="opt" width="41%">1. SD</td>
                                <td className="opt" width="41%">2. MI</td>
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td></td>
                              <td className="list">c. </td>
                              <td className="label_ab" colSpan={2}>Kategori Wilayah</td>
                              <td className="titik2"> : </td>
                              <td className="contents" colSpan={3} rowSpan={2}>
                                <tr>
                                  <td className="xsfield" width="10%">
                                    <Input
                                      type="text"
                                      name="rayon"
                                      value={form.value.rayon}
                                      readOnly
                                      align="center"
                                      style={{textAlign:"center"}}
                                    />
                                  </td>
                                  <td className="opt" width="30%">1. Daerah Terpencil</td>
                                  <td className="opt" width="60%">2. Daerah Perbatasan ( dengan negara lain )</td>
                                </tr>
                                <tr>
                                  <td className="xsfield" width="10%">&emsp;</td>
                                  <td className="opt" width="30%">3. Daerah Transmigrasi</td>
                                  <td className="opt" width="60%">4. Tidak termasuk ketegori 1,2 atau 3</td>
                                </tr>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={5}>&emsp;</td>
                            </tr>
                            <tr>
                              <td></td>
                              <td></td>
                              <td className="list">d. </td>
                              <td className="label_ab" colSpan={2}>Kecamatan</td>
                              <td className="titik2"> : </td>
                              <td className="contents field" colSpan={3}>
                                <Input
                                  type="text"
                                  name="districts_name"
                                  value={form.value.districts_name}
                                  error={form.error.districts_name || ""}
                                  readOnly
                                />
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td></td>
                              <td className="list">e. </td>
                              <td className="label_ab" colSpan={2}>Kabupaten/kota</td>
                              <td className="titik2"> : </td>
                              <td className="contents field" colSpan={3}>
                                <Input
                                  type="text"
                                  name="cities_name"
                                  value={form.value.cities_name}
                                  error={form.error.cities_name || ""}
                                  readOnly
                                />
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td className="label_ab" colSpan={2}></td>
                              <td className="titik2"> : </td>
                              <td className="contents" colSpan={3}>
                                <td className="xsfield" width="10%">
                                  <Input
                                    type="text"
                                    name="type_of_cities"
                                    value={form.value.type_of_cities}
                                    readOnly
                                    align="center"
                                    style={{textAlign:"center"}}
                                  />
                                </td>
                                <td className="opt" width="30%">1. Kabupaten</td>
                                <td className="opt" width="60%">2. Kota</td>
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td></td>
                              <td className="list">f. </td>
                              <td className="label_ab" colSpan={2}>Provinsi</td>
                              <td className="titik2"> : </td>
                              <td className="contents field" colSpan={3}>
                                <Input
                                  type="text"
                                  name="provinces_name"
                                  value={form.value.provinces_name}
                                  error={form.error.provinces_name || ""}
                                  readOnly
                                />
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td></td>
                              <td className="list">g. </td>
                              <td className="label_ab" colSpan={2}>Kode Pos</td>
                              <td className="titik2"> : </td>
                              <td className="contents field" colSpan={3}>
                                <Input
                                  type="text"
                                  name="postal_code"
                                  value={form.value.postal_code}
                                  error={form.error.postal_code || ""}
                                  readOnly
                                  style={{width:"30%"}}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td></td>
                              <td className="list">h. </td>
                              <td className="label_ab" colSpan={2}>Kode Area/No. Telp./Fax</td>
                              <td className="titik2"> : </td>
                              <td className="contents" colSpan={4}>
                                <td className="opt" width="30%">
                                  <Input
                                    type="text"
                                    name="phone"
                                    value={form.value.phone}
                                    readOnly
                                  />
                                </td>
                                <td className="opt" width="10%">Email: </td>
                                <td className="opt" width="25%">
                                  <Input
                                    type="text"
                                    name="email"
                                    value={form.value.email}
                                    readOnly
                                  />
                                </td>
                                <td className="opt" width="10%">Website:</td>
                                <td className="opt" width="25%">
                                  <Input
                                    type="text"
                                    name="website"
                                    value={form.value.website}
                                    readOnly
                                  />
                                </td>
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td className="list">3. </td>
                              <td className="label_12" colSpan={3}>Status Sekolah</td>
                              <td className="titik2"> : </td>
                              <td className="contents" colSpan={4}>
                                <td className="xsfield" width="8%">
                                  <Input
                                    type="text"
                                    name="school_status"
                                    value={form.value.school_status}
                                    readOnly
                                    align="center"
                                    style={{textAlign:"center"}}
                                  />
                                </td>
                                <td className="opt" width="23%">1. Negeri</td>
                                <td className="opt" width="69%">2. Swasta</td>
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td className="list">4. </td>
                              <td className="label_12" colSpan={3}>Status Akreditasi Sekolah</td>
                              <td className="titik2"> : </td>
                              <td className="contents" colSpan={4}>
                                <td className="xsfield" width="8%">
                                  <Input
                                    type="text"
                                    name="school_accreditation"
                                    value={form.value.school_accreditation}
                                    readOnly
                                    align="center"
                                    style={{textAlign:"center"}}
                                  />
                                </td>
                                <td className="opt" width="23%">1. A</td>
                                <td className="opt" width="23%">2. B</td>
                                <td className="opt" width="23%">3. C</td>
                                <td className="opt" width="23%">4. TT</td>
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td className="list">5. </td>
                              <td className="label_12" colSpan={3}>Waktu Penyelenggaraan</td>
                              <td className="titik2"> : </td>
                              <td className="contents" colSpan={4}>
                                <td className="xsfield" width="8%">
                                  <Input
                                    type="text"
                                    name="school_time"
                                    value={form.value.school_time}
                                    readOnly
                                    align="center"
                                    style={{textAlign:"center"}}
                                  />
                                </td>
                                <td className="opt" width="23%">1. Pagi</td>
                                <td className="opt" width="23%">2. Siang</td>
                                <td className="opt" width="46%">3. Kombinasi</td>
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td className="list">6. </td>
                              <td className="label_12" colSpan={3}>Gugus Sekolah</td>
                              <td className="titik2"> : </td>
                              <td className="contents" colSpan={4}>
                                <td className="xsfield" width="8%">
                                  <Input
                                    type="text"
                                    name="school_cluster"
                                    value={form.value.school_cluster}
                                    readOnly
                                    align="center"
                                    style={{textAlign:"center"}}
                                  />
                                </td>
                                <td className="opt" width="23%">1. Inti</td>
                                <td className="opt" width="23%">2. Imbas</td>
                                <td className="opt" width="46%">3. Belum Ikut</td>
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td className="list">7. </td>
                              <td className="label_12" colSpan={3}>Kategori Sekolah</td>
                              <td className="titik2"> : </td>
                              <td className="contents" colSpan={4} rowSpan={3}>
                                <tr>
                                  <td className="xsfield" width="8%">
                                    <Input
                                      type="text"
                                      name="school_category"
                                      value={form.value.school_category}
                                      readOnly
                                      align="center"
                                      style={{textAlign:"center"}}
                                    />
                                  </td>
                                  <td className="opt" width="46%">1. SD SPM (Standar Pelayanan Minimal)</td>
                                  <td className="opt" width="46%">2. SD RSSN  (Rintisan Sekolah Standar Nasional)</td>
                                </tr>
                                <tr>
                                  <td className="xsfield" width="8%">&emsp;</td>
                                  <td className="opt" width="46%">3. SD SSN (Sekolah Standar Nasional)</td>
                                  <td className="opt" width="46%">4. SD RSBI (Rintisan Sekolah Bertaraf Internasional)</td>
                                </tr>
                                <tr>
                                  <td className="xsfield" width="8%">&emsp;</td>
                                  <td className="opt" width="46%">5. SD SBI (Sekolah Bertaraf Internasional)</td>
                                  <td className="opt" width="46%">6. SD-SMP Satu Atap</td>
                                </tr>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={6}>&emsp;</td>
                            </tr>
                            <tr>
                              <td colSpan={6}>&emsp;</td>
                            </tr>
                            <tr>
                              <td></td>
                              <td className="list">8. </td>
                              <td colSpan={8}>
                                <td className="label_12_long">Apakah Sekolah ini telah melaksanakan Manajemen Berbasis Sekolah (MBS) ?</td>
                                <td className="titik2"></td>
                                <td className="contents" width="30%">
                                  <td className="xsfield" width="18%">
                                    <Input
                                      type="text"
                                      name="school_based_management"
                                      value={form.value.school_based_management}
                                      readOnly
                                      align="center"
                                      style={{textAlign:"center"}}
                                    />
                                  </td>
                                  <td className="opt" width="41%">1. Ya</td>
                                  <td className="opt" width="41%">2. Tidak</td>
                                </td>
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td className="list">9. </td>
                              <td className="label_12" colSpan={3}>Kurikulum Yang Digunakan</td>
                              <td className="titik2"> : </td>
                              <td className="contents" colSpan={4}>
                                <td className="xsfield" width="8%">
                                  <Input
                                    type="text"
                                    name="school_curriculum"
                                    value={form.value.school_curriculum}
                                    readOnly
                                    align="center"
                                    style={{textAlign:"center"}}
                                  />
                                </td>
                                <td className="opt" width="23%">1. Sekolah Penggerak</td>
                                <td className="opt" width="23%">2. KTSP</td>
                                <td className="opt" width="46%">3. 2013</td>
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td className="list">10. </td>
                              <td colSpan={8}>
                                <td className="label_12_long">Apakah Sekolah ini mempunyai koneksi internet ?</td>
                                <td className="titik2"> : </td>
                                <td className="contents" width="30%">
                                  <td className="xsfield" width="18%">
                                    <Input
                                      type="text"
                                      name="school_internet_connection"
                                      value={form.value.school_internet_connection}
                                      readOnly
                                      align="center"
                                      style={{textAlign:"center"}}
                                    />
                                  </td>
                                  <td className="opt" width="41%">1. Ya</td>
                                  <td className="opt" width="41%">2. Tidak</td>
                                </td>
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td className="list">11. </td>
                              <td colSpan={8}>
                                <td className="label_12_long">Apakah Sekolah ini menerima dana BOS ?</td>
                                <td className="titik2"> : </td>
                                <td className="contents" width="30%">
                                  <td className="xsfield" width="18%">
                                    <Input
                                      type="text"
                                      name="school_boss_fund"
                                      value={form.value.school_boss_fund}
                                      readOnly
                                      align="center"
                                      style={{textAlign:"center"}}
                                    />
                                  </td>
                                  <td className="opt" width="41%">1. Ya</td>
                                  <td className="opt" width="41%">2. Tidak</td>
                                </td>
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td className="list">12. </td>
                              <td className="label_12_long" colSpan={8}>Apakah Identitas sekolah tahun ini sama dengan identitas tahun lalu ? Jika identitas sekolah tahun ini sama dengan  tahun lalu, langsung mengisi butir B </td>
                            </tr>
                            <tr>
                              <td colSpan={2}></td>
                              <td className="label_12_long" colSpan={8}>Jika identitas sekolah tahun ini tidak sama dengan tahun lalu (ada perubahan) maka  tulislah identitas sekolah tahun lalu dengan rincian di bawah ini.</td>
                            </tr>
                            <tr>
                              <td></td>
                              <td></td>
                              <td className="list">a. </td>
                              <td className="label_ab" colSpan={2}>Nomor Statistik Sekolah</td>
                              <td className="titik2"> : </td>
                              <td className="contents field" colSpan={3}>
                                <Input
                                  type="text"
                                  name="nss2"
                                  value={form.value.nss2}
                                  error={form.error.nss2 || ""}
                                  readOnly
                                  style={{width:"50%"}}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td></td>
                              <td className="list">b. </td>
                              <td className="label_ab" colSpan={2}>Nama Sekolah</td>
                              <td className="titik2"> : </td>
                              <td className="contents field" colSpan={3}>
                                <Input
                                  type="text"
                                  name="name2"
                                  value={form.value.name2}
                                  error={form.error.name2 || ""}
                                  readOnly
                                />
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td></td>
                              <td className="list">c. </td>
                              <td className="label_ab" colSpan={2}>Alamat Sekolah</td>
                            </tr>
                            <tr>
                              <td></td>
                              <td></td>
                              <td className="list"></td>
                              <td className="label_ab" colSpan={2}>- Jalan</td>
                              <td className="titik2"> : </td>
                              <td className="contents field" colSpan={3}>
                                <Input
                                  type="text"
                                  name="address2"
                                  value={form.value.address2}
                                  error={form.error.address2 || ""}
                                  readOnly
                                />
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td></td>
                              <td className="list"></td>
                              <td className="label_ab" colSpan={2}>- Desa/Kelurahan</td>
                              <td className="titik2"> : </td>
                              <td className="contents field" colSpan={3}>
                                <Input
                                  type="text"
                                  name="sub_districs_name2"
                                  value={form.value.sub_districs_name2}
                                  error={form.error.sub_districs_name2 || ""}
                                  readOnly
                                />
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td></td>
                              <td className="list"></td>
                              <td className="label_ab" colSpan={2}>- Kecamatan</td>
                              <td className="titik2"> : </td>
                              <td className="contents field" colSpan={3}>
                                <Input
                                  type="text"
                                  name="districs_name2"
                                  value={form.value.districs_name2}
                                  error={form.error.districs_name2 || ""}
                                  readOnly
                                />
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td></td>
                              <td className="list"></td>
                              <td className="label_ab" colSpan={2}>- Kabupaten/Kota</td>
                              <td className="titik2"> : </td>
                              <td className="contents field" colSpan={3}>
                                <Input
                                  type="text"
                                  name="cities_name2"
                                  value={form.value.cities_name2}
                                  error={form.error.cities_name2 || ""}
                                  readOnly
                                />
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td></td>
                              <td className="list"></td>
                              <td className="label_ab" colSpan={2}>- Propinsi</td>
                              <td className="titik2"> : </td>
                              <td className="contents field" colSpan={3}>
                                <Input
                                  type="text"
                                  name="provinces_name2"
                                  value={form.value.provinces_name2}
                                  error={form.error.provinces_name2 || ""}
                                  readOnly
                                />
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td></td>
                              <td className="list">d. </td>
                              <td className="label_ab" colSpan={2}>Status Sekolah/Madrasah</td>
                              <td className="titik2"> : </td>
                              <td className="contents" colSpan={3}>
                                <td className="xsfield" width="8%">
                                  <Input
                                    type="text"
                                    name="status2"
                                    value={form.value.status2}
                                    readOnly
                                    align="center"
                                    style={{textAlign:"center"}}
                                  />
                                </td>
                                <td className="opt" width="23%">1. Negeri</td>
                                <td className="opt" width="69%">2. Swasta</td>
                              </td>
                            </tr>
                          </table>
                          <br></br>

                          {/* <table className="cetak-buku-induk2__table-view-identitas">
                            <tr>
                              <td><h3>B. </h3></td>
                              <td colSpan={2}><h3>SISWA, KELAS (ROMBONGAN BELAJAR), DAN DAFTAR NILAI UJIAN SEKOLAH</h3></td>
                            </tr>
                            <tr>
                              <td></td>
                              <td className="list">1. </td>
                              <td>Penerimaan Siswa Baru Tingkat I</td>
                            </tr>
                            <tr>
                              <td></td>
                              <td colSpan={2}>
                                <table className="table-raport">
                                  <thead>
                                    <tr>
                                      <th rowSpan="2">Asal Siswa</th>
                                      <th rowSpan="2">Rencana Penerimaan</th>
                                      <th colSpan="3">Pendaftar</th>
                                      <th colSpan="3">Siswa Diterima di Tingkat I</th>
                                    </tr>
                                    <tr>
                                      <th>L</th>
                                      <th>P</th>
                                      <th>L+P</th>
                                      <th>L</th>
                                      <th>P</th>
                                      <th>L+P</th>
                                    </tr>
                                    <tr>
                                      <td align="center" style={{fontStyle:"italic"}}>
                                        <small>(1)</small>
                                      </td>
                                      <td align="center" style={{fontStyle:"italic"}}>
                                        <small>(2)</small>
                                      </td>
                                      <td align="center" style={{fontStyle:"italic"}}>
                                        <small>(3)</small>
                                      </td>
                                      <td align="center" style={{fontStyle:"italic"}}>
                                        <small>(4)</small>
                                      </td>
                                      <td align="center" style={{fontStyle:"italic"}}>
                                        <small>(5)</small>
                                      </td>
                                      <td align="center" style={{fontStyle:"italic"}}>
                                        <small>(6)</small>
                                      </td>
                                      <td align="center" style={{fontStyle:"italic"}}>
                                        <small>(7)</small>
                                      </td>
                                      <td align="center" style={{fontStyle:"italic"}}>
                                        <small>(8)</small>
                                      </td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>1. Tamatan TK</td>
                                      <td></td>
                                      <td className="nominal" width="10%"></td>
                                      <td className="nominal" width="10%"></td>
                                      <th className="nominal" width="10%"></th>
                                      <td className="nominal" width="10%"></td>
                                      <td className="nominal" width="10%"></td>
                                      <th className="nominal" width="10%"></th>
                                    </tr>
                                    <tr>
                                      <td>2. Bukan TK</td>
                                      <td></td>
                                      <td className="nominal" width="10%"></td>
                                      <td className="nominal" width="10%"></td>
                                      <th className="nominal" width="10%"></th>
                                      <td className="nominal" width="10%"></td>
                                      <td className="nominal" width="10%"></td>
                                      <th className="nominal" width="10%"></th>
                                    </tr>
                                  </tbody>
                                  <tfoot>
                                    <th>Jumlah</th>
                                    <th></th>
                                    <th className="nominal" width="10%"></th>
                                    <th className="nominal" width="10%"></th>
                                    <th className="nominal" width="10%"></th>
                                    <th className="nominal" width="10%"></th>
                                    <th className="nominal" width="10%"></th>
                                    <th className="nominal" width="10%"></th>
                                  </tfoot>
                                </table>
                              </td>
                            </tr>
                          </table> */}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
LihatIdentitas.propTypes = {
  getOptionsParam: PropTypes.func,
  handleGetProvinces: PropTypes.func,
  handleGetCities: PropTypes.func,
  handleGetDistricts: PropTypes.func,
  handleGetSubDistricts: PropTypes.func,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
};
LihatIdentitas.defaultProps = {
  getOptionsParam: noop,
  handleGetProvinces: noop,
  handleGetCities: noop,
  handleGetDistricts: noop,
  handleGetSubDistricts: noop,
  user: null,
};