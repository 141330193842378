import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop, map, isEqual } from 'lodash';
import { Button, Pagination } from '../../../components/base';
import { formatData,  normalizeAmount } from '../../../utils/transformer.util';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { KELAS_DATAKELAS_TABLE_FIELDS } from '../../../constants/Akademi/DataKelas/kelas.constant';
import { SEARCH_FIELDS} from '../../../constants/Akademi/DataKelas/kelas.constant';
import language from '../../../languages';

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class StudentList extends PureComponent {
  constructor(props) {
    super(props);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._onClickEdit = this._onClickEdit.bind(this);
    this._onClickUpload = this._onClickUpload.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this.handleGetData = this.handleGetData.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
  
    this.state= {
      content: initialContent,
      list: [],
      workflow: {},
      listAmount: 5,
      form: {
        value: {},
        error: {},
      },
    };
  
  }

  componentDidMount() {
    const { content } = this.state;
    this.handleGetData();
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    const { workingUnit } = user;
    const { user: prevUser } = prevProps;
    const { workingUnit: prevWorkingUnit } = prevUser;

    if (!isEqual(workingUnit, prevWorkingUnit)) {
      this.handleGetData();
    }
  }

  async handleGetData(payload = {}) {
    const { handleListClassData, handleListAnnouncementStudent, user } = this.props;
    const { school_unit, workingUnit, id, user_group, profile, organizations_id } = user;
    const { units_id } = school_unit;
   

    // if(user_group.id === 2){
      const res = await handleListClassData({
        ...payload, 
        organizations_id: 3, 
        units_id: school_unit.id
      });

    this.setState({
      list: res,
    });
    // }

    
  }

  async onClickDelete(id) {
    const { handleDeleteClassData } = this.props;
    const res = await handleDeleteClassData(id);
    if(res){
      this.handleGetData();
    }
  }

  _renderButtons(data) {
    const { user } = this.props;
    const {user_group} = user;
    const button = {};
    
    return (
      <td>
        <div className="table__actions">
        
          
          {/* {user_group.id === 2? */}
             <Button
             onClick={() => this._onClickEdit(data)}
             title='Edit'
           />
           {/* :'' */}
          {/* } */}
            {/* {user_group.id === 2? */}
          {/*           
           <Button
             onClick={() => this.onClickDelete(data)}
             title='Hapus'
           /> */}
           {/* :'' */}
          {/* } */}
         
        </div>
      </td>
    );
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this.handleGetData({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  _onClickAdd() {
    const { history } = this.props;
    history.push('/dashboard/pengaturan/buat-data-kelas', { data: '' });
  }

  _onClickEdit(val) {
    const { history } = this.props;
    history.push('/dashboard/pengaturan/buat-data-kelas', { data: val, isEdit: true });
  }

  _onClickUpload() {
    const { history } = this.props;
    history.push('/dashboard/pengaturan/upload-kelas');
  }
  _onFormChange(event) {
    const {
      name,
      value,
      dataset,
      checked,
      type,
    } = event.target;
    const {
      inputType = 'text', inputArray = false, arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === 'checkbox') {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === 'code_of_account') {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray
              ? { [fieldName]: newListError }
              : { [name]: '' }),
          },
        },
      };
    });
  }
  onFormChange(event) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    console.log(name)
    const { inputArray = false, arrayPosition = 0, inputType = 'text' } = dataset;
    this.setState((prevState) => {
      let formattedValue = value;
      let newPlafon = [];
      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }

      if (inputArray) {
        newPlafon = prevState.form.value.plafon;
        newPlafon[arrayPosition][name] = value;
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray ? 
              { plafon: newPlafon } : 
              { [name]: formattedValue }
            )
          },
          error: {
            ...prevState.form.error,
            form:'',
            [name]: '',
          },
        },
      };
    });
  }


  render() {
    const {form, list = {}} = this.state;
    const { handleGetKelas, handleDeleteKelas,coa } = this.props;
    return (
      <div className="student-list">
        <div className="budget__title">
          <h1>Kelas</h1>
          <hr></hr>
        </div>
        <div className="student-list__header">
          <div className="student-list__button">
            <Button
              title="Buat Data Kelas"
              onClick={this._onClickAdd}
            />
          </div>
          {/* <div className="student-list__button">
            <Button
              title="Upload File"
              onClick={this._onClickUpload}
            />
          </div> */}


        </div>

        <div className="budget__content">
          {/* <div className="budget__search">
          <ListForm
          coa={coa}
          form={form}
          formFields={SEARCH_FIELDS}
          onFormChange={this._onFormChange}
          />

          </div> */}
          {/* <ContentTable
            onSearchContent={handleGetKelas}
            tableFields={KELAS_DATAKELAS_TABLE_FIELDS}
            onClickEdit={this._onClickEdit}
            onClickDelete={handleDeleteKelas}
          /> */}
          <div className="fund-request__content">
          <table className="table">
            <thead>
              <tr>
                {
                    map(KELAS_DATAKELAS_TABLE_FIELDS.label, (field, idx) => (
                      <th key={`table_th_${idx}`}>{field}</th>
                   ))
                  }
              </tr>
            </thead>
            <tbody>
              {
                  map(list.result, (data, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {
                      map(KELAS_DATAKELAS_TABLE_FIELDS.value, (field, fieldIdx) => {
                        if (fieldIdx === 0) {
                          return (
                            <td key={`table_index_${fieldIdx}_${idx}`} style={{textAlign:"center"}}>{idx + 1}</td>
                          );
                        }
                        if (field.attribute === 'homeroom_teacher'){
                          return (
                            <td>{data.homeroom_teacher==null?data.home_teacher_name2:data.homeroom_teacher}</td>
                          );
                        }
                        if (field.type === 'link') {
                          return (
                            <td key={`table_${fieldIdx}_${idx}`}><a href={"https://"+data.hyperlink} target="_blank" rel="noopener noreferrer">{data.hyperlink}</a></td>
                              // {/* <Link to={'www.google.com'}>{data.hyperlink}</Link> </td> */}
                          );
                        }
                        return (
                          <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                            {formatData(data, field)}
                          </td>
                        );
                      })
                      }
                      {this._renderButtons(data)}
                    </tr>
                  ))
                }
            </tbody>
          </table>
        </div>
        <div className="user-management__table-footer">
          <p className="user-management__page-info">
            {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${list.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${list.totalPage}`}
          </p>
          <Pagination
            totalPage={list.totalPage}
            currentPage={list.currentPage}
            onClick={this.onChangePage}
          />
        </div>
        </div>
      </div>
    );
  }
}
StudentList.propTypes = {
  handleListClassData: PropTypes.func,
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
StudentList.defaultProps = {
  handleListClassData: noop,
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
