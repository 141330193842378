import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map } from "lodash";
import {
  commaFormatted,
  normalizeAmount,
} from "../../../../utils/transformer.util";
import { Button, Select, Input } from "../../../../components/base/index";
import { validateRequiredFields } from '../../../../utils/validation.util';
import { errorAlert } from '../../../../utils/alert.util';

export default class CreateInvoiceRutin extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onAddList = this._onAddList.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._setForm = this._setForm.bind(this);
    this.getYearsOfEducationsOptions = this.getYearsOfEducationsOptions.bind(this);
    this.getPrmPayments = this.getPrmPayments.bind(this);

    const { user, location } = this.props;
    const { state = {} } = location;
    const { data } = state;
    const { organizations_id, school_unit } = user;
    const { va_code, unit_code } = school_unit;
    this.state = {
      form: {
        value: {
          va_code: va_code,
          unit_code: unit_code,
          organizations_id: organizations_id,
          users_id: data.users_id,
          no_va: data.no_va,
          name: data.name,
          address: data.address,
          academics_year:data.entrance_year,
          total: 0,
          currents_year:"",
          tabelpayments: [
            {
              payments_id: "",
              nominal: 0,
            },
          ],
        },
        error: {
          tabelpayments:[]
        },
      },
      param: {},
    };
  }

  componentDidMount() {
    const { location, getLevels, getclassrooms, user } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data, getdata } = state;
    const { id } = state.data || "";
    const { school_unit } = user;
    if (isEdit && id) {
      const { invoices_id, currents_year, temps_id } = getdata;
      this._setForm(invoices_id, currents_year, temps_id);
    }
    this.getYearsOfEducationsOptions({units_id: school_unit.id});
    this.getPrmPayments();
  }

  _onFormChange(event) {
    const { form } = this.state;
    const { tabelpayments } = form.value;
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;

    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === "number") {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === "checkbox") {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName] || [];
        newListError = prevState.form.error[fieldName] || [];

        if (name === "payments_id" ) {
          newList[arrayPosition].error_payments = "";
          {map(tabelpayments, (data, idx) => {
            if (data.payments_id == value) {
              formattedValue = ""
              newList[arrayPosition].error_payments = "Nama pembayaran sudah dipilih";
            }
          })}
        }

        if (name === "nominal") {
          var toNum = value
          do{
            toNum=toNum.replace(",", "")
          }
          while(toNum.indexOf(",") != -1)
          formattedValue=Number(toNum)
        }

        newList[arrayPosition][name] = formattedValue;
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
          },
        },
      };
    });
  }

  _onAddList(data) {
    const { form } = this.state;
    const { tabelpayments } = form.value;

    tabelpayments.push({
      payments_id: "",
      nominal: 0,
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          tabelpayments,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }

  _onDeleteList(idx) {
    this.setState((prevState) => {
      const list = prevState.form.value.tabelpayments;
      list.splice(idx, 1);
      return {
        ...prevState,
        tabelpayments: list,
      };
    });
  }

  async onEdit() {
    const { history, handleEditInvoiceRutin } = this.props;
    const { form } = this.state;
    const { value } = form;
    
    if(form.value.total < 0) {
      errorAlert({
        title: 'Total tidak boleh minus',
        errorMessage: 'Mohon di cek kembali'
      });
    } else {
      let canSaveA = true;
      const dataForm = form.value;
      map(dataForm.tabelpayments, (data, idx) => {
        if(!data.payments_id || !data.nominal) {
          if(!(data.nominal == 0 && data.edit)) {
            canSaveA = false;
            errorAlert({
              title: 'Harap lengkapi data form',
              errorMessage: 'Bagian yang harus diisi ditandai dengan tanda bintang (*)'
            });
          }
        }
      })
      if (canSaveA){
        await handleEditInvoiceRutin(value);
        history.goBack();
      }
    }
  }

  async onSubmit() {
    const { history, handleSaveInvoiceRutin } = this.props;
    const { form } = this.state;
    const { value } = form;

    if(form.value.total < 0) {
      errorAlert({
        title: 'Total tidak boleh minus',
        errorMessage: 'Mohon di cek kembali'
      });
    } 
    else {
      let canSaveA = true;
      const dataForm = form.value;
      const lengthdata = dataForm.tabelpayments.length
      if(dataForm.currents_year && lengthdata){
        console.log("submit")
        map(dataForm.tabelpayments, (data, idx) => {
          if(!data.payments_id || !data.nominal) {
            canSaveA = false;
            errorAlert({
              title: 'Harap lengkapi data form',
              errorMessage: 'Bagian yang harus diisi ditandai dengan tanda bintang (*)'
            });
          } else if (data.nominal == 0) {
            canSaveA = false;
            errorAlert({
              title: 'Nominal Tidak Boleh "0"',
              errorMessage: 'Mohon hapus pembayaran yang tidak terpakai.'
            });
          }
        })
      } else {
        canSaveA = false;
      }
      
      if (canSaveA) {
        await handleSaveInvoiceRutin(value);
        history.goBack();
        // history.push("/dashboard/tagihan-siswa/create-invoice");
      }
    }
  }

  async _setForm(invoices_id, currents_year, temps_id) {
    const { user, handleGetInvoiceRutin, location } = this.props;
    const { state = {} } = location;
    const { data } = state;
    const { organizations_id } = user;
    const { form } = this.state;

    try {
      const payload = await handleGetInvoiceRutin({
        invoices_id,
        currents_year,
        temps_id,
      });
      this.setState({
        form: {
          value: {
            invoices_id: invoices_id,
            no_va: payload.temps_id,
            name: data.name,
            entrance_year: payload.academics_year,
            // currents_year: payload.currents_year,
            periode: payload.periode,
            total: payload.total,
            // address: data.address,
            // id: form.value.id,
            // students_id: form.value.students_id,
            // currents_year: form.value.currents_year ? form.value.currents_year : nextyear,
            tabelpayments: map(payload.tablepayments, detail => ({
              payments_id: detail.payments_id,
              nominal:detail.nominal,
              edit:true,
            })),
          },
          error: {
          },
        },
      });
    } catch (err) {
      // err action
    }
  }

  async getYearsOfEducationsOptions(filters={}) {
    const { getYearsOfEducationsOptions, location } = this.props;
    const { state = {} } = location;
    const { isEdit = false } = state;

    const res = await getYearsOfEducationsOptions(filters);

    if (!isEdit) {
      map(res, (data, idx) => {
        var date = new Date();
        var start_date = new Date(data.attributes.start_date)
        if (date > start_date) {
          // tahun ajaran selanjutnya
          if (res[idx+1]) {
            this.setState(prevState => ({
              ...prevState,
              form: {
                ...prevState.form,
                value: {
                  ...prevState.form.value,
                  nextyear: res[idx+1].value,
                  // month: new Date(res[idx+1].attributes.start_date).getMonth() + 1
                  month: 7
                }
              }
            }))
          }
        }
      });
    }

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async getPrmPayments() {
    const { getPrmPayments } = this.props;
    const res = await getPrmPayments();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        prm_payments: res,
      },
    }));
  }

  render() {
    const { location, getLevels, getclassrooms, user } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data, getdata } = state;
    const { form, param } = this.state;
    const { tabelpayments, nextyear, month } = form.value;

    const bulan = [
      { label: 'Juli', value: 0, month: 7 },
      { label: 'Agustus', value: 1, month: 8 },
      { label: 'September', value: 2, month: 9 },
      { label: 'Oktober', value: 3, month: 10 },
      { label: 'November', value: 4, month: 11 },
      { label: 'Desember', value: 5, month: 12 },
      { label: 'Januari', value: 6, month: 1 },
      { label: 'Februari', value: 7, month: 2 },
      { label: 'Maret', value: 8, month: 3 },
      { label: 'April', value: 9, month: 4 },
      { label: 'Mei', value: 10, month: 5 },
      { label: 'Juni', value: 11, month: 6 },
    ];

    form.value.total = 0;
    {map(tabelpayments, (list, idx) => {
      if (list.payments_id && !isEmpty(param.prm_payments)) {
        list.is_discount = param.prm_payments.find(i => i.value == list.payments_id).is_discount
        // if (list.payments_id == 42 || list.payments_id == 43) {
        if (list.is_discount == 1) {
          form.value.total-=Number(list.nominal)
        } else {
          form.value.total+=Number(list.nominal)
        }
      }
    })}

    if(form.value.total < 0) {
      form.error.total = "Total minus."
    } else {
      delete form.error.total;
    }

    form.value.currents_year = form.value.currents_year ? form.value.currents_year : nextyear
    form.value.index = form.value.index ? form.value.index : (month ? bulan.find((i) => i.month == month).value : "")

    return (
      <div className="student-list">
        <div className="budget__title">
          <h1>SPP </h1>
          <hr></hr>
        </div>
        <form>
          <div className="result__content">
            <div className="nilai-kognitif__custom-form">
              <div className="kode-matpel__custom-form-row">
                <div className="kode-matpel__custom-form-row__field">
                  <Input
                    name="no_va"
                    label="No. VA"
                    value={data ? data.no_va : ""}
                    onChange={this._onFormChange}
                    error={form.error.no_va || ""}
                    readOnly
                  />
                </div>
                <div className="kode-matpel__custom-form-column__field">
                  <Input
                    name="name"
                    label="Nama"
                    value={data ? data.name : ""}
                    onChange={this._onFormChange}
                    error={form.error.name || ""}
                    readOnly
                  />
                </div>
              </div>
              <div className="kode-matpel__custom-form-row">
                <div className="kode-matpel__custom-form-row__field">
                  {(!isEdit) && (
                    <Select
                      name="index"
                      label="Bulan Mulai Invoice *"
                      onChange={this._onFormChange}
                      data={bulan}
                      placeholder={"Pilihan"}
                      value={form.value.index}
                      error={form.error.index}
                      isRequired
                    />
                  )}
                  {(isEdit) && (
                    <Input
                      name="month"
                      label="Bulan"
                      value={getdata ? getdata.month : ""}
                      onChange={this._onFormChange}
                      error={form.error.month || ""}
                      readOnly
                    />
                  )}
                </div>
                <div className="kode-matpel__custom-form-column__field">
                  <Input
                    name="entrance_year"
                    label="Tahun Masuk"
                    value={data ? data.entrance_year : ""}
                    onChange={this._onFormChange}
                    error={form.error.entrance_year || ""}
                    readOnly
                  />
                </div>
              </div>
              <div className="kode-matpel__custom-form-row">
                <div className="kode-matpel__custom-form-row__field">
                  {(!isEdit) && (
                    <Select
                      name="currents_year"
                      label="Tahun Ajaran *"
                      onChange={this._onFormChange}
                      data={param.periods}
                      placeholder={"Pilihan"}
                      value={form.value.currents_year || ""}
                      error={form.error.currents_year}
                      isRequired
                      disabled
                    />
                  )}
                </div>
                <div className="kode-matpel__custom-form-column__field">
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <table className="manage-rapbs-plafon__form-table table-invoice">
            <thead>
              <tr>
                <th colSpan="2">Data *</th>
              </tr>
            </thead>
            <tbody>
              {map(tabelpayments, (list, idx) => (
                <tr key={`plafon_journal_data__${idx}`}>
                  <td>
                    <div className="kode-matpel__custom-form-row">
                      <div className="kode-matpel__custom-form-row__field">
                        <Select
                          label="Nama Pembayaran *"
                          name="payments_id"
                          onChange={this._onFormChange}
                          placeholder="Pilihan"
                          data={param.prm_payments}
                          value={list.payments_id}
                          error={list.error_payments}
                          // isRequired
                          data-input-array
                          arrayPosition={idx}
                          data-array-position={idx}
                          data-field-name="tabelpayments"
                          disabled = { list.edit ? true : false }
                        />
                      </div>
                      <div className="kode-matpel__custom-form-column__field" style={{flexDirection: "unset"}}>
                        <label style={{paddingTop: "30px", paddingRight: "20px"}}>Rp.</label> 
                        <Input
                          // type="number"
                          name="nominal"
                          label={"Nominal " + (list.edit ? "":"*")}
                          onChange={this._onFormChange}
                          value={list.nominal}
                          // error={form.error.nominal}
                          // isRequired
                          data-input-array
                          arrayPosition={idx}
                          data-array-position={idx}
                          data-field-name="tabelpayments"
                          isNumber
                        />
                      </div>
                    </div>

                  </td>
                  <td>
                    {(!list.edit) && (
                      <Button onClick={() => this._onDeleteList(idx)} icon="icon-trash-o"></Button>
                    )}
                  </td>
                  
                </tr>
                
              ))}
            </tbody>
            <tfoot>
              <tr className="manage-rapbs-plafon__add-more">
                <td colSpan={2}>
                  <div>
                    <Button icon="icon-plus" onClick={this._onAddList}></Button>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>                    
                    <div className="kode-matpel__custom-form-row">
                      <div className="kode-matpel__custom-form-row__field">
                      </div>
                      
                      <div className="kode-matpel__custom-form-column__field" style={{flexDirection: "unset"}}>
                        <label style={{paddingTop: "30px", paddingRight: "20px"}}>Rp.</label> 
                        <Input
                          name="total"
                          label="Total"
                          onChange={this._onFormChange}
                          value={form.value.total}
                          error={form.error.total}
                          isNumber
                        />
                      </div>
                    </div>
                  </div>
                </td>
                <td></td>
              </tr>
            </tfoot>
          </table>
          <br></br>
          <div className="student-list__header">
            <div className="student-list__button">
              {isEdit ? 
                <Button type="button" title="Update" onClick={this.onEdit} />
              :
                <Button type="button" title="Simpan" onClick={this.onSubmit} />
              }
            </div>
          </div>
        </form>
      </div>
    );
  }
}
CreateInvoiceRutin.propTypes = {
  handleGetKlapper: PropTypes.func,
  handleDeleteKlapper: PropTypes.func,
};
CreateInvoiceRutin.defaultProps = {
  handleGetKlapper: noop,
  handleDeleteKlapper: noop,
};
