import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop } from "lodash";
import {
  FileInput,
  Input,
  Select,
  Textarea,
  SelectMultiple,
  InputDate
} from "../../../../components/base/index";
import { Multiselect } from "multiselect-react-dropdown";

export default class PengumumanForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: props.status === !isEmpty(props.data),
      allOption: {
        label: "Select all",
        value: "*",
      },
    };
    this.multiselectRef = React.createRef();
    this._onFormChange = this._onFormChange.bind(this);
    this._onChangeMulti = this._onChangeMulti.bind(this);
    this.onUpload = this.onUpload.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this._onRemoveMulti = this._onRemoveMulti.bind(this);
    this.selectAllItems = this.selectAllItems.bind(this);
  }
  _onFormChange(event) {
    const { onChange } = this.props;
    onChange(event);
  }
  selectAll() {
    const { param } = this.props;
    if (param.student && param.student.length) {
      for (let i = 0; i < param.student.length; i++) {
        param.student[i].selected = "true";
      }
      return param.student;
    }
  }

  _onChangeMulti(value, name) {
    const { onFormChange } = this.props;
    onFormChange({
      target: {
        name,
        value,
        dataset: {},
      },
    });
  }

  _onRemoveMulti(selectedList, removedItem) {
    const { onFormChange } = this.props;
    onFormChange({
      target: {
        name: removedItem,
        value: selectedList,
        dataset: {},
      },
    });
  }

  selectAllItems() {
    // By calling the belowe method will reset the selected values programatically
    this.multiselectRef.current.getSelectedItems();
  }

  componentDidMount() {
    console.log(JSON.stringify(this.props));
  }
  async onUpload(event) {
    const { handleUploadAnnouncement, onChange, idx, onFormChange, showEdit } =
      this.props;
    const { target } = event;
    const { files, dataset } = target;
    const upload = files[0];

    if (upload) {
      const filename = upload.name;
      const res = await handleUploadAnnouncement({
        file: upload,
      });
      onFormChange({
        target: {
          name: "file",
          value: JSON.stringify({
            path: res.path,
            url: res.url,
            filename,
          }),
          dataset: {
            ...dataset,
            inputArray: false,
          },
        },
      });
    }
  }

  removeFile(event) {
    const { handleFileUpload, onRemoveFile, idx } = this.props;
    const { target } = event;

    onRemoveFile(idx);
  }
  render() {
    const { onFormChange, form, param, data, student, classrooms, levels } =
      this.props;
    const { value } = form;
    let defaultValue = [{ label: "SEMUA KELAS", value: "0" }];
    const paramclass = defaultValue.concat(param.classrooms);
    // console.log(param.classrooms)

    return (
      <div className="buat-pelanggaran__custom-form">
        <div className="buat-pelanggaran__custom-form-column">
          <div className="buat-pelanggaran__custom-form-column__field">
            <FileInput
              data-input-type="file"
              noMargin
              type="file"
              name="upload"
              label="Upload File"
              onChange={this.onUpload}
              onRemoveFile={this.removeFile}
              fileName={value.upload ? value.upload : ""}
              displayName={value.upload ? value.upload : "default"}
            />

            <Input
              type="text"
              name="name"
              label="Judul*"
              onChange={onFormChange}
              value={form.value.name}
              error={form.error.name || ""}
            />
            <Textarea
              type="text"
              name="description"
              label="Deskripsi"
              onChange={onFormChange}
              value={form.value.description}
              error={form.error.description || ""}
            />
            <Input
              type="text"
              name="hyperlink"
              label="Hyperlink"
              onChange={onFormChange}
              value={form.value.hyperlink}
              error={form.error.hyperlink || ""}
            />
            <Select
              name="folder"
              label="Pilih Folder"
              placeholder={"Pilihan"}
              data={param.folder}
              onChange={onFormChange}
              value={form.value.folder}
              error={form.error.folder || ""}
            />
            <InputDate
              type="date"
              name="publish_date"
              label="Tanggal Mulai*"
              onChange={onFormChange}
              value={form.value.publish_date}
              error={form.error.publish_date || ""}
            />
            <InputDate
              type="date"
              name="tgl_selesai"
              label="Tanggal Selesai*"
              onChange={onFormChange}
              value={form.value.tgl_selesai}
              error={form.error.tgl_selesai || ""}
            />
            <Select
              name="levels"
              label="Pilih Unit*"
              placeholder={"Pilihan"}
              disabled
              data={param.levels}
              onChange={onFormChange}
              value={form.value.levels}
              error={form.error.levels || ""}
            />
            <p>Pilih Kelas*</p>

            <SelectMultiple
              name="classrooms"
              style={{ width: "50%" }}
              options={param.classrooms}
              disable={form.value.levels ? false : true}
              displayValue="label" // Property name to display in the dropdown options
              showCheckbox={true}
              //  selectedAll={this.selectAll()}
              values={form.value.classrooms}
              onRemove={(value) => this._onRemoveMulti(value, "classrooms")}
              // selectedValues={form.value.classrooms}
              onSelect={(value) => this._onChangeMulti(value, "classrooms")}
            />
            <p>Siswa</p>

            <SelectMultiple
              name="students"
              style={{ width: "50%" }}
              options={param.student}
              // disable	={form.value.classrooms?false:true}
              displayValue="label" // Property name to display in the dropdown options
              showCheckbox={true}
              values={form.value.students}
              //  selectAllText="Select All"
              // ref={this.selectAllItems()}
              // selectedValues={form.value.students}
              // value={form.value.students}
              onRemove={(value) => this._onRemoveMulti(value, "students")}
              // error={form.error.students || ''}
              onSelect={(value) => this._onChangeMulti(value, "students")}
            />

            <p>Karyawan</p>

            <SelectMultiple
              name="teachers"
              style={{ width: "50%" }}
              options={param.teacher}
              displayValue="label" // Property name to display in the dropdown options
              showCheckbox={true}
              values={form.value.teachers}
              //  selectAllText="Select All"
              // ref={this.selectAllItems()}
              // selectedValues={form.value.students}
              // value={form.value.students}
              onRemove={(value) => this._onRemoveMulti(value, "teachers")}
              // error={form.error.students || ''}
              onSelect={(value) => this._onChangeMulti(value, "teachers")}
            />
          </div>
        </div>
      </div>
    );
  }
}
PengumumanForm.propTypes = {
  onFormChange: PropTypes.func,
  onRemoveFile: PropTypes.func,
  onUpload: PropTypes.func,
  showEdit: PropTypes.bool,
  form: PropTypes.object.isRequired,
  student: PropTypes.array,
  classrooms: PropTypes.array,
  levels: PropTypes.array,
};
PengumumanForm.defaultProps = {
  student: [],
  levels: [],
  classrooms: [],
  showEdit: true,
  onFormChange: noop,
  onRemoveFile: noop,
};
