import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop } from "lodash";
import { Input, Select, InputDate, Textarea } from "../../../components/base/index";
import { GENDER } from "../../../variables/common.variable";
import {
  commaFormatted,
  normalizeAmount,
  manageGetRegistration,
} from "../../../utils/transformer.util";

export default class OrtuIbuForm extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._filterOptions = this._filterOptions.bind(this);
    this.state = {
      original: {
        sub_districts: props.sub_districts || [],
        districts: props.districts || [],
        cities: props.cities || [],
        provinces: props.provinces || [],
      },
      options: {
        sub_districts: [],
        districts: [],
        cities: [],
        provinces: [],
      },
    };
  }

  // componentDidUpdate({
  //   provinces: prevP,
  //   cities: prevC,
  //   sub_districts: prevS,
  //   districts: prevD,
  // }) {
  //   const {
  //     provinces = [],
  //     cities = [],
  //     sub_districts = [],
  //     districts = [],
  //   } = this.props;
  //   if (
  //     JSON.stringify(provinces) !== JSON.stringify(prevP) ||
  //     JSON.stringify(cities) !== JSON.stringify(prevC) ||
  //     JSON.stringify(districts) !== JSON.stringify(prevD) ||
  //     JSON.stringify(sub_districts) !== JSON.stringify(prevS)
  //   ) {
  //     this.setState({
  //       original: {
  //         provinces,
  //         cities,
  //         districts,
  //         sub_districts,
  //       },
  //       options: {
  //         provinces,
  //         cities,
  //         districts,
  //         sub_districts,
  //       },
  //     });
  //   }
  // }

  _onFormChange(ev) {
    const { onChange } = this.props;
    onChange(ev);
  }

  _filterOptions(name) {
    const { original } = this.state;
    const { form = {} } = this.props;
    const { value = {} } = form;

    if (name === "cities") {
      return original.cities.filter(
        (o) => o.data.provinces_id == value.mother_provincess_id
      );
    } else if (name === "districts") {
      return original.districts.filter(
        (o) => o.data.cities_id == value.mother_city_id
      );
    } else if (name === "sub_districts") {
      return original.sub_districts.filter(
        (o) => o.data.districts_id == value.mother_districts_id
      );
    } else {
      return original.provinces;
    }
  }

  render() {
    const { options, original } = this.state;
    const { provinces, sub_districts, districts, cities } = options;
    const { form, idx, onChange, classes, param, param2 } = this.props;

    if (form.value.mother_provincess_id == "") {
      form.value.mother_city_id = "";
    }
    if (form.value.mother_city_id == "") {
      form.value.mother_districts_id = "";
    }
    if (form.value.mother_districts_id == "") {
      form.value.mother_sub_districts_id = "";
    }

    return (
      <div className="manage-registration__custom-form">
        <h1>Ibu</h1>

        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="mother_name"
              label="Nama Lengkap *"
              onChange={this._onFormChange}
              value={form.value.mother_name}
              error={form.error.mother_name || ""}
              isRequired
            />
          </div>
          <div className="manage-registration__custom-form-column__field">
            <Input
              type="text"
              name="mother_nik"
              label="NIK *"
              onChange={this._onFormChange}
              value={form.value.mother_nik}
              error={form.error.mother_nik || ""}
              isRequired
              maxLength={16}
            />
          </div>
        </div>

        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="mother_religions_id"
              label="Agama *"
              onChange={this._onFormChange}
              placeholder="Pilihan"
              value={form.value.mother_religions_id}
              error={form.error.mother_religions_id || ""}
              data={param.religions}
              isRequired
            />
          </div>
          <div className="manage-registration__custom-form-column__field">
            <Select
              name="mother_citizenships_id"
              label="Kewarganegaraan"
              onChange={this._onFormChange}
              placeholder="Pilihan"
              value={form.value.mother_citizenships_id}
              error={form.error.mother_citizenships_id || ""}
              data={param.citizenships}
            />
          </div>
        </div>

        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="mother_educations_id"
              label="Pendidikan Terakhir"
              onChange={this._onFormChange}
              placeholder="Pilihan"
              value={form.value.mother_educations_id}
              error={form.error.mother_educations_id || ""}
              data={param.educations}
              isRequired
            />
          </div>
          <div className="manage-registration__custom-form-row-input__field">
            <Input
              type="text"
              name="mother_pob"
              label="Tempat Tanggal Lahir *"
              onChange={this._onFormChange}
              value={form.value.mother_pob}
              error={form.error.mother_pob || ""}
              isRequired
            />
            <div className="manage-registration__custom-form-margin-left">
              <InputDate
                type="date"
                name="mother_dob"
                label="Tanggal *"
                onChange={this._onFormChange}
                value={form.value.mother_dob}
                error={form.error.mother_dob || ""}
                isRequired
              />
            </div>
          </div>
        </div>

        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="mother_phone"
              label="No Telpon *"
              onChange={this._onFormChange}
              value={form.value.mother_phone}
              error={form.error.mother_phone || ""}
              isRequired
            />
          </div>

          <div className="manage-registration__custom-form-row__field">
            <Select
              name="mother_occupations_id"
              label="Pekerjaan *"
              onChange={this._onFormChange}
              data={param.occupations}
              placeholder="Pilihan"
              value={form.value.mother_occupations_id}
              error={form.error.mother_occupations_id || ""}
              isRequired
            />
          </div>
        </div>

        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              name="mother_position"
              label="Jabatan"
              onChange={this._onFormChange}
              value={form.value.mother_position}
              error={form.error.mother_position || ""}
              isRequired
            />
          </div>

          <div className="manage-registration__custom-form-column__field">
            <Input
              type="nominal"
              name="mother_salary"
              label="Penghasilan *"
              onChange={this._onFormChange}
              value={form.value.mother_salary}
              error={form.error.mother_salary || ""}
              isRequired
            />
          </div>
        </div>

        <div className="manage-registration__custom-form-row">
          <Textarea
            type="text"
            name="mother_address"
            label="Alamat Tempat Tinggal *"
            onChange={this._onFormChange}
            value={form.value.mother_address}
            error={form.error.mother_address || ""}
            isRequired
          />
        </div>

        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              name="mother_status"
              label="Status/Kondisi"
              onChange={this._onFormChange}
              value={form.value.mother_status}
              error={form.error.mother_status || ""}
            />
          </div>
          <div className="manage-registration__custom-form-column__field">
            <Input
              name="mother_provincess_id"
              value={form.value.mother_provincess_id}
              error={form.error.mother_provincess_id || ""}
              placeholder="Pilihan"
              // data={this._filterOptions("provinces")}
              label="Propinsi"
              onChange={this._onFormChange}
            />
            {/* {console.log(form.value.provinces)}  */}
          </div>
        </div>

        {form.value.mother_provincess_id ? (
          <div>
            <div className="manage-registration__custom-form-row">
              <div className="manage-registration__custom-form-row__field">
                <Input
                  name="mother_city_id"
                  label="Kabupaten/Kota"
                  onChange={this._onFormChange}
                  placeholder="Pilihan"
                  value={form.value.mother_city_id}
                  error={form.error.mother_city_id || ""}
                  // data={this._filterOptions("cities")}
                />
              </div>

              <div className="manage-registration__custom-form-column__field"></div>
            </div>
            {form.value.mother_city_id ? (
              <div className="manage-registration__custom-form-row">
                <div className="manage-registration__custom-form-row__field"></div>

                <div className="manage-registration__custom-form-column-city__field">
                  <Input
                    name="mother_districts_id"
                    label="Kecamatan"
                    onChange={this._onFormChange}
                    // data={this._filterOptions("districts")}
                    placeholder="Pilihan"
                    value={form.value.mother_districts_id}
                    error={form.error.mother_districts_id || ""}
                  />
                </div>
              </div>
            ) : (
              ""
            )}

            {form.value.mother_districts_id ? (
              <div className="manage-registration__custom-form-row">
                <div className="manage-registration__custom-form-row__field">
                  <Input
                    name="mother_sub_districts_id"
                    label="Kelurahan"
                    onChange={this._onFormChange}
                    // data={this._filterOptions("sub_districts")}
                    placeholder="Pilihan"
                    value={form.value.mother_sub_districts_id}
                    error={form.error.mother_sub_districts_id || ""}
                  />
                </div>
                <div className="manage-registration__custom-form-column__field">
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
OrtuIbuForm.propTypes = {
  //   onFormChange: PropTypes.func,
  idx: PropTypes.number.isRequired,
  form: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  list: PropTypes.array.isRequired,
  provinces4: PropTypes.array,
  cities4: PropTypes.array,
  districts4: PropTypes.array,
  sub_districts4: PropTypes.array,
  classes: PropTypes.array,
  religions: PropTypes.array,
  citizenships: PropTypes.array,
  educations: PropTypes.array,
  occupations: PropTypes.array,
};
OrtuIbuForm.defaultProps = {
  //   onFormChange: noop,
  onChange: noop,
  provinces4: [],
  cities4: [],
  districts4: [],
  mother_city_id: [],
  mother_districts_id: [],
  sub_districts4: [],
  religions: [],
  citizenships: [],
  classes: [],
  educations: [],
  occupations: [],
};