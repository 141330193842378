import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop } from "lodash";
import { Input, Select, InputDate, Textarea } from "../../../components/base/index";

export default class PPDBOrtuIbuForm extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this.state = {
    };
  }

  _onFormChange(ev) {
    const { onChange } = this.props;
    onChange(ev);
  }

  render() {
    const { form, param } = this.props;

    if (form.value.mother_provincess_id == "") {
      form.value.mother_city_id = "";
    }
    if (form.value.mother_city_id == "") {
      form.value.mother_districts_id = "";
    }
    if (form.value.mother_districts_id == "") {
      form.value.mother_sub_districts_id = "";
    }

    return (
      <div className="manage-registration__custom-form">
        <h1>Ibu</h1>

        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="mother_name"
              label="Nama Lengkap *"
              onChange={this._onFormChange}
              value={form.value.mother_name}
              error={form.error.mother_name || ""}
              isRequired
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="mother_nik"
              label="NIK *"
              onChange={this._onFormChange}
              value={form.value.mother_nik}
              error={form.error.mother_nik || ""}
              isRequired
              maxLength={16}
            />
          </div>
        </div>

        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="mother_religions_id"
              label="Agama *"
              onChange={this._onFormChange}
              placeholder="Pilihan"
              value={form.value.mother_religions_id}
              error={form.error.mother_religions_id || ""}
              data={param.religions}
              isRequired
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="mother_pob"
              label="Tempat Lahir *"
              onChange={this._onFormChange}
              value={form.value.mother_pob}
              error={form.error.mother_pob || ""}
              isRequired
            />
            <div className="manage-registration__custom-form-margin-left">
              <InputDate
                type="date"
                name="mother_dob"
                label="Tanggal Lahir *"
                onChange={this._onFormChange}
                value={form.value.mother_dob}
                error={form.error.mother_dob || ""}
                isRequired
              />
            </div>
          </div>
        </div>

        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            {/* <Select
              name="mother_citizenships_id"
              label="Kewarganegaraan"
              onChange={this._onFormChange}
              placeholder="Pilihan"
              value={form.value.mother_citizenships_id}
              error={form.error.mother_citizenships_id || ""}
              data={param.citizenships}
            /> */}
            <Select
              name="mother_educations_id"
              label="Pendidikan Terakhir"
              onChange={this._onFormChange}
              placeholder="Pilihan"
              value={form.value.mother_educations_id}
              error={form.error.mother_educations_id || ""}
              data={param.educations}
              isRequired
            />
          </div>
          <div className="manage-registration__custom-form-row-input__field">
            <Input
              type="text"
              name="mother_phone"
              label="No Telpon *"
              onChange={this._onFormChange}
              value={form.value.mother_phone}
              error={form.error.mother_phone || ""}
              isRequired
            />
          </div>
        </div>

        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="mother_occupations_id"
              label="Pekerjaan *"
              onChange={this._onFormChange}
              data={param.occupations}
              placeholder="Pilihan"
              value={form.value.mother_occupations_id}
              error={form.error.mother_occupations_id || ""}
              isRequired
            />
          </div>

          <div className="manage-registration__custom-form-row__field">
            <Input
              type="number"
              name="mother_salary"
              label="Penghasilan *"
              onChange={this._onFormChange}
              value={form.value.mother_salary}
              error={form.error.mother_salary || ""}
              isRequired
            />
          </div>
        </div>

        <div className="manage-registration__custom-form-row">
          <Textarea
            type="text"
            name="mother_address"
            label="Alamat Tempat Tinggal *"
            onChange={this._onFormChange}
            value={form.value.mother_address}
            error={form.error.mother_address || ""}
            isRequired
          />
        </div>

        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              name="mother_status"
              label="Status/Kondisi"
              onChange={this._onFormChange}
              value={form.value.mother_status}
              error={form.error.mother_status || ""}
            />
          </div>

          <div className="manage-registration__custom-form-row__field">
          </div>
        </div>

        {/* <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              name="mother_provincess_id"
              value={form.value.mother_provincess_id}
              error={form.error.mother_provincess_id || ""}
              placeholder="Pilihan"
              label="Propinsi"
              onChange={this._onFormChange}
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            {form.value.mother_provincess_id && (
              <Input
                name="mother_city_id"
                label="Kabupaten/Kota"
                onChange={this._onFormChange}
                placeholder="Pilihan"
                value={form.value.mother_city_id}
                error={form.error.mother_city_id || ""}
              />
            )}
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            {form.value.mother_city_id && (
              <Input
                name="mother_districts_id"
                label="Kecamatan"
                onChange={this._onFormChange}
                placeholder="Pilihan"
                value={form.value.mother_districts_id}
                error={form.error.mother_districts_id || ""}
              />
            )}
          </div>
          <div className="manage-registration__custom-form-row__field">
            {form.value.mother_districts_id && (
              <Input
                name="mother_sub_districts_id"
                label="Kelurahan"
                onChange={this._onFormChange}
                placeholder="Pilihan"
                value={form.value.mother_sub_districts_id}
                error={form.error.mother_sub_districts_id || ""}
              />
            )}
          </div>
        </div> */}
      </div>
    );
  }
}
PPDBOrtuIbuForm.propTypes = {
  idx: PropTypes.number.isRequired,
  form: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  list: PropTypes.array.isRequired,
  religions: PropTypes.array,
  citizenships: PropTypes.array,
  educations: PropTypes.array,
  occupations: PropTypes.array,
};
PPDBOrtuIbuForm.defaultProps = {
  onChange: noop,
  provinces4: [],
  mother_city_id: [],
  mother_districts_id: [],
  religions: [],
  citizenships: [],
  educations: [],
  occupations: [],
};
