import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import budgeting from '../../assets/img/finance/anggaran.png';
import bookkeeping from '../../assets/img/bookkeeping/jurnal_bank.png';

export default class Finance extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickBudgeting = this.onClickBudgeting.bind(this);
    this.onClickBookkeping = this.onClickBookkeping.bind(this);
    this.onClickReportFinance = this.onClickReportFinance.bind(this);
  }

  onClickBudgeting() {
    const { history } = this.props;
    history.push('/dashboard/keuangan/penganggaran');
  }
  onClickBookkeping() {
    const { history } = this.props;
    history.push('/dashboard/keuangan/pembukuan');
  }
  onClickReportFinance() {
    const { history } = this.props;
    history.push('/dashboard/keuangan/laporan-keuangan');
  }

  render() {
    return (
      <div className="finance">
        <div className="finance__list">
          <button onClick={this.onClickBudgeting}>
            <img src={budgeting} alt="Penganggaran" />
            <h3>Penganggaran</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickBookkeping}>
            <img src={bookkeeping} alt="pembukuan" />
            <h3>Pembukuan</h3>
          </button>
        </div>
        <div className="finance__list">
          <button onClick={this.onClickReportFinance}>
            <img src={budgeting} alt="pembukuan" />
            <h3>Laporan Keuangan</h3>
          </button>
        </div>
      </div>
    );
  }
}
Finance.propTypes = {
  history: PropTypes.object.isRequired,
};
