import { connect } from 'react-redux';
import Pembayaran from '../../../../views/Finance/Spp/Pembayaran/Pembayaran.view';
import { getStudentSPPOptions } from '../../../../states/thunks/options.thunk';
import { listInvoiceRutin, listInvoiceNonRutin, listPaymentGateway, paymentsOffline} from '../../../../states/thunks/academics.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    user: state.user || {},
  }
}
function mapDispatchToProps(dispatch) {
  return {
    getStudentSPPOptions: (payload) => dispatch(getStudentSPPOptions(payload)),
    handleListInvoiceRutin: (payload, goback) => dispatch(listInvoiceRutin(payload, goback)),
    handleListInvoiceNonRutin: (payload, goback) => dispatch(listInvoiceNonRutin(payload, goback)),
    handleListPaymentGateway: (payload, goback) => dispatch(listPaymentGateway(payload, goback)),
    handlePaymentsOffline: (payload, goback) => dispatch(paymentsOffline(payload, goback)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Pembayaran);



