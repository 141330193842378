import { connect } from 'react-redux';
import CatatSiswaPindah from '../../../views/Student/DataSiswa/CatatSiswaPindah.student.view';
import { getStudent } from '../../../states/thunks/student.thunk';
import { getClasses } from '../../../states/thunks/options.thunk';
import { getParamOptions, getStudentExitOptions, getStudentOptions, getParamOptionsAcademics } from '../../../states/thunks/options.thunk';
import {listMoveRecord} from '../../../states/thunks/academics.thunk';


function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    user: state.user,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    handleGetClasses: payload => dispatch(getClasses(payload)),
    handleGetStudent: () => dispatch(getStudent()),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleListMoveRecord: (payload, goback) => dispatch(listMoveRecord(payload, goback)),
    // getStudentOptions: (payload) => dispatch(getStudentOptions(payload)),
    getStudentExitOptions: (payload) => dispatch(getStudentExitOptions(payload)),
    getParamOptions: (payload, type) => dispatch(getParamOptions(payload, type)),

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CatatSiswaPindah);


