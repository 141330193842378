import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop } from "lodash";
import { Input, Select, FileInput, InputDate } from "../../../components/base/index";
import { GENDER } from "../../../variables/common.variable";
import {
  commaFormatted,
  normalizeAmount,
  manageGetRegistration,
} from "../../../utils/transformer.util";

export default class DataDiri extends Component {
  constructor(props) {
    super(props);
    // this._onFormChange2 = this._onFormChange2.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._onSetForm = this._onSetForm.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this._onRemoveMulti = this._onRemoveMulti.bind(this);
    this.onUpload = this.onUpload.bind(this);
  }

  _onSetForm() {
    const { location } = this.props;
    const { name } = location.state;
    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          name,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }

  _onFormChange(e) {
    const { onChange } = this.props;
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);

    onChange(e);
  }

  async onUpload(event) {
    const {
      handleUploadImageFileStudents,
      onChange,
      idx,
      onFormChange,
      showEdit,
    } = this.props;
    const { target } = event;
    const { files, dataset } = target;
    const upload_image = files[0];

    if (upload_image) {
      const filename = upload_image.name;
      const res = await handleUploadImageFileStudents({
        file: upload_image,
      });
      onChange({
        target: {
          name: "file",
          value: JSON.stringify({
            path: res.path,
            url: res.url,
            filename,
          }),
          dataset: {
            ...dataset,
            inputArray: false,
          },
        },
      });
    }
  }

  _onRemoveMulti(selectedList, removedItem) {
    const { onFormChange } = this.props;
    onFormChange({
      target: {
        name: removedItem,
        value: selectedList,
        dataset: {},
      },
    });
  }

  removeFile(event) {
    const { handleFileUpload, onRemoveFile, idx } = this.props;
    const { target } = event;

    onRemoveFile(idx);
  }

  render() {
    const {
      religions,
      citizenships,
      genders,
      blood_types,
      levels,
      form,
      param,
    } = this.props;
    const { value } = form;
    return (
      <div className="manage-registration__custom-form">
        <h1>Data Pribadi Siswa</h1>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="nis"
              label="NIS/NIM"
              onChange={this._onFormChange}
              value={form.value.nis}
              error={form.error.nis || ""}
            />
            <div className="manage-registration__custom-form-row-datadiri">
              <Input
                type="text"
                name="nisn"
                label="NISN *"
                onChange={this._onFormChange}
                value={form.value.nisn || ""}
                error={form.error.nisn || ""}
                isRequired
                maxLength={10}
              />
            </div>
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="first_name"
              label="Nama Depan * (sesuai Akte/Ijazah)"
              onChange={this._onFormChange}
              value={form.value.first_name}
              error={form.error.first_name || ""}
              isRequired
            />
            <div className="manage-registration__custom-form-row-datadiri">
              <Input
                type="text"
                name="last_name"
                label="Nama Belakang (sesuai Akte/Ijazah)"
                onChange={this._onFormChange}
                value={form.value.last_name}
                error={form.error.last_name || ""}
              />
            </div>
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="nick_name"
              label="Nama Panggilan"
              onChange={this._onFormChange}
              value={form.value.nick_name}
              error={form.error.nick_name || ""}
            />

            <div className="manage-registration__custom-form-row-datadiri">
              <FileInput
                data-input-type="file"
                noMargin
                type="file"
                name="upload_image"
                label="Upload File Foto"
                onChange={this.onUpload}
                onRemoveFile={this.removeFile}
                fileName={value.upload_image ? value.upload_image : ""}
                displayName={
                  value.upload_image ? value.upload_image : "default"
                }
              />
            </div>
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="genders_id"
              label="Jenis kelamin *"
              onChange={this._onFormChange}
              value={form.value.genders_id}
              error={form.error.genders_id || ""}
              placeholder={"Pilihan"}
              data={param.genders}
              isRequired
            />
            <div className="manage-registration__custom-form-row-datadiri">
              <InputDate
                type="date"
                name="entrance_date"
                label="Tanggal Masuk *"
                onChange={this._onFormChange}
                value={form.value.entrance_date}
                error={form.error.entrance_date || ""}
                isRequired
              />
            </div>
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="number"
              name="height"
              label="Tinggi Badan "
              onChange={this._onFormChange}
              value={form.value.height}
              error={form.error.height || ""}
            />
            <div className="manage-registration__custom-form-row-datadiri">
              <Input
                type="number"
                name="weight"
                label="Berat Badan"
                onChange={this._onFormChange}
                value={form.value.weight}
                error={form.error.weight || ""}
              />
            </div>
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="pob"
              label="Tempat *"
              onChange={this._onFormChange}
              value={form.value.pob}
              error={form.error.pob || ""}
              isRequired
            />
            <div className="manage-registration__custom-form-row-datadiri">
              <InputDate
                type="date"
                name="dob"
                label="Tanggal Lahir *"
                onChange={this._onFormChange}
                value={form.value.dob}
                error={form.error.dob || ""}
                isRequired
              />
            </div>
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="blood_types_id"
              label="Golongan darah"
              onChange={this._onFormChange}
              placeholder={"Pilihan"}
              data={param.blood_types}
              value={form.value.blood_types_id}
              error={form.error.blood_types_id || ""}
            />
          </div>
          <div className="manage-registration__custom-form-column__field">
            <Input
              type="text"
              name="hobby"
              label="Hobi"
              onChange={this._onFormChange}
              value={form.value.hobby}
              error={form.error.hobby || ""}
            />
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="disabilities"
              label="Penyakit dan Kelainan (bila ada)"
              onChange={this._onFormChange}
              value={form.value.disabilities}
              error={form.error.disabilities || ""}
            />
          </div>
          <div className="manage-registration__custom-form-column__field">
            <Select
              name="religions_id"
              label="Agama *"
              onChange={this._onFormChange}
              placeholder="Pilihan"
              value={form.value.religions_id}
              error={form.error.religions_id || ""}
              data={param.religions}
              isRequired
            />
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Select
              name="citizenships_id"
              label="Kewarganegaraan"
              onChange={this._onFormChange}
              placeholder="Pilihan"
              value={form.value.citizenships_id}
              error={form.error.citizenships_id || ""}
              data={param.citizenships}
            />
          </div>
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="number"
              name="nth_child"
              label="Anak ke"
              onChange={this._onFormChange}
              value={form.value.nth_child}
              error={form.error.nth_child || ""}
            />
            <div className="manage-registration__custom-form-row-datadiri">
              <Input
                type="number"
                name="siblings"
                label="Jumlah Saudara Kandung"
                onChange={this._onFormChange}
                value={form.value.siblings}
                error={form.error.siblings || ""}
              />
            </div>
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="language"
              label="Bahasa Yang Digunakan Sehari-hari"
              onChange={this._onFormChange}
              value={form.value.language}
              error={form.error.language || ""}
            />
          </div>
          <div className="manage-registration__custom-form-column__field">
            <Input
              type="text"
              name="email"
              label="Email"
              onChange={this._onFormChange}
              value={form.value.email}
              error={form.error.email || ""}
            />
          </div>
        </div>
        {/* <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="status"
              label="Status Anak"
              onChange={this._onFormChange}
              value={form.value.status}
              error={form.error.status || ""}
            />
          </div>
          <div className="manage-registration__custom-form-column__field">
          </div>
        </div> */}
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
            <Input
              type="text"
              name="phone"
              label="Nomor Telepon"
              onChange={this._onFormChange}
              value={form.value.phone}
              error={form.error.phone || ""}
            />
          </div>
          <div className="manage-registration__custom-form-column__field">
            <Input
              type="text"
              name="house_ownership"
              label="Tinggal dengan orangtua / saudara / asrama / kost"
              onChange={this._onFormChange}
              value={form.value.house_ownership}
              error={form.error.house_ownership || ""}
            />
          </div>
        </div>
        <div className="manage-registration__custom-form-row">
          <div className="manage-registration__custom-form-row__field">
          </div>
          <div className="manage-registration__custom-form-column__field">
            {/* <Select
              name="levels_id"
              label="Unit"
              onChange={this._onFormChange}
              placeholder={"Pilihan"}
              data={param.levels}
              // disabled
              value={form.value.levels_id}
              error={form.error.levels_id || ""}
            /> */}
          </div>
        </div>
      </div>
    );
  }
}
DataDiri.propTypes = {
  //   onFormChange: PropTypes.func,
  idx: PropTypes.number.isRequired,
  form: PropTypes.object.isRequired,
  handleGetProvinces: PropTypes.func,
  onChange: PropTypes.func,
  list: PropTypes.array.isRequired,
  religions: PropTypes.array,
  citizenships: PropTypes.array,
  genders: PropTypes.array,
  blood_types: PropTypes.array,
  levels: PropTypes.array,
};
DataDiri.defaultProps = {
  //   onFormChange: noop,
  onChange: noop,
  religions: [],
  citizenships: [],
  genders: [],
  blood_types: [],
  levels: [],
};