import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map, isEqual } from "lodash";
import StudentReportFilter from "../../../components/Filter/StudentReport.filter.component";
import ListForm from "../../../components/ListForm/ListForm.component";
import ContentTable from "../../../components/ContentTable/ContentTable.component";
import { validateRequiredFields } from "../../../utils/validation.util";
import {
  commaFormatted,
  normalizeAmount,
  formatData,
} from "../../../utils/transformer.util";
import {
  Button,
  Pagination,
  Input,
  Select,
} from "../../../components/base/index";
import { PENGISIAN_SIKAP_LIST_TABLE_FIELDS } from "../../../constants/Student/student.constant";
import language from "../../../languages";
import PengisianSikapForm from "./components/PengisianSikapForm.component";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class PengisianSikap extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._getParamOptions = this._getParamOptions.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._setForm = this._setForm.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this._getSubjectOptions = this._getSubjectOptions.bind(this);
    this._getTeacherSubjectsOptions =this._getTeacherSubjectsOptions.bind(this);
    this._getTypeExam = this._getTypeExam.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._getClassesOptions = this._getClassesOptions.bind(this);
    this._getPrmLevels = this._getPrmLevels.bind(this);

    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    this.state = {
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {
          levels_id,
          organizations_id: 3,
          students_id: "",
          indikator_sikap: [
            {
              pengisian_sikap: "",
              detail_nilai: "",
              poin: "",
            },
          ],
        },
        error: {
          indikator_sikap: "",
        },
      },
      param: {},
    };
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    console.log(state);
    if ((!isEdit || !id) && !isEqual(prevProps, this.props)) {
      this._onSearchContent({ page: 1 });
    }
  }

  componentDidMount() {
    const { location, getLevels, getClasses, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id, units_id } = school_unit;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    const { content } = this.state;
    const paramTypes = ["classes", "classrooms", "levels"];

    // console.log(location);
    paramTypes.forEach((type) => {
      // this._getParamOptions(type);
      this._getParamOptionsAcademics(type);
    });
    // if (isEdit && id) {
    //   this.setState({id:id})
    //   // this._setForm(data.id);
    // } else
    // this.handleGetData();
    // this.isMount = true;

    this._getSubjectOptions({ filters: { units_id: [school_unit.id] } });
    this._getTypeExam();
    this._getTeacherSubjectsOptions();
    this._getPeriodsOptions();
    this._getClassesOptions({ filters: { levels_id: [levels_id], units_id: [school_unit.id] } });
    // this._getPrmLevels({ filters: {units_id: [school_unit.id], organizations_id: 3 } });

  }

  async _getPrmLevels(filters = {}) {
    const { getPrmLevels, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getPrmLevels(filters);
    filters.organizations_id = 3;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        levels: res,
      },
    }));
  }

  async _getClassesOptions(filters = {}) {
    const { getClassesOptions, user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    filters.levels_id = levels_id;
    filters.units_id = school_unit.id;
    const res = await getClassesOptions(filters);
    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        classes: res,
      },
    }));
  }

  async _getSubjectOptions(filters = {}) {
    const { getSubjectOptions, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id, units_id } = school_unit;
    const { form } = this.state;
    const { subject_id } = form.value;
    const res = await getSubjectOptions(filters);
    const selectedSubject = res.find((item) => item.value == subject_id);
    filters.units_id = school_unit.id;

    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          kkm: selectedSubject ? selectedSubject.attributes.kkm : "",
        },
      },
      param: {
        ...prevState.param,
        subject: res,
      },
    }));
  }

  async _getTypeExam() {
    const { getTypeExam } = this.props;
    const res = await getTypeExam();
    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        type_exam: res,
      },
    }));
  }

  async _getTeacherSubjectsOptions(filters = {}) {
    const { getTeacherSubjectsOptions } = this.props;
    const res = await getTeacherSubjectsOptions(filters);
    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        teacher_subjects: res,
      },
    }));
  }

  async _getPeriodsOptions() {
    const { getPeriodsOptions } = this.props;
    const res = await getPeriodsOptions();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  async handleGetData(payload = {}) {
    const { handleListAchievement, user } = this.props;
    const { units_id, workingUnit } = user;
    let unit_id = units_id;

    if (unit_id === null && workingUnit && workingUnit.id) {
      unit_id = workingUnit.id;
    }

    const res = await handleListAchievement({ ...payload });

    this.setState({
      list: res,
    });
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this.handleGetData({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  _onClickAdd(data) {
    const { form } = this.state;
    const { indikator_sikap } = form.value;

    indikator_sikap.push({
      indikatorsikap: "",
      detail_nilai: "",
      poin: "",
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          indikator_sikap,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    if (type === "classes" || type === "classrooms") {
      filters.organizations_id = 3;
      filters.units_id = school_unit.id;
      // filters.levels_id = levels_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getParamOptions(type, filters = {}) {
    const { getParamOptions, user } = this.props;
    const { organizations_id } = user;
    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = 3;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptions(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _setForm(id) {
    const { handleGetAttitudeIndicator } = this.props;
    try {
      const payload = await handleGetAttitudeIndicator({ id });
      this.setState({
        form: {
          value: {
            id: payload.id,
            period: payload.period,
            date: payload.date,
            classrooms_id: payload.classrooms_id,
            hour: payload.hour,
            minute: payload.minute,
            nis: payload.nis,
            name: payload.name,
            type_achievement: payload.type_achievement,
            subject_id: payload.subject_id,
            order_of_achievement: payload.order_of_achievement,
            score: payload.score,
            description: payload.description,
            penalty: payload.penalty,
          },
          error: {
            // details: [],
          },
        },
      });
    } catch (err) {
      // err action
    }
  }

  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked;
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        if (name === "levels_id") {
          this._getClassesOptions({ filters: { levels_id: [value] } });
        }
        if (name === "classes_id") {
          this._getParamOptionsAcademics("classrooms", {
            classes_id: value,
            organizations_id: 3,
          });
        }
        if (name === "classes_id") {
          this._getSubjectOptions({ filters: { classes_id: value } });
        }
      }
    );
  }

  async onSubmit() {
    const { history, handleSaveAttitudeIndicator } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    const dataForm = form.value;
    await handleSaveAttitudeIndicator(value);
    history.push("/dashboard/kurikulum/ulangan/pengisian-nilai-kognitif");
    history.go(0);
  }

  async onEdit() {
    const { history, handleEditAttitudeIndicator } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    let canSaveB = false;
    const dataTabel = value.pelajaran;
    const dataForm = form.value;
    let a;
    await handleEditAttitudeIndicator(value);
    history.push(
      "/dashboard/kurikulum/ulangan/tambah-penilaian-nilai-kognitif"
    );
    // }
  }

  _renderButtons(data) {
    const { user } = this.props;
    const button = {};

    return (
      <td>
        <div className="table__actions">
          {/* <Button
                  onClick={() => this.onClickEdit(data)}
                  title='Edit'
                /> */}
        </div>
      </td>
    );
  }

  _onDeleteList(idx) {
    this.setState((prevState) => {
      const { indikator_sikap } = prevState.form.value;
      const list = prevState.form.value.indikator_sikap;
      list.splice(idx, 1);
      return {
        ...prevState,
        indikator_sikap: list,
      };
    });
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    const { user } = this.props;
    const { organizations_id } = user;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        if (name === "levels_id") {
          this._getParamOptionsAcademics("classrooms", {
            filters: { levels_id: value, organizations_id: 3 },
          });
        }
        this._onSearchContent();
      }
    );
  }

  async _onSearchContent(params = {}) {
    const { filters } = this.state;

    try {
      const { handleListStudentAttendance, user } = this.props;
      const { school_unit, workingUnit, id, user_group, organizations_id } =
        user;
      const { levels_id, units_id } = school_unit;
      const result = await handleListStudentAttendance({
        ...params,
        organizations_id: 3,
        units_id: school_unit.id,
        filters,
      });
      // if (this.isMount) {
      if (result) {
        result.forEach((item) => {
          if (!item.description) {
            item.description = "";
          }
        });
      }
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            absensiharian: result,
          },
        },
      }));

      // }
    } catch (err) {
      // if (this.isMount) {
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            absensiharian: [],
          },
        },
      }));
    }
    // }
  }

  render() {
    const { form, page, list, content, param, filters } = this.state;
    const { plafon } = form.value;
    const { indikator_sikap } = form.value;
    const { classrooms_id = "" } = filters;
    const academic_year = [
      { label: "2019", value: "2019" },
      { label: "2020", value: "2020" },
      { label: "2021", value: "2021" },
    ];
    const semester = [
      { label: "Ganjil", value: "ganjil" },
      { label: "Genap", value: "genap" },
    ];

    const model = [
      { label: "Praktek/Keterampilan/KI-3", value: "praktek" },
      { label: "Sikap/Afektif", value: "sikap" },
      { label: "Siswa Profil/Learning Record", value: "siswa profile" },
    ];

    return (
      <div className="absensi-rekap__custom-form">
        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            <Select
              label="Tahun Ajaran"
              name="period"
              onChange={this._onFormChange}
              placeholder="Pilihan"
              data={param.periods}
              value={form.value.period}
              // error={form.error.period || ''}
            />
          </div>
          <div className="absensi-rekap__custom-form-column__field">
            <Select
              type="unit"
              name="levels_id"
              label="Unit"
              placeholder="Pilihan"
              data={param.levels}
              disabled
              onChange={this._onFormChange}
              value={form.value.levels_id}
              error={form.error.levels_id || ""}
            />
          </div>
        </div>

        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            <Select
              name="semester"
              label="Semester"
              placeholder={"Pilihan"}
              onChange={this._onFormChange}
              data={semester}
              value={form.value.semester}
              error={form.error.semester || ""}
            />
          </div>
          <div className="data-pelanggaran__custom-form-row__field">
            <Select
              name="classes_id"
              label="Tingkat Kelas"
              onChange={this._onFormChange}
              data={param.classes}
              placeholder={"Pilihan"}
              value={form.value.classes_id}
              error={form.error.classes_id || ""}
            />
            <div className="data-pelanggaran__custom-form-row__field"></div>
            <Select
              type="text"
              name="classrooms_id"
              label="Kelas"
              placeholder="Pilih Kelas"
              data={param.classrooms}
              onChange={this._onFormChange}
              value={form.value.classrooms_id}
            />
          </div>
        </div>
        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            <Select
              type="text"
              name="type_of_exam_id"
              label="Jenis Ulangan"
              placeholder={"Pilihan"}
              data={param.type_exam}
              onChange={this._onFormChange}
              value={form.value.type_of_exam_id}
              error={form.error.type_of_exam_id || ""}
            />
          </div>

          <div className="absensi-rekap__custom-form-column__field">
            {/* <Select
              name="model"
              label="Model"
              onChange={this._onFormChange}
              data={model}
              placeholder="Pilihan"
              value={form.value.model}
              error={form.error.model || ""}
            /> */}
            <Select
              name="subject_id"
              label="Mata Pelajaran"
              onChange={this._onFormChange}
              data={param.subject}
              placeholder="Pilih Matapelajaran"
              value={form.value.subject_id}
              error={form.error.subject_id || ""}
            />
          </div>
        </div>

        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            <Input
              type="text"
              name="materi_indikator"
              label="Materi Indikator"
              placeholder="Pilihan"
              onChange={this._onFormChange}
              value={form.value.materi_indikator}
              error={form.error.materi_indikator || ""}
            />
          </div>
          <div className="absensi-rekap__custom-form-column__field">
            
          </div>
        </div>

        <PengisianSikapForm
          onAddList={this._onClickAdd}
          list={indikator_sikap}
          onDeleteList={this._onDeleteList}
          onFormChange={this._onFormChange}
          param={param}
        />

        <div className="student-list__header">
          {/* <div className="student-list__button">
          <Button
          title="Upload"
          onClick={this._onClickAddFolder}
        />
          </div> */}
          <div className="student-list__button">
            <Button type="button" title="Simpan" onClick={this.onSubmit} />
          </div>
        </div>
      </div>
    );
  }
}
PengisianSikap.propTypes = {
  handleGetStudent: PropTypes.func,
  handleGetclassrooms: PropTypes.func,
  history: PropTypes.object.isRequired,
};
PengisianSikap.defaultProps = {
  handleGetStudent: noop,
  HandleGetclassrooms: noop,
};
