import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop, map, capitalize, isEmpty } from 'lodash';
import { Button, Input, form } from '../../../components/base';
import ReactToPrint from "react-to-print";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class LaporanSekolah extends Component {
  constructor(props) {
    super(props);
    this.onClickEdit = this.onClickEdit.bind(this);
    this._getHeadmaster = this._getHeadmaster.bind(this);

    this.state = {
      isPrint: true,
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {
          headmaster: "",
          // levels_id,
          classes: [],
          levels: [],
          classes_id: "",
        },
        error: {
          classes: "",
          levels: "",
        },
      },
      param: {},
      filtered: false,
      headmaster: "",
    }
  }

  componentDidMount() {
    this.handleGetData();
    this._getHeadmaster();
  }

  async handleGetData(payload = {}) {
    const { handleListTenagaPendidik, handleListTotalSiswa, handleListTotalSiswaMajor, user } = this.props;
    const { school_unit, workingUnit, id, user_group, organizations_id } = user;
    const res = await handleListTenagaPendidik();
    const result = await handleListTotalSiswa({...payload, units_id: school_unit.id});
    const resMajor = await handleListTotalSiswaMajor({...payload, units_id: school_unit.id});
    this.setState({
      tenaga_pendidik: res,
      jumlah_siswa: result,
      total_major: resMajor,
    });
  }

  async _getHeadmaster(payload = {}) {
    const { getHeadmaster, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getHeadmaster({
      ...payload,
      organizations_id: 3,
      units_id: school_unit.id,
    });
    // filters.organizations_id = organizations_id;
    // filters.units_id = units_id;

    this.setState((prevState) => ({
      ...prevState,
      headmaster: res,
    }));
  }

  onClickEdit() {
    const { history } = this.props;
    history.push('/dashboard/tata-usaha/manajemen-kelas/laporan-sekolah/edit');
  }

  renderTotalSiswa(){
    const { jumlah_siswa } = this.state;
    const row = [];
    let subTotalMale = 0;
    let subTotalFemale = 0;

      map(jumlah_siswa, (item) => {
        subTotalMale+=Number(item.ttl_male)
        subTotalFemale+=Number(item.ttl_female)
      })

    row.push([
      <>
      <td style={{textAlign:"center"}}>{subTotalMale}</td>
      <td style={{textAlign:"center"}}>{subTotalFemale}</td>
      </>
    ])


    return row;
  }

  renderTotalSiswaPerKelas(){
    const { jumlah_siswa } = this.state;
    const row = [];
    let subTotal = 0;
    let subTotalMale = 0;
    let subTotalFemale = 0;
    let AllTotal = 0;
    let Total = 0;

      map(jumlah_siswa, (item) => {
        subTotal = Number(item.ttl_male) + Number(item.ttl_female)
        subTotalMale+=Number(item.ttl_male)
        subTotalFemale+=Number(item.ttl_female)
        row.push([
          <>
          <td colSpan={2} style={{textAlign: 'center'}}>{subTotal}</td>
          </>
        ])
      })
      AllTotal = subTotalMale + subTotalFemale
      Total += Number(AllTotal)
      row.push([
        <>
        <td colSpan={2} style={{textAlign: 'center'}}>{Total}</td>
        </>
      ])

    return row;
  }

  renderTotalSiswaKeseluruhan(){
    const { jumlah_siswa } = this.state;
    const row = [];
    let subTotalMale = 0;
    let subTotalFemale = 0;
    let AllTotal = 0;

      map(jumlah_siswa, (item) => {
        subTotalMale+=Number(item.ttl_male)
        subTotalFemale+=Number(item.ttl_female)
      })

      AllTotal = subTotalMale + subTotalFemale

    row.push([
      <>
      <td  style={{textAlign: 'center'}}>{subTotalMale}</td>
      <td  style={{textAlign: 'center'}}>{subTotalFemale}</td>
      <td  style={{textAlign: 'center'}}>{AllTotal}</td>
      </>
    ])


    return row;
  }

  renderMajor(){
    const {total_major} = this.state;
    const row = [];
      map(total_major, (item) => {
        // {map(item.detail, (data) => {
          
        // })}
        {isEmpty(item.detail)?(
          row.push([
            <>
            <td colSpan={2} style={{textAlign: 'center', width:"10%"}}>X</td>
            <td colSpan={2} style={{textAlign: 'center' , width:"10%"}}>XI</td>
            <td colSpan={2} style={{textAlign: 'center' , width:"10%"}}>XII</td>
            </>
          ])
        ):
          map(item.detail, (data) => (
            
             row.push([
              <>
              <td colSpan={2} style={{textAlign:"center"}}>{data.classes}</td>
              </>
             ]) 
          ))
        }}
      
     )
    return row;
  }

  renderLPMajor(){
    const {total_major} = this.state;
    const row = [];
    let subTotalMale = 0;
    let subTotalFemale = 0;

      map(total_major, (item) => (
        <>
        {subTotalMale = 0}
        {subTotalFemale = 0}
        {isEmpty(item.detail)?(
          row.push([
            <>
              <td style={{textAlign:"center"}}></td>
              <td style={{textAlign:"center"}}></td>
              <td style={{textAlign:"center"}}></td>
              <td style={{textAlign:"center"}}></td>
              <td style={{textAlign:"center"}}></td>
              <td style={{textAlign:"center"}}></td>
            </>
          ])
        ):
          map(item.detail, (data) => (
            <>
              {subTotalMale+=Number(data.ttl_male)}
              {subTotalFemale+=Number(data.ttl_female)}
              {
             row.push([
              <>
              <td style={{textAlign:"center"}}>{data.ttl_male}</td>
              <td style={{textAlign:"center"}}>{data.ttl_female}</td>
              </>
             ]) 
            }
             </>
          ))
        }
        {console.log(subTotalMale)}
        {row.push([
          <>
          <td>{subTotalMale}</td>
          <td>{subTotalFemale}</td>
          </>
        
        ])}
       
        </>
      )
      
     )
    return row;
  }

 

  renderTotalMajorPerkelas(){
    const {total_major} = this.state;
    const row = [];
    let subTotalMale = 0;
    let subTotalFemale = 0;
    let subTotal = 0;
    let AllTotal = 0;
    let Total = 0;
      map(total_major, (item) => (
        <>
        {subTotalMale = 0}
        {subTotalFemale = 0}
        {subTotal = 0}
        {AllTotal = 0}
        {Total = 0}
        {isEmpty(item.detail)?(
          row.push([
            <>
              <td colSpan={2} style={{textAlign:"center"}}></td>
              <td colSpan={2} style={{textAlign:"center"}}></td>
              <td colSpan={2} style={{textAlign:"center"}}></td>
            </>
          ])
        ):
          map(item.detail, (data) => (
            <>
              {subTotal = Number(data.ttl_male) + Number(data.ttl_female)}
              {subTotalMale+=Number(data.ttl_male)}
              {subTotalFemale+=Number(data.ttl_female)}
              {
             row.push([
              <>
              <td colSpan={2} style={{textAlign:"center"}}>{subTotal}</td>
              </>
             ]) 
            }
             </>
          ))
        }
          {AllTotal = subTotalMale + subTotalFemale}
          {Total += Number(AllTotal)}
        {row.push([
          <>
          <td colSpan={2} style={{textAlign:"center"}}>{Total}</td>
          </>
        
        ])}
       
        </>
      )
      
     )
    return row;
  }
  render() {
    const { handleListUnits, handleDeleteBudget, user } = this.props;
    const { school_unit } = user;
    const school_unit_name = school_unit.name.toUpperCase();
    const { isPrint, headmaster, tenaga_pendidik, jumlah_siswa, total_major} = this.state
    const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni",
    "Juli", "Agustus", "September", "Oktober", "November", "Desember"
    ];
    var month = monthNames[new Date().getMonth()];
    const date = new Date().getDate();
    const year = new Date().getFullYear();
    const componentRef = React.createRef();

    const printTest = `
    @media print {
      @page {
        page-break-inside: avoid
      }
    }
    `;

    let lengthJml = 0;
    {
      map(jumlah_siswa, (item) => {
        return lengthJml++;
      })
    }



    // console.log(total_major)
   
    return (
      <div className="student-list">
        <div className="budget__title">
          <h1>Laporan Sekolah</h1>
          <hr></hr>
          <br></br>
        </div>

        <div className="student-list__header">
          <div className="student-list__button">
            <Button
              title="Edit Tenaga Pendidik"
              onClick={this.onClickEdit}
            />
          </div>
          <div className="student-list__button">
            <ReactToPrint
              pageStyle={printTest}
              onBeforeGetContent={()=> {
                return new Promise((resolve) => {
                  setTimeout(() => {
                    this.setState(
                      { isPrint: false },
                      resolve
                    );
                  }, 500);
                });
              }}
              onAfterPrint={()=> this.setState({isPrint: true})}
              trigger={() => (
                <Button title="Cetak" />
              )}
              content={() => componentRef.current}
            />
          </div>
        </div>

      <div id="divPrint" style={{ border: "0px solid black", padding: "2rem", pageBreakInside: 'avoid' }} ref={componentRef}>

         <div className="manage-registration">
          <br></br>
            <table className="table-report-header">
                <tr>
                  <th rowSpan="2">
                    {/* <img style={{width:"70px"}} src={logo} /> */}
                  </th>
                  <th style={{textAlign:'center', fontFamily: 'Calibri' }}>
                    <h2>LAPORAN SEKOLAH</h2>
                  </th>
                </tr>
                <tr>
                  <th style={{textAlign:'center', fontFamily: 'Calibri' }}>
                    <h3>YAYASAN PENGUDI LUHUR</h3>
                  </th>
                </tr>
               
              </table>

              <table className="table-report-school">
              <tr>
                <td style={{width:"20%", textAlign:"center"}}>Bulan</td>
                <td style={{width:"20%", textAlign:"center"}}>{month+' '+year}</td>
                <td className='noborder' style ={{width:'10%'}}>
                </td>   
                <td style={{width:"20%", textAlign:"center"}}>Pembelajaran</td>    
                <td style={{width:"20%", textAlign:"center"}}>2021/2022</td>
              </tr>
            </table>
            <table className="table-report-school">
              <tr>
                <th className='noborder' >A. Profile Sekolah</th>
              </tr>
              <tr>
                <th className="tops" >Nama Sekolah</th>
                <th className="tops" colSpan={3} style={{width:"10%"}}>Akreditasi ABC</th>
                <th className="tops" >Alamat Sekolah</th>
                <th className="tops" >Nomor Telpon</th>
                <th className="tops" >Waktu Belajar</th>
                <th className="tops" >Jumlah Rombel</th>
              </tr>
              <tbody>
                <tr>
                  <td style={{textAlign:"center"}}>{school_unit_name}</td>
                  <td style={{textAlign:"center"}}>{school_unit.school_accreditation}</td>
                  <td style={{textAlign:"center"}}>{school_unit.school_accreditation}</td>
                  <td style={{textAlign:"center"}}>{school_unit.school_accreditation}</td>
                  <td style={{textAlign:"center"}}>{school_unit.address}</td>
                  <td style={{textAlign:"center"}}>{school_unit.phone}</td>
                  <td style={{textAlign:"center"}}>{school_unit.study_time}</td>
                  <td style={{textAlign:"center"}}>{school_unit.school_cluster}</td>
                </tr>
              </tbody>
            </table>

            <table className="table-report-school" style={{pageBreakInside: 'avoid'}}>
              <tr>
                <th colSpan={8} className='noborder' >B. Jumlah Tenaga Pendidik dan Kependidikan</th>
              </tr>
              </table>
                {map(tenaga_pendidik, (item) => (
                 
                  <table className="table-report-school">
                  <tr>
                  <th colSpan={item.details.length} className="tops" >{item.name}</th>
                  <th rowSpan={2} className="tops" >Jumlah</th>
                  </tr>
                  
                    <tr>
                    {map(item.details, (data) => (
                    <>
                      <td style={{textAlign:"center"}}>{data.name}</td>
                      </>
                       ))}
                    </tr>
                    <tr>
                    {map(item.details, (data) => (
                      <>
                      <td style={{textAlign:"center"}}>{data.nominal}</td>
                      </>
                       ))}
                       <td style={{textAlign:"center"}}>{item.total}</td>
                    </tr>
                    </table>
                ))}
              
              
          
            { school_unit.levels_id == 2 && (
              <table className="table-report-school" style={{pageBreakInside: 'avoid'}}>
              <tr>
                <th colSpan={3} className='noborder'>C. Jumlah Siswa Sekolah</th>
              </tr>
              <tr>
                <th colSpan={lengthJml + lengthJml} className="tops" >KB dan TK</th>
                <th rowSpan={2} colSpan={2}>JML</th>
              </tr>
              
                <tr>
                  {
                    map(jumlah_siswa, (item) => (
                      <>
                      <td colSpan={2} style={{textAlign:"center", width:'30%'}}>{item.classes}</td>
                      </>
                    ))
                  }
                </tr>
                <tr>
                  {
                    map(jumlah_siswa, (item) => (
                      <>
                      <td style={{textAlign:"center"}}>L</td>
                      <td style={{textAlign:"center"}}>P</td>
                      </>
                    ))
                  }
                   <td style={{textAlign:"center"}}>L</td>
                   <td style={{textAlign:"center"}}>P</td>
                </tr>
                <tr>
                  {
                    map(jumlah_siswa, (item) => (
                    <>
                      <td style={{textAlign:"center"}}>{item.ttl_male}</td>
                      <td style={{textAlign:"center"}}>{item.ttl_female}</td>
                    </>
                    ))
                  }
                  {this.renderTotalSiswa()}
                  
                </tr>
                <tr>
                  {this.renderTotalSiswaPerKelas()}
              </tr>
              </table>
            )}

          {school_unit.levels_id == 3 &&(
            <table className="table-report-school">
              <tr>
                <th colSpan={6} className='noborder' style={{pageBreakInside: 'avoid'}}>C. Jumlah Siswa Sekolah</th>
              </tr>
              <tr>
                <th colSpan={12} className="tops" >Sekolah Dasar, Kelas</th>
                <th rowSpan={2} colSpan={2} style={{fontWeight:"bold", fontSize:14}}>JML</th>
              </tr>
              <tr>
                <td colSpan={2} style={{textAlign:"center", width:'15%'}}>I</td>
                <td colSpan={2} style={{textAlign:"center", width:'15%'}}>II</td>
                <td colSpan={2} style={{textAlign:"center", width:'15%'}}>III</td>
                <td colSpan={2} style={{textAlign:"center", width:'15%'}}>IV</td>
                <td colSpan={2} style={{textAlign:"center", width:'15%'}}>V</td>
                <td colSpan={2} style={{textAlign:"center", width:'15%'}}>VI</td>
              </tr>
              <tr>
                <td style={{textAlign:"center"}}>L</td>
                <td style={{textAlign:"center"}}>P</td>
                <td style={{textAlign:"center"}}>L</td>
                <td style={{textAlign:"center"}}>P</td>
                <td style={{textAlign:"center"}}>L</td>
                <td style={{textAlign:"center"}}>P</td>
                <td style={{textAlign:"center"}}>L</td>
                <td style={{textAlign:"center"}}>P</td>
                <td style={{textAlign:"center"}}>L</td>
                <td style={{textAlign:"center"}}>P</td>
                <td style={{textAlign:"center"}}>L</td>
                <td style={{textAlign:"center"}}>P</td>
                <td style={{textAlign:"center"}}>L</td>
                <td style={{textAlign:"center"}}>P</td>
              </tr>
              <tr>
                {
                  map(jumlah_siswa, (item) => (
                  <>
                    <td style={{textAlign:"center"}}>{item.ttl_male}</td>
                    <td style={{textAlign:"center"}}>{item.ttl_female}</td>
                  </>
                  ))
                }
                  {this.renderTotalSiswa()}

              </tr>
              <tr>
                  {this.renderTotalSiswaPerKelas()}
              </tr>
            </table>
           )}

            {school_unit.levels_id == 4 && (
              <table className="table-report-school" style={{pageBreakInside: 'avoid'}}>
                <tr>
                <th colSpan={3} className='noborder'>C. Jumlah Siswa Sekolah</th>
              </tr>
              <tr>
                <th colSpan={6} className="tops" >SMP, Kelas</th>
                <th rowSpan={2} colSpan={2} style={{fontWeight:"bold", fontSize:14}}>JML</th>
              </tr>
              <tr>
                <td colSpan={2} style={{textAlign:"center", width:'30%'}}>VII</td>
                <td colSpan={2} style={{textAlign:"center", width:'30%'}}>VIII</td>
                <td colSpan={2} style={{textAlign:"center", width:'30%'}}>IX</td>
              </tr>
              <tr>
                <td style={{textAlign:"center"}}>L</td>
                <td style={{textAlign:"center"}}>P</td>
                <td style={{textAlign:"center"}}>L</td>
                <td style={{textAlign:"center"}}>P</td>
                <td style={{textAlign:"center"}}>L</td>
                <td style={{textAlign:"center"}}>P</td>
                <td style={{textAlign:"center"}}>L</td>
                <td style={{textAlign:"center"}}>P</td>
              </tr>
              <tr>
                {
                  map(jumlah_siswa, (item) => (
                  <>
                    <td style={{textAlign:"center"}}>{item.ttl_male}</td>
                    <td style={{textAlign:"center"}}>{item.ttl_female}</td>
                  </>
                  ))
                }
                  {this.renderTotalSiswa()}

              </tr>
              <tr>
                  {this.renderTotalSiswaPerKelas()}
              </tr>
              </table>
            )}

          {school_unit.levels_id == 5 &&(
            <table className="table-report-school">
            <tr>
              <th colSpan={3} className='noborder' style={{pageBreakInside: 'avoid'}}>C. Jumlah Siswa Sekolah</th>
            </tr>
            <tr>
              <th colSpan={50} className="tops" >SMA, Kelas</th>
            </tr>
            <tr>
              {
                map(total_major, (item) => (
                  <>
                    <th colSpan={6} className="tops" style={{textAlign:"center", width:'30%'}}>{item.name}</th>
                    <th rowSpan={2} colSpan={2} style={{fontWeight:"bold", fontSize:14}}>JML</th>
                  </>
                ))
              }
             
            </tr>
            <tr>
              {this.renderMajor()}
              
            </tr>
            <tr>
              <td style={{textAlign:"center"}}>L</td>
              <td style={{textAlign:"center"}}>P</td>
              <td style={{textAlign:"center"}}>L</td>
              <td style={{textAlign:"center"}}>P</td>
              <td style={{textAlign:"center"}}>L</td>
              <td style={{textAlign:"center"}}>P</td>
              <td style={{textAlign:"center"}}>L</td>
              <td style={{textAlign:"center"}}>P</td>

              <td style={{textAlign:"center"}}>L</td>
              <td style={{textAlign:"center"}}>P</td>
              <td style={{textAlign:"center"}}>L</td>
              <td style={{textAlign:"center"}}>P</td>
              <td style={{textAlign:"center"}}>L</td>
              <td style={{textAlign:"center"}}>P</td>
              <td style={{textAlign:"center"}}>L</td>
              <td style={{textAlign:"center"}}>P</td>

              <td style={{textAlign:"center"}}>L</td>
              <td style={{textAlign:"center"}}>P</td>
              <td style={{textAlign:"center"}}>L</td>
              <td style={{textAlign:"center"}}>P</td>
              <td style={{textAlign:"center"}}>L</td>
              <td style={{textAlign:"center"}}>P</td>
              <td style={{textAlign:"center"}}>L</td>
              <td style={{textAlign:"center"}}>P</td>
            </tr>
            <tr>
             {this.renderLPMajor()}
             {/* {this.renderTotalMajor()} */}
            </tr>
            <tr>
              {this.renderTotalMajorPerkelas()}
            </tr>
           
            </table>
           )}
           
          {school_unit.levels_id == 6 && (
            <table className="table-report-school" style={{pageBreakInside: 'avoid'}}>
            <tr>
              <th colSpan={3} className='noborder'>C. Jumlah Siswa Sekolah</th>
            </tr>
            <tr>
              <th colSpan={6} className="tops" >SMK, Kelas</th>
              <th rowSpan={2} colSpan={2} style={{fontWeight:"bold", fontSize:14}}>JML</th>
            </tr>
            <tr>
              <td colSpan={2} style={{textAlign:"center", width:'30%'}}>X</td>
              <td colSpan={2} style={{textAlign:"center", width:'30%'}}>XI</td>
              <td colSpan={2} style={{textAlign:"center", width:'30%'}}>XII</td>
            </tr>
            <tr>
                <td style={{textAlign:"center"}}>L</td>
                <td style={{textAlign:"center"}}>P</td>
                <td style={{textAlign:"center"}}>L</td>
                <td style={{textAlign:"center"}}>P</td>
                <td style={{textAlign:"center"}}>L</td>
                <td style={{textAlign:"center"}}>P</td>
                <td style={{textAlign:"center"}}>L</td>
                <td style={{textAlign:"center"}}>P</td>
              </tr>
              <tr>
                {
                  map(jumlah_siswa, (item) => (
                  <>
                    <td style={{textAlign:"center"}}>{item.ttl_male}</td>
                    <td style={{textAlign:"center"}}>{item.ttl_female}</td>
                  </>
                  ))
                }
                  {this.renderTotalSiswa()}

            </tr>
              <tr>
                {this.renderTotalSiswaPerKelas()}
            </tr>
          </table>
          )}
          
          

            { school_unit.levels_id == 7 && (
              <>
              <table className="table-report-school" style={{pageBreakInside: 'avoid'}}>
              <tr>
                <th colSpan={3} className='noborder'>C. Jumlah Siswa Sekolah</th>
              </tr>
              <tr>
                <th colSpan={lengthJml + lengthJml} className="tops" >TK SD</th>
                <th rowSpan={2} colSpan={2}>JML</th>
              </tr>
                <tr>
                  {
                    map(jumlah_siswa, (item) => ((
                      <>
                      <td colSpan={2} style={{textAlign:"center", width: "10%"}}>{item.classes}</td>
                      </>
                    )))
                  }
                </tr>
                <tr>
                  {
                    map(jumlah_siswa, (item) =>((
                      <>
                       <td style={{textAlign:"center"}}>L</td>
                       <td style={{textAlign:"center"}}>P</td>
                      </>
                    )))
                  }
                  <td style={{textAlign:"center"}}>L</td>
                  <td style={{textAlign:"center"}}>P</td>
                </tr>
                <tr>
                  {
                    map(jumlah_siswa, (item) => (
                    <>
                      <td style={{textAlign:"center"}}>{item.ttl_male}</td>
                      <td style={{textAlign:"center"}}>{item.ttl_female}</td>
                    </>
                    ))
                  }
                  {this.renderTotalSiswa()}
                  
                </tr>
                <tr>
                  {this.renderTotalSiswaPerKelas()}
              </tr>
              </table>
             </>
            )}
           
            <table className="table-report-school" style={{pageBreakInside: 'avoid'}}>
              <tr>
                <th className='noborder' colSpan={7} >D. Jumlah Siswa Berdasarkan Agama</th>
              </tr>
              <tr>
                <th className="tops" >Katolik</th>
                <th className="tops" >Protestan</th>
                <th className="tops" >Islam</th>
                <th className="tops" >Hindu</th>
                <th className="tops" >Budha</th>
                <th className="tops" >Konghuchu</th>
                <th style={{fontWeight:"bold", fontSize:14}} >Jumlah</th>
              </tr>
              <tbody>
                <tr>
                  <td style={{textAlign:"center"}}>{school_unit_name}</td>
                  <td style={{textAlign:"center"}}>{school_unit.school_accreditation}</td>
                  <td style={{textAlign:"center"}}>{school_unit.school_accreditation}</td>
                  <td style={{textAlign:"center"}}>{school_unit.school_accreditation}</td>
                  <td style={{textAlign:"center"}}>{school_unit.address}</td>
                  <td style={{textAlign:"center"}}>{school_unit.phone}</td>
                  <td style={{textAlign:"center"}}>{school_unit.study_time}</td>
                  <td style={{textAlign:"center"}}>{school_unit.school_cluster}</td>
                </tr>
              </tbody>
            </table>
            
            <table className="table-report-school" style={{pageBreakInside: 'avoid'}}>
              <tr>
                <th className='noborder' >E. Jumlah Siswa Berdasarkan Jenis Kelamin</th>
              </tr>
              <tr>
                <th className="tops" >Laki-laki</th>
                <th className="tops" >Perempuan</th>
                <th style={{fontWeight:"bold", fontSize:14}} >Jumlah</th>
              </tr>
              <tbody>
                <tr>
                {this.renderTotalSiswaKeseluruhan()}
                </tr>
              </tbody>
            </table>

            <table className="table-report-school" style={{width:"80%", pageBreakInside: 'avoid'}}>
              <tr>
                <th className='noborder' colSpan={5} >F. Mutasi Siswa Sekolah</th>
              </tr>
              <tr>
                <td className='noborder'style={{width:"2%"}}>a.</td>
                <td className='noborder'style={{width:"15%"}}>Mutasi Masuk :</td>
                <td style={{width:"15%"}}></td>
                <td className='noborder'style={{width:"5%"}}></td>
                <td className='noborder'style={{width:"2%"}}>b.</td>
                <td className='noborder'style={{width:"15%"}}>Mutasi Keluar :</td>
                <td style={{width:"15%"}}></td>
              </tr>
            </table>

            <table className="table-report-school" style={{pageBreakInside: 'avoid'}}>
              <tr>
                <th className='noborder' colSpan={5} >G. Finansial</th>
              </tr>
              <tr>
                <td className='noborder'style={{width:"5%"}}>a.</td>
                <td className='noborder'style={{width:"20%"}}>Rata-rata Uang Sekolah :</td>
                <td style={{width:"15%"}}></td>
                <td className='noborder'style={{width:"5%"}}></td>
                <td className='noborder'style={{width:"5%"}}>b.</td>
                <td className='noborder'style={{width:"20%"}}>Rata-rata UPS :</td>
                <td style={{width:"15%"}}></td>
              </tr>
              <tr>
                <td className='noborder' colspan={7} style={{width:"15%"}}>&nbsp;</td>
              </tr>
              <tr>
                <td className='noborder'style={{width:"5%"}}>c.</td>
                <td className='noborder'style={{width:"25%"}}>Rata-rata Uang Kegiatan :</td>
                <td style={{width:"15%"}}></td>
                <td className='noborder'style={{width:"5%"}}></td>
                <td className='noborder'style={{width:"5%"}}>d.</td>
                <td className='noborder'style={{width:"25%"}}>Rata-rata Uang Komite :</td>
                <td style={{width:"15%"}}></td>
              </tr>
            </table>


            <table className="table-report-school" style={{width:"70%", pageBreakInside: 'avoid'}}>
              <tr>
                <th className='noborder' colSpan={5} >Keterangan</th>
              </tr>
              <tr>
                <td className='noborder'>Yang dimaksud mutasi adalah siswa yang diterima atau keluar di tengah tahun pelajaran atau awal tahun pelajaran, tetapi bukan peserta didik baru yang diterima secara reguler pada tahun ajaran baru.</td>
              </tr>
            </table>

            <table className="table-report-school" style={{width:"90%", textAlign:"left"}}>
              <tr>
                <td className='noborder' colSpan={5} ></td>
                <td className='noborder' colSpan={5} style={{width:'30%', textAlign: 'left'}}>Surakarta, &nbsp; &nbsp;{date} {month} {year}</td>
              </tr>
              <tr>
                <td className='noborder' colSpan={5}></td>
                <td className='noborder' colSpan={5} style={{width:'30%', textAlign: 'left'}}>Koordinator {school_unit_name}</td>
              
              </tr>
              <tr>
                <td className='noborder' colSpan={5}>&nbsp;</td>
              </tr>
              <tr>
                <td className='noborder' colSpan={5}>&nbsp;</td>
              </tr>
              <tr>
                <td className='noborder' colSpan={5}></td>
                <td className='noborder' colSpan={5} style={{width:'30%',  textAlign: 'left'}}>{headmaster.full_name}</td>
              </tr>
            </table>


          </div>
        </div>

      </div>
    );
  }
}

LaporanSekolah.propTypes = {
  handleGetBudget: PropTypes.func,
  handleDeleteBudget: PropTypes.func,
  user: PropTypes.object,
  history: PropTypes.object.isRequired,
};
LaporanSekolah.defaultProps = {
  handleGetBudget: noop,
  handleDeleteBudget: noop,
  user: null,
};
