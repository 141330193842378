export const LIST_PENDAFTARAN_SISWA_LIST_TABLE_FIELDS = {
  label: [
    'No',
    'NISN',
    'No VA',
    'Nama Siswa',
    // 'Tahun Ajaran',
    // 'Keterangan',
    'Opsi',
  ],
  value: [
    {},
    { type: 'string', attribute: 'nisn' },
    { type: 'string', attribute: 'no_va' },
    { type: 'string', attribute: 'name' },
    // { type: 'string', attribute: 'entrance_year' },
    // { type: 'string', attribute: 'status' },
  ],
};
   export const JADWAL_KELAS_LIST_TABLE_FIELDS = {
    label: [
      'No',
      'Judul',
      'Unit',
      'Tanggal Mulai',
      'Tanggal Selesai',
      '',
    ],
    value: [
      {},
      { type: 'string', attribute: 'name' },
      { type: 'string', attribute: 'levels' },
      { type: 'string', attribute: 'publish_date' },
      { type: 'string', attribute: 'tgl_selesai' },
    ],
  };
  
    export const JADWAL_KARYAWAN_LIST_TABLE_FIELDS = {
      label: [
        'No',
        'Judul',
        'Unit',
        'Golongan',
        'Jabatan',
        '',
      ],
      value: [
        {},
        { type: 'string', attribute: 'name' },
        { type: 'string', attribute: 'units' },
        { type: 'string', attribute: 'golongan' },
        { type: 'string', attribute: 'jabatan' },
      ],
    };
    export const FOLDER_LIST_TABLE_FIELDS = {
      label: [
        'No',
        'Code',
        'Name',
        'Deskripsi',
        'Aktif',
        '',
      ],
      value: [
        {},
        { type: 'string', attribute: 'folder_code' },
        { type: 'string', attribute: 'folder_name' },
        { type: 'string', attribute: 'folder_description' },
        { type: 'string', attribute: 'folder_active'},
      ],
    };
  
    export const LIST_SOAL_FROM_TABEL = {
      label: [
        'No',
        'Tanggal',
        'Judul',
        'Mata Pelajaran',
        'Unit',
        'Status',
        'Opsi',
      ],
      value: [
        {},
        { type: 'string', attribute: 'kode_jenis' },
        { type: 'string', attribute: 'nama_jenis' },
      ],
    };
  
  
    export const ABSEN_REKAP_LIST_TABLE_FIELDS = {
      label: [
        'No',
        'NISN',
        'No VA',
        'Nama Siswa',
        'Status',
        'Keterangan',
        'Jumlah Hadir',
        '%',
        '',
      ],
      value: [
        {},
        { type: 'string', attribute: 'nisn' },
        { type: 'string', attribute: 'no_va' },
        { type: 'string', attribute: 'name' },
        { type: 'string', attribute: 'stataus' },
        { type: 'link', attribute: 'keterangan'},
        { type: 'string', attribute: 'jml_hadir' },
        { type: 'link', attribute: 'percent'},
      ],
    };

    export const FOLDER_CATAT_PELANGGARAN_TABLE_FIELDS = {
      label: [
        'No',
        'NISN',
        'No VA',
        'Nama Siswa',
        'Tanggal',
        'Mapel',
        // 'Pelanggaran',
        'Keterangan',
        'Sanksi( - )',
        'Skor',
        'Opsi',
      ],
      value: [
        {},
        { type: 'string', attribute: 'nisn' },
        { type: 'string', attribute: 'no_va' },
        { type: 'string', attribute: 'name' },
        { type: 'string', attribute: 'date' },
        { type: 'string', attribute: 'subject' },
        // { type: 'string', attribute: 'order_of_violation' },
        { type: 'string', attribute: 'description'},
        { type: 'string', attribute: 'penalty'},
        { type: 'string', attribute: 'score'},
      ],
    };

    export const FOLDER__LIST_CATAT_PRESTASI_FIELDS = {
      label: [
        'No',
        'NISN',
        'No VA',
        'Nama Siswa',
        'Tanggal',
        'Mapel',
        'Prestasi',
        'Keterangan',
        'Skor( - )',
        'Opsi',
      ],
      value: [
        {},
        { type: 'string', attribute: 'nisn' },
        { type: 'string', attribute: 'no_va' },
        { type: 'string', attribute: 'name' },
        { type: 'string', attribute: 'date' },
        { type: 'string', attribute: 'subject' },
        { type: 'string', attribute: 'order_of_achievement' },
        { type: 'string', attribute: 'description'},
        { type: 'string', attribute: 'score'},
      ],
    };

    export const FOLDER__LIST_CATAT_HARIAN_SISWA_FIELDS = {
      label: [
        'No',
        'NISN',
        'No VA',
        'Nama Siswa',
        'Tanggal',
        'Nama Guru',
        'Peristiwa',
        'Keterangan',
        'Opsi',
      ],
      value: [
        {},
        { type: 'string', attribute: 'nisn' },
        { type: 'string', attribute: 'no_va' },
        { type: 'string', attribute: 'name' },
        { type: 'string', attribute: 'date' },
        { type: 'string', attribute: 'teacher_name' },
        { type: 'string', attribute: 'name_of_activities'},
        { type: 'string', attribute: 'description'},
      ],
    };
  
    export const FOLDER__LIST_DISPLINER_CATAT_HARIAN_SISWA_FIELDS = {
      label: [
        'No',
        'NISN',
        'No VA',
        'Nama Siswa',
        'Tanggal',
        'Waktu',
        'Nama Guru',
        'Peristiwa',
        'Keterangan',
      ],
      value: [
        {},
        { type: 'string', attribute: 'nisn' },
        { type: 'string', attribute: 'no_va' },
        { type: 'string', attribute: 'name' },
        { type: 'string', attribute: 'date' },
        { type: 'string', attribute: 'time' },
        { type: 'string', attribute: 'teacher_name' },
        { type: 'string', attribute: 'name_of_activities'},
        { type: 'string', attribute: 'description'},
      ],
    };

    export const  FOLDER__LAPORAN_DISPLINER_FIELDS = {
      label: [
        'No',
        'Kelas',
        'Nama Siswa',
        'Tanggal',
        'Jenis Prestasi',
        'Prestasi Point',
        'Jenis Pelanggaran',
        'Pelanggaran Point',
        // 'Skor',
      ],
      value: [
        {},
        { type: 'string', attribute: 'classrooms_id' },
        { type: 'string', attribute: 'name' },
        { type: 'string', attribute: 'date' },
        { type: 'string', attribute: 'type_achievement'},
        { type: 'string', attribute: 'score_achievement'},
        { type: 'string', attribute: 'type_violation'},
        { type: 'string', attribute: 'score_violation'},
        // { type: 'string', attribute: 'score'},
      ],
    };

    export const FOLDER_CATAT_PELANGGARAN_REPORT_TABLE_FIELDS = {
      label: [
        'No',
        'NISN',
        'No VA',
        'Nama Siswa',
        'Tanggal',
        'Mapel',
        // 'Pelanggaran',
        'Keterangan',
        'Sanksi( - )',
        'Skor',
      ],
      value: [
        {},
        { type: 'string', attribute: 'nisn' },
        { type: 'string', attribute: 'no_va' },
        { type: 'string', attribute: 'name' },
        { type: 'string', attribute: 'date' },
        { type: 'string', attribute: 'subject' },
        // { type: 'string', attribute: 'order_of_violation' },
        { type: 'string', attribute: 'description'},
        { type: 'string', attribute: 'penalty'},
        { type: 'string', attribute: 'score'},
      ],
    };

    export const FOLDER__LIST_CATAT_PRESTASI__REPORT_FIELDS = {
      label: [
        'No',
        'NISN',
        'No VA',
        'Nama Siswa',
        'Tanggal',
        'Mapel',
        'Prestasi',
        'Keterangan',
        'Skor( - )',
      ],
      value: [
        {},
        { type: 'string', attribute: 'nisn' },
        { type: 'string', attribute: 'no_va' },
        { type: 'string', attribute: 'name' },
        { type: 'string', attribute: 'date' },
        { type: 'string', attribute: 'subject' },
        { type: 'string', attribute: 'order_of_achievement' },
        { type: 'string', attribute: 'description'},
        { type: 'string', attribute: 'score'},
      ],
    };

    export const PESERTA_EKSKUL_TABLE_FIELDS = {
      label: [
        'No',
        'NISN',
        'No VA',
        'Nama Siswa',
        // 'P/L',
        'Kegiatan',
      ],
      value: [
        {},
        { type: 'string', attribute: 'nisn' },
        { type: 'string', attribute: 'no_va' },
        { type: 'link', attribute: 'name' },
        // { type: 'string', attribute: 'pl' },
        { type: 'string', attribute: 'kegiatan' },
      ],
    };

    export const PEMBAGIAN_KELAS_EKSKUL_TABLE_FIELDS = {
      label: [
        'No',
        'NISN',
        'No VA',
        'Nama Siswa',
        // 'L/P',
        'Kelas',
        'Jenis Ekskul',
        'Opsi',
      ],
      value: [
        {},
        { type: 'string', attribute: 'nisn' },
        { type: 'string', attribute: 'no_va' },
        { type: 'string', attribute: 'name' },
        // { type: 'string', attribute: 'pl' },
        { type: 'string', attribute: 'classrooms_name' },
        { type: 'string', attribute: 'ekskul_name' },
      ],
    };

    export const PENGISIAN_NILAI_EKSKUL_TABLE_FIELDS = {
      label: [
        'No',
        'NISN',
        'No VA',
        'Nama Siswa',
        'Kelas',
        'Jenis Ekskul',
        'Nilai Ekskul',
        'Ekskul Sikap',
        'Keterangan',
        '',

      ],
      value: [
        {},
        { type: 'string', attribute: 'nisn' },
        { type: 'string', attribute: 'no_va' },
        { type: 'string', attribute: 'name' },
        { type: 'string', attribute: 'classrooms_name' },
        { type: 'string', attribute: 'ekskul_name' },
        { type: 'string', attribute: 'grades' },
        { type: 'string', attribute: 'attitude_ekskul' },
        { type: 'string', attribute: 'description' },
      ],
    };

    export const BUAT_KELAS_EKSKUL_TABLE_FIELDS = {
      label: [
        'No',
        'NISN',
        'No VA',
        'Nama',
        'Kelas',
        'Jenis Ekskul',
        // 'L/P',
        'Keterangan',
      ],
      value: [
        {},
        { type: 'string', attribute: 'nisn' },
        { type: 'string', attribute: 'no_va' },
        { type: 'string', attribute: 'student_name' },
        { type: 'string', attribute: 'classrooms_id' },
        { type: 'string', attribute: 'name' },
        // { type: 'string', attribute: 'pl' },
        { type: 'string', attribute: 'description' },
      ],
    };
    export const BUAT_NILAI_EKSKUL_TABLE_FIELDS = {
      label: [
        'No',
        'NISN',
        'No VA',
        'Nama Siswa',
        'Kelas',
        'Jenis Ekskul',
        'Nilai Ekskul',
        'Ekskul Sikap',
        'Keterangan',
      ],
      value: [
        {},
        { type: 'string', attribute: 'nisn' },
        { type: 'string', attribute: 'no_va' },
        { type: 'string', attribute: 'student_name' },
        { type: 'string', attribute: 'classrooms_name' },
        { type: 'string', attribute: 'name' },
        { type: 'number', attribute: 'grades' },
        { type: 'string', attribute: 'attitude_ekskul' },
        { type: 'string', attribute: 'description' },

      ],
    };

    export const TAMBAH_ABSEN_PERBIDANG_STUDI_TABLE_FIELDS = {
      label: [
        'No',
        'Tanggal',
        'Kelas',
        'Mata Pelajaran',
        'Opsi',
      ],
      value: [
        {},
        { type: 'string', attribute: 'date' },
        { type: 'string', attribute: 'classrooms_name' },
        { type: 'string', attribute: 'name' },

      ],
    };

    export const ABSENSI_PERBIDANG_STUDI_TABLE_FIELDS = {
      label: [
        'No',
        'NISN',
        'No VA',
        'Nama Siswa',
        'Hadir',
        'Izin',
        'Sakit',
        'Alpa',
        'Terlambat',
        'Keterangan',

      ],
      value: [
        {},
        { type: 'string', attribute: 'nisn' },
        { type: 'string', attribute: 'no_va' },
        { type: 'string', attribute: 'name' },
        { type: 'string', attribute: 'status', value: 'HADIR' },
        { type: 'string', attribute: 'status', value: 'IZIN' },
        { type: 'string', attribute: 'status', value: 'SAKIT' },
        { type: 'string', attribute: 'status', value: 'ALPA' },
        { type: 'string', attribute: 'status', value: 'TERLAMBAT' },
        { type: 'string', attribute: 'description' },
      ],
    };

    export const ABSENSI_EKSKUL_TABLE_FIELDS = {
      label: [
        'No',
        'NISN',
        'No VA',
        'Nama Siswa',
        'Kelas',
        'Jenis Ekskul',
        'Status',
        'Keterangan',
        'Opsi',
      ],
      value: [
        {},
        { type: 'string', attribute: 'nisn' },
        { type: 'string', attribute: 'no_va' },
        { type: 'string', attribute: 'student_name' },
        { type: 'string', attribute: 'classrooms_name' },
        { type: 'string', attribute: 'ekskul_name' },
        { type: 'string', attribute: 'status' },
        { type: 'string', attribute: 'description' },
      ],
    };

    export const ISI_ABSEN_EKSKUL_TABLE_FIELDS = {
      label: [
        'No',
        'NISN',
        'No VA',
        'Nama',
        'Kelas',
        'Jenis Ekskul',
        'Hadir',
        'Izin',
        'Sakit',
        'Alpa',
        'Keterangan',
      ],
      value: [
        {},
        { type: 'string', attribute: 'nisn' },
        { type: 'string', attribute: 'no_va' },
        { type: 'string', attribute: 'student_name' },
        { type: 'string', attribute: 'classrooms_name' },
        { type: 'string', attribute: 'ekskul_name' },
        { type: 'string', attribute: 'status', value: 'HADIR' },
        { type: 'string', attribute: 'status', value: 'IZIN' },
        { type: 'string', attribute: 'status', value: 'SAKIT' },
        { type: 'string', attribute: 'status', value: 'ALPA' },
        { type: 'string', attribute: 'description'},
      ],
    };

    export const CATAT_SISWA_KELUAR_LIST_TABLE_FIELDS = {
      label: [
        'No',
        'NISN',
        'No VA',
        'Nama Siswa',
        'Kelas',
        // 'Status Mutasi',
        // 'Status Absen',
        // 'Keterangan',
        'Opsi'
      ],
      value: [
        {},
        { type: 'string', attribute: 'nisn' },
        { type: 'string', attribute: 'no_va' },
        { type: 'string', attribute: 'student_name' },
        { type: 'string', attribute: 'classrooms_name' },
        // { type: 'string', attribute: 'transfer_to' },

      ],
    };

    export const BATAL_SISWA_KELUAR_LIST_TABLE_FIELDS = {
      label: [
        'No',
        'NISN',
        'No VA',
        'Nama Siswa',
        'Kelas',
        // 'Status Mutasi',
        // 'Status Absen',
        'Keterangan',
        'Opsi',
      ],
      value: [
        {},
        { type: 'string', attribute: 'nisn' },
        { type: 'string', attribute: 'no_va' },
        { type: 'string', attribute: 'student_name' },
        { type: 'string', attribute: 'classrooms_name' },
        // { type: 'string', attribute: 'student_mutations_id' },
        // { type: 'string', attribute: 'student_attendance_status_id' },
        { type: 'string', attribute: 'transfer_to' },        
      ],
    };

    export const LAPORAN_ABSEN_TABLE_FIELDS = {
      label: [
        'No',
        'NISN',
        'No VA',
        'Nama Siswa',
        // 'Matapelajaran-Materi',
        'Status',
        // 'Mengikuti',
        'Total',

      ],
      value: [
        {},
        { type: 'string', attribute: 'nisn' },
        { type: 'string', attribute: 'no_va' },
        { type: 'string', attribute: 'name' },
        // { type: 'string', attribute: 'subject_name' },
        { type: 'string', attribute: 'status', value: 'status' },
        // { type: 'string', attribute: 'presence_status',value: 'mengikuti'},
        { type: 'string', attribute: 'total'},
      ],
    };

    export const LAPORAN_8355_TABLE_UNIT_FIELDS = {
      label: [
        'No',
        'NISN',
        'No VA',
        'Nama Siswa',
        'TTL',
        'Agama',
        'Nama Orang Tua/Wali',
        'Pekerjaan',
        'Alamat',
        'Telp',
      ],
      value: [
        {},
        { type: 'string', attribute: 'nisn' },
        { type: 'string', attribute: 'no_va' },
        { type: 'string', attribute: 'students_name' },
        { type: 'string', attribute: 'dob' },
        { type: 'string', attribute: 'religions' },
        { type: 'string', attribute: 'father_name' },
        { type: 'string', attribute: 'father_occupations' },
        { type: 'string', attribute: 'address' },
        { type: 'string', attribute: 'father_phone' },
      ],
    };

    export const LAPORAN_KONSELING_LIST_TABLE_FIELDS = {
      label: [
        'No',
        'Tgl Konseling',
        'Catatan Khusus',
        'Inti Masalah',
        'Penyelesaian',
        'Keterangan',
        'Kesimpulan',
      ],
      value: [
        {},
        { type: 'string', attribute: 'date' },
        { type: 'string', attribute: 'specific_record' },
        { type: 'string', attribute: 'problems' },
        { type: 'string', attribute: 'problems_solving' },
        { type: 'string', attribute: 'descriptions' },
        { type: 'string', attribute: 'conclusion' },
      ],
    };

    export const JADWAL_MATPEL_LIST_TABLE_FIELDS = {
      label: [
        'No',
        'Hari',
        'Mata Pelajaran',
        'Pengajar',
        'Jam Mulai',
        'Jam Selesai',
        'Opsi',

      ],
      value: [
        {},
        { type: 'string', attribute: 'day' },
        { type: 'string', attribute: 'subject_name' },
        { type: 'string', attribute: 'teacher_profile_name' },
        { type: 'string', attribute: 'start_hour' },
        { type: 'string', attribute: 'end_hour' },

      ],
    };

    export const CETAK_JADWAL_MATPEL_LIST_TABLE_FIELDS = {
      label: [
        'No',
        'Hari',
        'Mata Pelajaran',
        'Pengajar',
        'Jam Mulai',
        'Jam Selesai',


      ],
      value: [
        {},
        { type: 'string', attribute: 'day' },
        { type: 'string', attribute: 'subject_name' },
        { type: 'string', attribute: 'teacher_profile_name' },
        { type: 'string', attribute: 'start_hour' },
        { type: 'string', attribute: 'end_hour' },

      ],
    };

    
    export const PENILAIAN_KOGNITIF_LIST_TABLE_FIELDS = {
      label: [
        'No',
        'Tanggal ulangan',
        'Jenis Ulangan',
        'Kelas',
        'Opsi',
        // 'Status',
      ],
      value: [
        {},
        { type: 'string', attribute: 'date_exam' },
        { type: 'string', attribute: 'type' },
        { type: 'string', attribute: 'classrooms_name' },
      ],
    };

    export const TAMBAH_PENGISIAN_PSIK_AFEK_LIST_TABLE_FIELDS = {
      label: [
        'No',
        'NISN',
        'No VA',
        'Nama Siswa',
        '(100) Mengeja',
        '(200) Membaca',
        'Nilai Akhir',
      ],
      value: [
        {},
        { type: 'string', attribute: 'nisn' },        
        { type: 'string', attribute: 'no_va' },        
        { type: 'string', attribute: 'name' },
        { type: 'string', attribute: 'mengeja' },
        { type: 'string', attribute: 'membaca' },
        { type: 'string', attribute: 'nilai_akhir' },



        

      ],
    };

    export const PENGISIAN_SIKAP_LIST_TABLE_FIELDS = {
      label: [
        'No',
        'Nama Indikator',
        'Bobot Nilai',
      ],
      value: [
        {},
        { type: 'string', attribute: 'nis' },        
        { type: 'string', attribute: 'name' },
        { type: 'string', attribute: 'score_students' },
      ],
    };

    export const TAMBAH_PENILAIAN_KOGNITIF_LIST_TABLE_FIELDS = {
      label: [
        'No',
        'NISN',
        'No VA',
        'Nama Siswa',
        'Skor',
        'Remedial',
      ],
      value: [
        {},
        { type: 'string', attribute: 'nisn' },        
        { type: 'string', attribute: 'no_va' },        
        { type: 'string', attribute: 'name' },
        { type: 'string', attribute: 'score_students' },
        { type: 'string', attribute: 'remedial', value: 'remedial'},
      ],
    };

    export const PENGISIAN_OBS_HARIAN_DAN_DAFTAR_NILAI_LIST_TABLE_FIELDS = {
      label: [
        'No',
        'NISN',
        'No VA',
        'Nama Siswa',
        'Kelas',
        'Opsi',
      ],
      value: [
        {},
        { type: 'string', attribute: 'nisn' },
        { type: 'string', attribute: 'no_va' },
        { type: 'string', attribute: 'name' },
        { type: 'string', attribute: 'classrooms_name' },
      ],
    };

    export const PENGISIAN_OBS_MINGGUAN_DAN_DAFTAR_NILAI_LIST_TABLE_FIELDS = {
      label: [
        'No',
        'NISN',
        'No VA',
        'Nama Siswa',
        'Kelas',
        'Opsi',
      ],
      value: [
        {},
        { type: 'string', attribute: 'nisn' },
        { type: 'string', attribute: 'no_va' },
        { type: 'string', attribute: 'name' },
        { type: 'string', attribute: 'classrooms_name' },

      ],
    };

    export const DAFTAR_NILAI_REPORT_LIST_TABLE_FIELDS = {
      label: [
        'No',
        'NISN',
        'No VA',
        'Nama Siswa',
        'Kelas',
        'Opsi',
        
      ],
      value: [
        {},
        { type: 'string', attribute: 'nisn' },
        { type: 'string', attribute: 'no_va' },
        { type: 'string', attribute: 'name' },
        { type: 'string', attribute: 'classrooms_name' },

      ],
    };

    export const PENGISIAN_PSIK__AFEK_LIST_TABLE_FIELDS = {
      label: [
        'No',
        'Tanggal Ulangan',
        'Jenis Ulangan',
        'Kelas',
        'Opsi',
        // 'Status',
      ],
      value: [
        {},
        { type: 'string', attribute: 'date_exam' },
        { type: 'string', attribute: 'type' },
        { type: 'string', attribute: 'classrooms_name' },
      ],
    };
    
    export const LIST_PENDAFTARAN_PPDB_LIST_TABLE_FIELDS = {
      label: [
        'No',
        'NISN',
        'No VA',
        'Nama Siswa',
        // 'Tahun Ajaran',
        // 'Keterangan',
        'Opsi',
      ],
      value: [
        {},
        { type: 'string', attribute: 'nisn' },
        { type: 'string', attribute: 'no_va' },
        { type: 'string', attribute: 'fullname' },
        // { type: 'string', attribute: 'entrance_year' },
        // { type: 'string', attribute: 'status' },
      ],
    };