import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import buat_invoice from '../../../assets/img/akademi/tagihan-siswa/buat_invoice.png';
import pembayaran from '../../../assets/img/akademi/tagihan-siswa/pembayaran.png';
import riwayat_pembayaran from '../../../assets/img/akademi/tagihan-siswa/riwayat_pembayaran.png';
import rincian_pembayaran from '../../../assets/img/akademi/tagihan-siswa/rincian_pembayaran.png';
import laporan_pembayaran from '../../../assets/img/akademi/tagihan-siswa/laporan_pembayaran.png';
import tunggakan from '../../../assets/img/akademi/tagihan-siswa/tunggakan.png';
import laporan_pembayaran_ppdb from '../../../assets/img/akademi/tagihan-siswa/laporan_pembayaran_ppdb.png';

export default class Spp extends PureComponent {
  constructor(props) {
    super(props);
    this.clickHandlers = {};
    this.onClick = this.onClick.bind(this);
    this.onClickTableControl = this.onClickTableControl.bind(this);
    this.onClickMasterData = this.onClickMasterData.bind(this);
    this.onClickCreateInvoice = this.onClickCreateInvoice.bind(this);
    this.onClickProsesPembayaran = this.onClickProsesPembayaran.bind(this);
    this.onClickLaporanSPP = this.onClickLaporanSPP.bind(this);
    this.onClickRincianPembayaran = this.onClickRincianPembayaran.bind(this);
    this.onClickRiwayat = this.onClickRiwayat.bind(this);
    this.onClickTunggakan = this.onClickTunggakan.bind(this);
    this.onClickLaporanPembayaranPPDB = this.onClickLaporanPembayaranPPDB.bind(this);
  }
  

  onClick(link) {
    const { history } = this.props;
    history.push(`/dashboard/keuangan/pembukuan/${link}`);
  }


  getClickHandler(link) {
    if (!Object.prototype.hasOwnProperty.call(this.clickHandlers, link)) {
      this.clickHandlers[link] = () => this.onClick(link);
    }
    return this.clickHandlers[link];
  }
  onClickTableControl() {
    const { history } = this.props;
    history.push(`/dashboard/tagihan-siswa/nama-pembayaran`);

  }
  onClickMasterData() {
    const { history } = this.props;
    history.push(`/dashboard/keuangan/spp/master-data-keuangan`);

  }

  onClickCreateInvoice() {
    const {history} = this.props;
    history.push(`/dashboard/tagihan-siswa/create-invoice`);
  }

  onClickProsesPembayaran() {
    const {history} = this.props;
    history.push(`/dashboard/tagihan-siswa/pembayaran`);
  }

  onClickRincianPembayaran() {
    const {history} = this.props;
    history.push(`/dashboard/tagihan-siswa/rincian-pembayaran`);
  }

  onClickRiwayat() {
    const {history} = this.props;
    history.push(`/dashboard/tagihan-siswa/riwayat`);
  }

  onClickLaporanSPP() {
    const {history} = this.props;
    history.push(`/dashboard/tagihan-siswa/laporan-pembayaran`);
  }

  onClickTunggakan() {
    const {history} = this.props;
    history.push(`/dashboard/tagihan-siswa/tunggakan`);
  }

  onClickLaporanPembayaranPPDB() {
    const {history} = this.props;
    history.push(`/dashboard/tagihan-siswa/laporan-pembayaran-ppdb`);
  }

  render() {
    const { user } = this.props;

    if(user.user_group.id === 1){
      user.user_group.permissions = ['view_student']
    }
    const { permissions } = user.user_group;
    
    return (
      <div className="akademi">
        {/* <div className="akademi__list">
          <button onClick={this.onClickTableControl}>
            <img src={bankJournal} alt="Table Kontrol Keuangan" />
            <h3>Nama Pembayaran</h3>
          </button>
        </div> */}
        
        {/* <div className="akademi__list">
          <button onClick={this.onClickCreateInvoice}>
            <img src={buat_invoice} alt="buat_invoice" />
            <h3>Buat Invoice</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickProsesPembayaran}>
            <img src={pembayaran} alt="pembayaran" />
            <h3>Pembayaran</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickRiwayat}>
            <img src={riwayat_pembayaran} alt="riwayat_pembayaran" />
            <h3>Riwayat Pembayaran</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickRincianPembayaran}>
            <img src={rincian_pembayaran} alt="rincian_pembayaran" />
            <h3>Rincian Pembayaran</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickLaporanSPP}>
            <img src={laporan_pembayaran} alt="laporan_pembayaran" />
            <h3>Laporan Pembayaran</h3>
          </button>
        </div>
        
        <div className="akademi__list">
          <button onClick={this.onClickTunggakan}>
            <img src={tunggakan} alt="tunggakan" />
            <h3>Tunggakan</h3>
          </button>
        </div>
        
        <div className="akademi__list">
          <button onClick={this.onClickLaporanPembayaranPPDB}>
            <img src={laporan_pembayaran_ppdb} alt="laporan_pembayaran_ppdb" />
            <h3>Laporan Pembayaran PPDB</h3>
          </button>
        </div> */}
        
      </div>
    );
  }
}
Spp.propTypes = {
  history: PropTypes.object.isRequired,
};
