import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, isEqual, map } from "lodash";
import { commaFormatted, normalizeAmount, dateFormat } from "../../../utils/transformer.util";
import { Button, Select, SearchSelect, Input, InputDate } from "../../../components/base/index";
import CetakSuratPindahForm from "../components/CetakSuratPindahForm.component";
// import AnekdotSubTemaFormView from "./components/AnekdotSubTemaFormView.component";
import ReactToPrint from "react-to-print";
import logo from '../../../assets/img/Spin.png';

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class CetakSuratPindah extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onClickNext = this._onClickNext.bind(this);
    this._onClickBack = this._onClickBack.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._setForm = this._setForm.bind(this);
    this._onClickAddSubTema = this._onClickAddSubTema.bind(this);
    this._onDeleteListSubTema = this._onDeleteListSubTema.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);

    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    this.state = {
      page: 1,
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {
          levels_id,
          organizations_id: 4,
          sub_tema: [
            {
              sub_tema_id:'',
              date:'',
              indikator: [
                {
                  kd:'',
                  name:'',
                  note:'',
                },
              ],
            }
          ],
        },
        error: {
          // indikator: "",
          // sub_tema: "",
        },
      },
      param: {},
    };
  }

  componentDidMount() {
    const { location, getLevels, getclassrooms, user } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data,  } = state;
    const { id, tema_id } = state.data || "";
    const { form } = this.state;
    const paramTypes = ["classrooms", "levels"];
    
    if (isEdit && id) {
      this.setState({id:id})
      this._setForm(data.id);
    }
    // else
    
  }

  _onClickAddSubTema(data) {
    const { form } = this.state;
    const { sub_tema } = form.value;

    sub_tema.push({
      sub_tema_id: "",
      date: "",
      indikator: [],
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          sub_tema,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }
  
  _onDeleteListSubTema(idx) {
    this.setState((prevState) => {
      const { sub_tema } = prevState.form.value;
      const list = prevState.form.value.sub_tema;
      list.splice(idx, 1);
      return {
        ...prevState,
        sub_tema: list,
      };
    });
  }

  _onClickAdd(data) {
    const { form } = this.state;
    const { sub_tema } = form.value;
    const [{ indikator }] = sub_tema;

    sub_tema[data].indikator.push({
      kd: "",
      name: "",
      note: "",
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          ...prevState.sub_tema,
          indikator,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }
  
  _onDeleteList(data) {
    this.setState((prevState) => {
      const { sub_tema } = prevState.form.value;
      const [{ indikator }] = sub_tema;
      const idxsub = data.idxsub;
      const idx = data.idx;

      const list = prevState.form.value.sub_tema[idxsub].indikator;
      list.splice(idx, 1);
      return {
        ...prevState,
        indikator: list,
      };
    });
  }

  async _getPrmTema(filters = {}) {
    const { getPrmTema, user } = this.props;
    const { organizations_id, school_unit } = user;
    const res = await getPrmTema(filters);
    filters.organizations_id = 3;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        prm_tema: res,
      },
    }));
  }

 

  async _setForm(id) {
    const { handleGetMoveRecord } = this.props;
    try {
      const payload = await handleGetMoveRecord({ id });
      this.setState({
        form: {
          value: {
            id: payload.id,
            classrooms_id: payload.classrooms_id,
            classrooms_name:payload.class_name,
            student_attendance_status_id: payload.student_attendance_status_id,
            student_mutations_id: payload.student_mutations_id,
            student_name: payload.student_name,
            students_id:  payload.students_id,
            date: payload.date,
            transfer_to: payload.transfer_to,
            transfer_letter_number: payload.transfer_letter_number,
            parents_name: payload.parents_name,
            genders_name: payload.genders_name,
            description: payload.description,
            address: payload.address,

          },
          error: {
            // details: [],
          },
        },
      });
    } catch (err) {
      // err action
    }
  }
  async onEdit() {
    const { history, handleEditLembarObsHarian } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    let canSaveB = false;
    const dataTabel = value.pelajaran;
    const dataForm = form.value;
    let a;
    await handleEditLembarObsHarian(value);
    history.push(
      "/dashboard/kurikulum/ulangan/pengisian-lembar-daftar-nilai/report"
    );
    // }
  }

  async onSubmit() {
    const { history, handleSaveCatatanAnekdot } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    const dataForm = form.value;
    await handleSaveCatatanAnekdot(value);
    history.push("/dashboard/kurikulum/ulangan/catatan-anekdot/pengisian-catatan-anekdot");
    // history.go(0);
  }

  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
      arraySub,
    } = dataset;

    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === "number") {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === "checkbox") {
          formattedValue = checked;
        }
        if(fieldName === "indikator"){
          newList = prevState.form.value.sub_tema[arraySub][fieldName];
          newListError = prevState.form.error[fieldName] || [];
          newList[arrayPosition][name] = formattedValue;
        } else{
          newList = prevState.form.value[fieldName] || [];
          newListError = prevState.form.error[fieldName] || [];
          newList[arrayPosition][name] = formattedValue;
        }
        if (name === "code_of_account") {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = '';
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
          },
        },
      };
    });
  }

  // _onClickNext() {
  //   const { history } = this.props;
  //   history.push("/dashboard/kesiswaan/laporan/daftar-siswa-kelas-unit");
  // }

  _onClickNext() {
    window.scrollTo(0, 0);
    this.setState((prevState) => ({
      page: prevState.page + 1,
    }));
  }

  _onClickBack() {
    window.scrollTo(0, 0);
    this.setState((prevState) => ({
      page: prevState.page - 1,
    }));
  }
  
  render() {
    const { location, getLevels, getclassrooms, user, list } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    const { form, param, page } = this.state;
    const { sub_tema } = form.value;
    const { school_unit, organization } = user;
    const org_name = organization.name.toUpperCase();
    const school_unit_name = school_unit.name.toUpperCase();
    // console.log(data)
    const componentRef = React.createRef();
    return (
      <div className="student-list">
        <div className="budget__title">
          {/* <h1>Pengisian Catatan Anekdot</h1> */}
          {/* <hr></hr> */}
        </div>
        <ReactToPrint
          trigger={() => (

            <div className="form-group-member">
              <Button title="Cetak" />
            </div>
          )}
          content={() => componentRef.current}
        />
        <form style={{ padding: '40px', marginLeft: '10rem' }} onSubmit={this._onSubmit} ref={componentRef}>
          <div id="divPrint" style={{width:810, border: '0px solid black'}}>
            <table className="table-report-cetak">
              <tr>
                <th rowSpan={5}>
                <img className="home__logo-surat" src={logo} />

                </th>
                <th className="big">
                  <h1 style={{fontFamily: "Times New Roman", fontSize: 17}}>{org_name}</h1>
                </th>
              </tr>
              <tr>
                <th className="big">
                  <h1 style={{fontFamily: "Times New Roman", fontSize: 19}}>{school_unit_name}</h1>
                </th>

              </tr>
              <tr>

              <th className="big">
                  <h1 style={{fontFamily: "Times New Roman", fontSize: 11}}>"TERAKREDITASI A"</h1>
                </th>

              </tr>
              <tr>
                <th className="big">
                  <h1 style={{fontFamily: "Times New Roman", fontSize: 11}}>Jl. Sugiyopranoto no. 1 Surakarta 57111 Telp/Fax. (0271) 645349</h1>
                </th>

              </tr>
              <tr>
               <th className="big">
                  <p style={{fontFamily: "Times New Roman", fontSize: 11}}>Web:www.sdpangudiluhurtimotius.sch.id  Email: info@sdpangudiluhurtimotius.sch.id</p>
                </th>

              </tr>
              <tr>
                <th  colSpan={2}>
                <hr className="bold"></hr>

                </th>

              </tr>
              <tr>
                <th colSpan={2}>
                <hr className="normal"></hr>
                </th>


              </tr>
            
            </table>
            <table className="table-middle-cetak">
              <tr>
                <th className="border">
                  NSS : 102036103074
                </th>
                <th style ={{width:'50%'}}>
                </th>   
                <th className="borderRight">
                NPSN : 20328168
                </th>    
              </tr>
              <tr>
                <th > </th>
                <th style ={{width:'50%'}}>
                <h1 style={{fontFamily: "Times New Roman", fontSize: 16, textDecorationLine: 'underline'}}>SURAT KETERANGAN PINDAH SEKOLAH</h1>
                </th>   
                <th></th>    
              </tr>
              <tr>
                <th></th>
                <th style ={{width:'50%'}}>
                  <h1 style={{fontFamily: "Times New Roman", fontSize: 12}}>Nomor:....................</h1>
                </th>   
                <th></th>    
              </tr>
             
            </table>
            <table className="table-middle2-cetak">
              <tr>
                <td colSpan={3}>
                <p style={{fontFamily: "Cambria", fontSize: 12}}>Yang bertanda tangan di bawah ini, Kepala Sekolah {school_unit.name}, Kecamatan Pasarkliwon, Kota Surakarta, menerangkan bahwa :</p>
                </td>
              </tr>
              <tr>
                <td style={{width:'15%'}}></td>
                <td style={{fontFamily: "Cambria", fontSize: 12, width:'12%'}}>Nama</td>
                <td style={{fontFamily: "Cambria", fontSize: 12}}>:{data.student_name}</td>
              </tr>
              <tr>
                <td style={{width:'15%'}}></td>
                <td style={{fontFamily: "Cambria", fontSize: 12, width:'12%'}}>NIS/NISN</td>
                <td style={{fontFamily: "Cambria", fontSize: 12}}>:{data.nis}</td>
              </tr>
              <tr>
                <td style={{width:'15%'}}></td>
                <td style={{fontFamily: "Cambria", fontSize: 12, width:'12%'}}>Jenis Kelamin</td>
                <td style={{fontFamily: "Cambria", fontSize: 12}}>:{form.value.genders_name}</td>
              </tr>
              <tr>
                <td style={{width:'15%'}}></td>
                <td style={{fontFamily: "Cambria", fontSize: 12, width:'12%'}}>Siswa Kelas</td>
                <td style={{fontFamily: "Cambria", fontSize: 12}}>:{form.value.classrooms_name}</td>
              </tr>
            </table>
            <table className="table-middle2-cetak">
              <tr>
                <td colSpan={3}>
                <p style={{fontFamily: "Cambria", fontSize: 12, fontStyle: 'italic', textAlign: 'center'}}>Sesuai surat permohonan pindah sekolah oleh orang tua siswa :</p>
                </td>
              </tr>
            </table>
            <table className="table-middle2-cetak">
              <tr>
                <td style={{width:'15%'}}></td>
                <td style={{fontFamily: "Cambria", fontSize: 12, width:'12%'}}>Nama</td>
                <td style={{fontFamily: "Cambria", fontSize: 12}}>:{form.value.parents_name}</td>
              </tr>
              <tr>
                <td style={{width:'15%'}}></td>
                <td style={{fontFamily: "Cambria", fontSize: 12, width:'12%'}}>Alamat</td>
                <td style={{fontFamily: "Cambria", fontSize: 12}}>:{form.value.address}</td>
              </tr>
            </table>
            <table className="table-middle2-cetak">
              <tr>
                <td colSpan={3}>
                <p style={{fontFamily: "Cambria", fontSize: 12}}>Telah mengajukan pindah ke {form.value.transfer_to}, dengan alasan {form.value.description}.</p>
                </td>
              </tr>
            </table>
            <table className="table-middle2-cetak">
              <tr>
                <td colSpan={3}>
                <p style={{fontFamily: "Cambria", fontSize: 12}}>Bersama ini kami sertakan Nilai Hasil Belajar dan surat permohonan pindah dari orang tua/wali  siswa.</p>
                </td>
              </tr>
            </table>
            <table className="table-middle2-cetak">
              <tr>
                <td style={{width:'20%'}}>
                </td>
                <td style={{width:'50%'}}>
                </td>
                <td>
                <p style={{fontFamily: "Cambria", fontSize: 12}}>Surakarta, ............</p>
                </td>
              </tr>
              <tr>
                <td style={{width:'20%'}}>
                </td>
                <td style={{width:'50%'}}>
                </td>
                <td>
                <p style={{fontFamily: "Cambria", fontSize: 12}}>Kepala Sekolah</p>
                </td>
              </tr>
            </table>
            <table className="table-foot-cetak">
              <tr>
                  <td style={{width:'20%'}}>
                  </td>
                  <td style={{width:'50%'}}>
                  </td>
                  <td>
                  <p style={{fontFamily: "Cambria", fontSize: 12}}>Br. Markus Sujarwo, FIC, S.Pd</p>
                  </td>
                </tr>
            </table>
            <table className="table-middle2-cetak">
              <tr>
                  <td colSpan={3} style={{border:'1px solid'}}>
                  <p style={{fontFamily: "Cambria", fontSize: 12, textAlign: 'center'}}>Setelah anak tersebut diterima di sekolah ini, 
                  isian di bawah ini harap diisi dan dikembalikan kepada kami</p>
                  </td>
                </tr>
            </table>
            <table className="table-middle2-cetak">
              <tr>
                <td style={{fontFamily: "Cambria", fontSize: 12, width:'20%'}}>Nama Anak</td>
                <td style={{fontFamily: "Cambria", fontSize: 12, width:'30%'}}>:_____________</td>
                <td style={{fontFamily: "Cambria", fontSize: 12, width:'20%'}}></td>
                <td style={{fontFamily: "Cambria", fontSize: 12, width:'30%'}}></td>
              </tr>
              <tr>
                <td style={{fontFamily: "Cambria", fontSize: 12, width:'20%'}}>Nama Sekolah</td>
                <td style={{fontFamily: "Cambria", fontSize: 12, width:'30%'}}>:_____________</td>
                <td style={{fontFamily: "Cambria", fontSize: 12, width:'20%'}}>Kabupaten/Kota</td>
                <td style={{fontFamily: "Cambria", fontSize: 12, width:'30%'}}>:_____________</td>
              </tr>
              <tr>
                <td style={{fontFamily: "Cambria", fontSize: 12, width:'20%'}}>Status Sekolah</td>
                <td style={{fontFamily: "Cambria", fontSize: 12, width:'30%'}}>:_____________</td>
                <td style={{fontFamily: "Cambria", fontSize: 12, width:'20%'}}>Propinsi</td>
                <td style={{fontFamily: "Cambria", fontSize: 12, width:'30%'}}>:_____________</td>
              </tr>
              <tr>
                <td style={{fontFamily: "Cambria", fontSize: 12, width:'20%'}}>Alamat Sekolah</td>
                <td style={{fontFamily: "Cambria", fontSize: 12, width:'30%'}}>:_____________</td>
                <td style={{fontFamily: "Cambria", fontSize: 12, width:'20%'}}>Diterima Tanggal</td>
                <td style={{fontFamily: "Cambria", fontSize: 12, width:'30%'}}>:_____________</td>
              </tr>
              <tr>
                <td style={{fontFamily: "Cambria", fontSize: 12, width:'20%'}}>Desa/Kelurahan</td>
                <td style={{fontFamily: "Cambria", fontSize: 12, width:'30%'}}>:_____________</td>
                <td style={{fontFamily: "Cambria", fontSize: 12, width:'20%'}}>Di kelas</td>
                <td style={{fontFamily: "Cambria", fontSize: 12, width:'30%'}}>:_____________</td>
              </tr>
              <tr>
                <td style={{fontFamily: "Cambria", fontSize: 12, width:'20%'}}>Kecamatan</td>
                <td style={{fontFamily: "Cambria", fontSize: 12, width:'30%'}}>:_____________</td>
                <td style={{fontFamily: "Cambria", fontSize: 12, width:'20%'}}></td>
                <td style={{fontFamily: "Cambria", fontSize: 12, width:'30%'}}></td>
              </tr>
            </table>
            <table className="table-middle2-cetak">
              <tr>
                <td style={{fontFamily: "Cambria", fontSize: 12, width:'20%'}}></td>
                <td style={{fontFamily: "Cambria", fontSize: 12, width:'30%'}}></td>
                <td style={{fontFamily: "Cambria", fontSize: 12, width:'20%'}}>Kepala Sekolah</td>
                <td style={{fontFamily: "Cambria", fontSize: 12, width:'30%'}}></td>
              </tr>
            </table>
            <table className="table-foot-cetak">
              <tr>
                  <td style={{width:'20%'}}>
                  </td>
                  <td style={{width:'30%'}}>
                  </td>
                  <td>
                  <p style={{fontFamily: "Cambria", fontSize: 12}}>....................................</p>
                  </td>
                  <td style={{width:'30%'}}></td>
                </tr>
                <tr>
                  <td style={{width:'20%'}}>
                  </td>
                  <td style={{width:'30%'}}>
                  </td>
                  <td>
                  <p style={{fontFamily: "Cambria", fontSize: 12}}>NIP:...........................</p>
                  </td>
                  <td style={{width:'30%'}}></td>
                </tr>
            </table>
          </div>
          {/* <div className="result__content">
            {page === 1 ? (
              <CetakSuratPindahForm
                form={form}
                param={param}
                data={data}
                onFormChange={this._onFormChange}
              />
            ) : (
              ""
            )}
          </div> */}
          <br/>
        </form>
      </div>
    );
  }
}
CetakSuratPindah.propTypes = {
  handleGetKlapper: PropTypes.func,
  handleDeleteKlapper: PropTypes.func,
};
CetakSuratPindah.defaultProps = {
  handleGetKlapper: noop,
  handleDeleteKlapper: noop,
};