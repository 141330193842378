import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import daftar_pindah_unit from '../../../assets/img/akademi/administrasi/DataKelas/daftar-pindah-unit.png';
import jurusan_kelas from '../../../assets/img/akademi/administrasi/DataKelas/jurusan-kelas.png';
import kenaikan_kelas from '../../../assets/img/akademi/administrasi/DataKelas/kenaikan-kelas.png';
import pembagian_kelas from '../../../assets/img/akademi/administrasi/DataKelas/pembagian-kelas.png';
import permohonan_pindah_unit from '../../../assets/img/akademi/administrasi/DataKelas/permohonan-pindah-unit.png';
import pindah_unit from '../../../assets/img/akademi/administrasi/DataKelas/pindah-unit.png';
import siswa_pindah_kelas from '../../../assets/img/akademi/administrasi/DataKelas/siswa-pindah-kelas.png';

export default class Finance extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickLaporanSekolah = this.onClickLaporanSekolah.bind(this);
    this.onClickJurusan = this.onClickJurusan.bind(this);
    this.onClickKenaikanKelas = this.onClickKenaikanKelas.bind(this);
    this.onClickKelasKhusus = this.onClickKelasKhusus.bind(this);
    this.onClickPembagianKelas = this.onClickPembagianKelas.bind(this);
    this.onClickPembagianKlsTahunAjaran = this.onClickPembagianKlsTahunAjaran.bind(this);
    this.onClickPengaturanKlsTahunAjaran = this.onClickPengaturanKlsTahunAjaran.bind(this);
    this.onClickPembagianKelasKhusus = this.onClickPembagianKelasKhusus.bind(this);
    this.onClickSiswaPindahKelas = this.onClickSiswaPindahKelas.bind(this);
    this.onClickPindahUnit = this.onClickPindahUnit.bind(this);
    this.onClickDaftarPindahUnit = this.onClickDaftarPindahUnit.bind(this);
    this.onClickPermohonanPindahUnit = this.onClickPermohonanPindahUnit.bind(this);
  }

  onClickLaporanSekolah() {
    const { history } = this.props;
    history.push('/dashboard/pengaturan/laporan-sekolah');
  }

  onClickJurusan() {
    const { history } = this.props;
    history.push('/dashboard/tata-usaha/manajemen-kelas/jurusan-kelas');
  }

  onClickKenaikanKelas() {
    const { history } = this.props;
    history.push('/dashboard/tata-usaha/manajemen-kelas/kenaikan-kelas-siswa');
  }

  onClickKelasKhusus(){
    const { history } = this.props;
    history.push('/dashboard/tata-usaha/manajemen-kelas/kelas-khusus');
  }
  onClickPembagianKelas(){
    const { history } = this.props;
    history.push('/dashboard/tata-usaha/manajemen-kelas/pembagian-kelas');
  }
  onClickPembagianKlsTahunAjaran(){
    const { history } = this.props;
    history.push('/dashboard/tata-usaha/manajemen-kelas/pembagian-kelas-ppdb');
  }
  onClickPengaturanKlsTahunAjaran(){
    const { history } = this.props;
    history.push('/dashboard/tata-usaha/manajemen-kelas/pengaturan-kelas-ajaran-baru');
  }
  onClickPembagianKelasKhusus(){
    const { history } = this.props;
    history.push('/dashboard/tata-usaha/manajemen-kelas/pembagian-kelas-khusus');
  }
  onClickSiswaPindahKelas(){
    const { history } = this.props;
    history.push('/dashboard/tata-usaha/manajemen-kelas/siswa-pindah-kelas');
  }
  onClickPindahUnit(){
    const { history } = this.props;
    history.push('/dashboard/tata-usaha/manajemen-kelas/pindah-unit');
  }
  onClickDaftarPindahUnit(){
    const { history } = this.props;
    history.push('/dashboard/tata-usaha/manajemen-kelas/daftar-pindah-unit');
  }
  onClickPermohonanPindahUnit(){
    const { history } = this.props;
    history.push('/dashboard/tata-usaha/manajemen-kelas/permohonan-pindah-unit');
  }

  render() {
    return (
      <div className="akademi">
        {/* <div className="akademi__list">
          <button onClick={this.onClickLaporanSekolah}>
            <img src={identitas_unit_sekolah} alt="laporansekolah" />
            <h3>Laporan Sekolah</h3>
          </button>
        </div> */}
        {/* <div className="akademi__list">
          <button onClick={this.onClickJurusan}>
            <img src={jurusan_kelas} alt="jurusan" />
            <h3>Jurusan Kelas</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickPembagianKelas}>
            <img src={pembagian_kelas} alt="pembagiankelas" />
            <h3>Pembagian Kelas</h3>
          </button>
        </div> */}
        {/* <div className="akademi__list">
          <button onClick={this.onClickKenaikanKelas}>
            <img src={kenaikan_kelas} alt="kenaikankelas" />
            <h3>Kenaikan Kelas Siswa</h3>
          </button>
        </div> */}
        {/* <div className="akademi__list">
          <button onClick={this.onClickPembagianKlsTahunAjaran}>
            <img src={pembagian_kelas} alt="pembagiankelasTahunAjaran" />
            <h3>Pembagian Kelas PPDB</h3>
          </button>
        </div> */}
        {/* <div className="akademi__list">
          <button onClick={this.onClickPengaturanKlsTahunAjaran}>
            <img src={pembagian_kelas} alt="pengaturankelasTahunAjaran" />
            <h3>Laporan Kelas Tahun Ajaran Baru</h3>
          </button>
        </div> */}
        {/* <div className="akademi__list">
          <button onClick={this.onClickKelasKhusus}>
            <img src={budgeting} alt="kelaskhusus" />
            <h3>Kelas Khusus</h3>
          </button>
        </div> */}
        {/* <div className="akademi__list">
          <button onClick={this.onClickPembagianKelas}>
            <img src={budgeting} alt="pembagiankelaskhusus" />
            <h3>Pembagian Kelas Khusus</h3>
          </button>
        </div> */}
        {/* <div className="akademi__list">
          <button onClick={this.onClickSiswaPindahKelas}>
            <img src={siswa_pindah_kelas} alt="siswapindahkls" />
            <h3>Siswa Pindah Kelas</h3>
          </button>
        </div> */}
        {/* <div className="akademi__list">
          <button onClick={this.onClickPindahUnit}>
            <img src={pindah_unit} alt="pindahunit" />
            <h3>Pindah Unit</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickDaftarPindahUnit}>
            <img src={daftar_pindah_unit} alt="daftarpindahunit" />
            <h3>Daftar Pindah Unit</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickPermohonanPindahUnit}>
            <img src={permohonan_pindah_unit} alt="permohonanpindahunit" />
            <h3>Permohonan Pindah Unit</h3>
          </button>
        </div> */}
      </div>
    );
  }
}
Finance.propTypes = {
  history: PropTypes.object.isRequired,
};
