import { connect } from 'react-redux';
import LaporanPembayaranPPDB from '../../../../views/Finance/Spp/LaporanPembayaranPPDB/LaporanPembayaranPPDB.view';
import { reportPPDBSpp, reportPPDBUpp, reportPPDBOfflineSpp, reportPPDBOfflineUpp } from '../../../../states/thunks/academics.thunk';
import { getYearsOfEducationsOptions } from '../../../../states/thunks/options.thunk';

function mapStateToProps(state) {
  return {
    classes: state.options.classes,
    levels: state.options.levels,
    user: state.user || {},
  }
}
function mapDispatchToProps(dispatch) {
  return {
    handleReportPPDBSpp: (payload, goback) => dispatch(reportPPDBSpp(payload, goback)),
    handleReportPPDBUpp: (payload, goback) => dispatch(reportPPDBUpp(payload, goback)),
    handleReportPPDBOfflineSpp: (payload, goback) => dispatch(reportPPDBOfflineSpp(payload, goback)),
    handleReportPPDBOfflineUpp: (payload, goback) => dispatch(reportPPDBOfflineUpp(payload, goback)),
    getYearsOfEducationsOptions: (payload) => dispatch(getYearsOfEducationsOptions(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LaporanPembayaranPPDB);
