import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map, isEqual, capitalize } from "lodash";
import StudentReportFilter from "../../../components/Filter/StudentReport.filter.component";
import ListForm from "../../../components/ListForm/ListForm.component";
import ContentTable from "../../../components/ContentTable/ContentTable.component";
import { validateRequiredFields } from "../../../utils/validation.util";
import {
  commaFormatted,
  normalizeAmount,
  formatData,
} from "../../../utils/transformer.util";
import {
  Button,
  Pagination,
  Input,
  Select,
  InputDate
} from "../../../components/base/index";
import { TAMBAH_PENGISIAN_PSIK_AFEK_LIST_TABLE_FIELDS } from "../../../constants/Student/student.constant";
import language from "../../../languages";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class TambahPengisianPsikAfek extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._getParamOptions = this._getParamOptions.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._setForm = this._setForm.bind(this);
    this.onEdit = this.onEdit.bind(this);
    // this._getSubjectOptions= this._getSubjectOptions.bind(this);
    this._getSubjectPsikAfekOptions =
    this._getSubjectPsikAfekOptions.bind(this);
    this._getTeacherSubjectsOptions =
    this._getTeacherSubjectsOptions.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._getTypeExam = this._getTypeExam.bind(this);
    this.onClickAddUpload = this.onClickAddUpload.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    this._getClassesOptions = this._getClassesOptions.bind(this);
    // this._getPrmLevels = this._getPrmLevels.bind(this);


    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    this.state = {
      page: 1,
      list: [],
      indicator: {},
      selected: {},
      content: initialContent,
      filters: {},
      form: {
        value: {
          levels_id,
          organizations_id: 3,
          score_max: "100",
          score: "100",
          details: [],
        },
        error: {
          classrooms: "",
          details: "",
        },
      },

      param: {},
    };
  }

  componentDidMount() {
    const { location, getLevels, getClasses, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id, units_id } = school_unit;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    const { content } = this.state;
    const paramTypes = ["classes", "classrooms", "levels"];
    paramTypes.forEach((type) => {
      // this._getParamOptions(type);
      this._getParamOptionsAcademics(type);
    });
    if (isEdit && id) {
      this.setState({ id: id });
      this._setForm(data.id, { page: 1 });
    }
    // this.isMount = true;
    else if (isEmpty(content.list)) {
      // this._onSearchContent({ page: 1 });
    }
    // this._getSubjectOptions();
    this._getSubjectPsikAfekOptions({ filters: { units_id: [units_id] } });
    this._getTypeExam();
    this._getTeacherSubjectsOptions();
    // this.handleGetData();
    this._getPeriodsOptions();
    this._getClassesOptions({ filters: { levels_id: [levels_id], units_id: [school_unit.id] } });
    // this._getPrmLevels({ filters: {units_id: [school_unit.id], organizations_id: 3 } });
    
  }

  async _getClassesOptions(filters = {}) {
    const { getClassesOptions, user } = this.props;
    const { school_unit } = user;
    const { levels_id } = school_unit;
    filters.levels_id = levels_id;
    filters.units_id = school_unit.id;
    const res = await getClassesOptions(filters);
    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        classes: res,
      },
    }));
  }

  async _getPrmLevels(filters = {}) {
    const { getPrmLevels, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getPrmLevels(filters);
    filters.organizations_id = 3;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        levels: res,
      },
    }));
  }

  async _getSubjectPsikAfekOptions(filters = {}) {
    const { getSubjectPsikAfekOptions, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id, units_id } = school_unit;
    const { form } = this.state;
    const { subject_id } = form.value;
    const res = await getSubjectPsikAfekOptions(filters);
    const selectedSubject = res.find((item) => item.value == subject_id);
    filters.units_id = school_unit.id;

    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          kkm: selectedSubject ? selectedSubject.attributes.kkm : "",
        },
      },
      param: {
        ...prevState.param,
        subject: res,
      },
    }));
  }

  async _getTypeExam() {
    const { getTypeExam } = this.props;
    const res = await getTypeExam();
    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        type_exam: res,
      },
    }));
  }

  async _getTeacherSubjectsOptions(filters = {}) {
    const { getTeacherSubjectsOptions } = this.props;
    const res = await getTeacherSubjectsOptions(filters);
    // console.log(res)
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        teacher_subjects: res,
      },
    }));
  }

  async _getPeriodsOptions() {
    const { getPeriodsOptions } = this.props;
    const res = await getPeriodsOptions();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  onClickAddUpload() {
    const { history } = this.props;
    history.push(
      "/dashboard/kurikulum/ulangan/tambah-pengisian-psik-afek/upload-pengisian-psik-afek",
      { isEdit: true }
    );
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this.handleGetData({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  async _onSearchContent(params = {}) {
    const { filters, form } = this.state;
    const { value } = form;
    const { user } = this.props;
    const { organizations_id } = user;

    try {
      const { handleListStudentPsikAfek, handleListIndicatorPsikAfek, user } =
        this.props;
      const { school_unit } = user;
      const { units_id } = school_unit;
      const result = await handleListStudentPsikAfek({
        ...params,
        organizations_id: 3,
        // units_id,
        filters: {
          classrooms_id: value.classrooms_id,
        },
      });
      const res = await handleListIndicatorPsikAfek({
        ...params,
        organizations_id: 3,
        //  units_id,
        filters: {
          subject_id: value.subject_id,
          classrooms_id: value.classrooms_id,

        },
      });

      result.map((item) => {
        res.map((indicator) => {
          if (!item.indikator) {
            item.indikator = [];
          }
          item.indikator.push({
            attitude_indicator_details_id: indicator.id,
            value: null,
          });
        });
      });
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            details: result,
          },
        },
        indicator: res,
      }));
    } catch (err) {
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            details: [],
          },
        },
        indicator: [],
      }));
    }
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    if (type === "classes" || type === "classrooms") {
      filters.organizations_id = 3;
      // filters.levels_id = levels_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getParamOptions(type, filters = {}) {
    const { getParamOptions, user } = this.props;
    const { organizations_id } = user;
    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = 3;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptions(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _setForm(id, params = {}) {
    const { filters, form } = this.state;
    const { value } = form;
    const { handleGetPsikAfek, handleListIndicatorPsikAfek, user } = this.props;
    const { organizations_id } = user;
    try {
      const payload = await handleGetPsikAfek({ id });
      const {classes_id, levels_id} = payload;
      this._getClassesOptions({ filters: { levels_id: [levels_id] }} );
      // this._getSubjectOptions({ filters: { classes_id: classes_id }} );
      this._getSubjectPsikAfekOptions({ filters: { classes_id: [classes_id] } });

      const data = await handleListIndicatorPsikAfek({
        ...params,
        organizations_id: 3,
        filters: {
          subject_id: payload.subject_id,
        },
      });

      const details = map(payload.details, (detail) => ({
        ...detail,
      }));

      details.forEach((o) => {
        if (o.indikator.length === 0) {
          o.indikator = map(data, (detail_indikator) => ({
            attitude_indicator_details_id: detail_indikator.id,
            value: null,
          }));
        }
      });

      this.setState({
        // filters: {
        //   subject_id: payload.subject_id
        // },
        form: {
          value: {
            id: payload.id,
            period: payload.period,
            levels_id: payload.levels_id,
            semester: payload.semester,
            type_of_exam_id: payload.type_of_exam_id,
            model: payload.model,
            subject_id: payload.subject_id,
            teacher_id: payload.teacher_id,
            materi_indikator: payload.materi_indikator,
            date_exam: payload.date_exam,
            organizations_id: payload.organizations_id,
            kkm: payload.kkm,
            score_max: payload.score_max,
            score: payload.score,
            classrooms_id: payload.classrooms_id,
            classes_id: payload.classes_id,
            bobot: payload.bobot,
            details,
          },
          error: {
            // details: [],
          },
        },
        indicator: data,
      });
    } catch (err) {
      // err action
    }
  }

  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
      indicatorIndex,
    } = dataset;
    const { user } = this.props;
    const { organizations_id } = user;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked ? value : "";
          }
          newList = prevState.form.value[fieldName] || [];
          newListError = prevState.form.error[fieldName] || [];
          if (name === "indikator" && indicatorIndex) {
            let subList = newList[arrayPosition][name] || [];
            const indicator = subList.find(
              (o) => o.attitude_indicator_details_id == indicatorIndex
            ) || {
              attitude_indicator_details_id: indicatorIndex,
              value: null,
            };
            indicator.value = formattedValue;
            newList[arrayPosition][name] = subList;
          } else {
            if (!newList[arrayPosition]) {
              newList[arrayPosition] = {
                [name]: "",
              };
            }
            newList[arrayPosition][name] = formattedValue;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        // if (name === "levels_id") {
        //   this._getParamOptionsAcademics("classrooms", {
        //     levels_id: value,
        //     organizations_id: 3,
        //   });
        // }
        if (name === "levels_id") {
          this._getClassesOptions({ filters: { levels_id: [value] } });
        }
        if (name === "subject_id") {
          this._getTeacherSubjectsOptions({ filters: { subject_id: value } });
          const param = this.state.param.subject;
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                kkm: param.find((i) => i.value == value).attributes.kkm,
              },
            },
          }));
        }
        if (name === "classrooms_id" || name === "subject_id") {
          this._onSearchContent();
        }
        if (name === "classes_id") {
          this._getParamOptionsAcademics("classrooms", {
            classes_id: value,
            organizations_id,
          });
          this._getSubjectPsikAfekOptions({ filters: { classes_id: value } });
        }
      }
    );
  }

  async onSubmit() {
    const { history, handleSavePsikAfek } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    const dataForm = form.value;
    await handleSavePsikAfek(value);
    history.push("/dashboard/kurikulum/ulangan/pengisian-nilai-kognitif");
    history.go(0);
  }
  async onEdit() {
    const { history, handleEditPsikAfek } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    let canSaveB = false;
    const dataTabel = value.pelajaran;
    const dataForm = form.value;
    let a;
    await handleEditPsikAfek(value);
    history.push("/dashboard/kurikulum/ulangan/pengisian-nilai-kognitif");
    // }
  }

  _renderButtons(data) {
    const { user } = this.props;
    const button = {};

    return (
      <td>
        <div className="table__actions">
          {/* <Button
                  onClick={() => this.onClickEdit(data)}
                  title='Edit'
                /> */}
        </div>
      </td>
    );
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        this._onSearchContent();
      }
    );
  }

  render() {
    const { form, page, list, content, param, filters, indicator } = this.state;
    const { plafon } = form.value;
    const { details } = form.value;
    const { classrooms_id = "", subject_id = "" } = filters;
    const academic_year = [
      { label: "2019", value: "2019" },
      { label: "2020", value: "2020" },
      { label: "2021", value: "2021" },
    ];
    const model = [
      { label: "Praktek/Keterampilan/KI-3", value: "praktek" },
      { label: "Sikap/Afektif", value: "sikap" },
      { label: "Siswa Profil/Learning Record", value: "siswa profile" },
    ];
    const semester = [
      { label: "Ganjil", value: "ganjil" },
      { label: "Genap", value: "genap" },
    ];
    // console.log(indicator)
    return (
      <div className="absensi-rekap__custom-form">
        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            <Select
              label="Tahun Ajaran"
              name="period"
              onChange={this._onFormChange}
              placeholder="Pilihan"
              data={param.periods}
              value={form.value.period}
              // error={form.error.period || ''}
            />
          </div>
          <div className="absensi-rekap__custom-form-column__field">
            <Select
              type="unit"
              name="levels_id"
              label="Unit"
              disabled
              placeholder="Pilihan"
              data={param.levels}
              onChange={this._onFormChange}
              value={form.value.levels_id}
              error={form.error.levels_id || ""}
            />
          </div>
        </div>

        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            <Select
              name="semester"
              label="Semester"
              placeholder={"Pilihan"}
              onChange={this._onFormChange}
              data={semester}
              value={form.value.semester}
              error={form.error.semester || ""}
            />
          </div>

          <div className="data-pelanggaran__custom-form-row__field">
            <Select
              name="classes_id"
              label="Tingkat Kelas"
              onChange={this._onFormChange}
              data={param.classes}
              placeholder={"Pilihan"}
              value={form.value.classes_id}
              error={form.error.classes_id || ""}
            />
            <div className="data-pelanggaran__custom-form-row__field"></div>
            <Select
              name="classrooms_id"
              label="Kelas"
              onChange={this._onFormChange}
              placeholder="Pilih Kelas"
              data={param.classrooms}
              value={form.value.classrooms_id}
              error={form.error.classrooms_id || ""}
            />
          </div>
        </div>
        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            <Select
              type="text"
              name="type_of_exam_id"
              label="Jenis Ulangan"
              placeholder={"Pilihan"}
              data={param.type_exam}
              onChange={this._onFormChange}
              value={form.value.type_of_exam_id}
              error={form.error.type_of_exam_id || ""}
            />
          </div>

          <div className="absensi-rekap__custom-form-column__field">
            <Select
              name="model"
              label="Model"
              onChange={this._onFormChange}
              data={model}
              placeholder="Pilihan"
              value={form.value.model}
              error={form.error.model || ""}
            />
          </div>
        </div>

        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            <Select
              name="subject_id"
              label="Mata Pelajaran"
              onChange={this._onFormChange}
              data={param.subject}
              placeholder="Pilih Matapelajaran"
              value={form.value.subject_id}
              error={form.error.subject_id || ""}
            />
          </div>
          <div className="absensi-rekap__custom-form-column__field">
            <Input
              type="text"
              name="materi_indikator"
              label="Materi Indikator"
              placeholder="Pilihan"
              onChange={this._onFormChange}
              value={form.value.materi_indikator}
              error={form.error.materi_indikator || ""}
            />
          </div>
        </div>
        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            <Select
              name="teacher_id"
              label="Nama Guru"
              placeholder={"Pilihan"}
              data={param.teacher_subjects}
              onChange={this._onFormChange}
              value={form.value.teacher_id}
              error={form.error.teacher_id || ""}
            />
          </div>
          <div className="absensi-rekap__custom-form-column__field">
            <InputDate
              type="date"
              name="date_exam"
              label="Tanggal Ulangan"
              onChange={this._onFormChange}
              value={form.value.date_exam}
              error={form.error.date_exam || ""}
            />
          </div>
        </div>
        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            <Input
              type="number"
              name="score_max"
              label="Skor Maks"
              onChange={this._onFormChange}
              value={form.value.score_max}
              style={{ backgroundColor: "grey" }}
            />
          </div>
          <div className="absensi-rekap__custom-form-column__field">
            <Input
              type="text"
              name="score"
              label="Nilai"
              onChange={this._onFormChange}
              value={form.value.score}
              error={form.error.score || ""}
              style={{ backgroundColor: "grey" }}
            />
          </div>
        </div>
        <div className="absensi-rekap__custom-form-row">
          <div className="absensi-rekap__custom-form-row__field">
            <Input
              type="number"
              name="bobot"
              label="Bobot"
              onChange={this._onFormChange}
              value={form.value.bobot}
              style={{ backgroundColor: "grey" }}
            />
          </div>
          <div className="absensi-rekap__custom-form-column__field">
            <Input
              type="text"
              name="kkm"
              label="KKM"
              placeholder="Pilihan"
              style={{ backgroundColor: "grey" }}
              onChange={this._onFormChange}
              value={form.value.kkm}
            />
          </div>
        </div>

        <div className="fund-request__content">
          <table className="table">
            <thead>
              <tr>
                {/* {
          map(TAMBAH_PENGISIAN_PSIK_AFEK_LIST_TABLE_FIELDS.label, (field, idx) => (
            <th key={`table_th_${idx}`}>{field}</th>
        ))
        } */}
                <th>No</th>
                <th>NISN</th>
                <th>No VA</th>
                <th>Nama Siswa</th>
                {map(indicator, (name) => (
                  <th className="angka">
                    {name.name}
                    <br></br>
                    {name.bobot}
                  </th>
                ))}
                <th>Nilai Akhir</th>
              </tr>
              <tr></tr>
            </thead>
            <tbody>
              {map(details, (list, idx) => (
                <tr key={`budget_row_${idx}`}>
                  <td style={{textAlign:"center"}}>{idx + 1}</td>
                  <td style={{textAlign:"center"}}>{list.nisn}</td>
                  <td style={{textAlign:"center"}}>{list.no_va}</td>
                  <td style={{textTransform:"capitalize"}}>{capitalize(list.name)}</td>
                  {map(indicator, (item) => (
                    <td className="angka">
                      <Input
                        type="number"
                        value={
                          list.indikator.find(
                            (o) => o.attitude_indicator_details_id == item.id
                          ).value
                        }
                        data-input-array
                        arrayPosition={idx}
                        data-indicator-index={item.id}
                        data-array-position={idx}
                        data-field-name="details"
                        name="indikator"
                        error={list.indikator.error}
                        onChange={this._onFormChange}
                      />
                    </td>
                  ))}
                  <td>
                    <Input
                      type="number"
                      value={list.score_students}
                      data-input-array
                      arrayPosition={idx}
                      data-array-position={idx}
                      data-field-name="details"
                      name="score_students"
                      onChange={this._onFormChange}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="student-list__header">
          {/* <div className="student-list__button">
          <Button
          title="Upload"
          onClick={this.onClickAddUpload}
        />
          </div> */}
          <div className="student-list__button">
            {this.state.id ? (
              <Button type="button" title="Update" onClick={this.onEdit} />
            ) : (
              <Button type="button" title="Simpan" onClick={this.onSubmit} />
            )}
          </div>
        </div>
      </div>
    );
  }
}
TambahPengisianPsikAfek.propTypes = {
  handleGetStudent: PropTypes.func,
  handleGetPsikAfek: PropTypes.func,
  handleGetclassrooms: PropTypes.func,
  history: PropTypes.object.isRequired,
};
TambahPengisianPsikAfek.defaultProps = {
  handleGetStudent: noop,
  HandleGetclassrooms: noop,
  handleGetPsikAfek: noop,
};
