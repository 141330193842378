import { connect } from "react-redux";
import ManageRegistrationPPDB from "../../views/Student/ManageRegistrationPPDB.student.view";
import {
  savePPDBOffline,
  EditPPDBOffline,
  uploadImageFileStudents,
} from "../../states/thunks/student.thunk";
import {
  getParamOptionsAcademics,
  getClassesOptions,
  getCitizenships,
  getReligions,
  getEducations,
  getOccupations,
  getClasses,
  getGenders,
  getBloodTypes,
  getLevels,
  getPeriodsOptions,
  getYearsOfEducationsOptions,
  getPrmLevels,
} from "../../states/thunks/options.thunk";

function mapStateToProps(state) {
  return {
    user: state.user,
    citizenships: state.options.citizenships,
    religions: state.options.religions,
    educations: state.options.educations,
    occupations: state.options.occupations,
    classes: state.options.classes,
    genders: state.options.genders,
    blood_types: state.options.blood_types,
    levels: state.options.levels,
    guardians: state.options.guardians,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    handleSavePPDBOffline: (payload, push) => dispatch(savePPDBOffline(payload, push)),
    handleEditPPDBOffline: (payload, goback) => dispatch(EditPPDBOffline(payload, goback)),
    handleUploadImageFileStudents: (payload, goback) => dispatch(uploadImageFileStudents(payload, goback)),
    getClassesOptions: (payload) => dispatch(getClassesOptions(payload)),
    getPeriodsOptions: (payload) => dispatch(getPeriodsOptions(payload)),
    getYearsOfEducationOptions: (payload) => dispatch(getYearsOfEducationsOptions(payload)),
    getPrmLevels: (payload) => dispatch(getPrmLevels(payload)),
    getParamOptionsAcademics: (payload, type) => dispatch(getParamOptionsAcademics(payload, type)),
    handleGetCitizenships: (payload) => dispatch(getCitizenships(payload)),
    handleGetReligions: (payload) => dispatch(getReligions(payload)),
    handleGetEducations: (payload) => dispatch(getEducations(payload)),
    handleGetOccupations: (payload) => dispatch(getOccupations(payload)),
    handleGetClasses: (payload) => dispatch(getClasses(payload)),
    handleGetGenders: (payload) => dispatch(getGenders(payload)),
    handleGetBloodTypes: (payload) => dispatch(getBloodTypes(payload)),
    handleGetLevels: (payload) => dispatch(getLevels(payload)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageRegistrationPPDB);
