import axios from 'axios';
import { map } from 'lodash';
import * as api from '../../utils/api.util';
import * as commonActions from '../actions/common.action';

const { CancelToken } = axios;
let source;

export function getBudgetOptions(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (unit_id === null && workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      dispatch(commonActions.getOptionsRequested());
      const res = await api.OPTIONS({
        ...payload,
        unit_id,
      }, '/budget');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'budget',
        data: res.data,
      }));
      return res.data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getPeriodeOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.OPTIONS(payload, '/periode');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'periode',
        data: res.data,
      }));
      return res.data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}


export function getExistingPeriodeOptions() {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (unit_id === null && workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      dispatch(commonActions.getOptionsRequested());
      const res = await api.OPTIONS({ unit_id }, '/existing-periode');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'periode',
        data: res.data,
      }));

      return res.data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}


export function getCoaOptions(payload = {}) {
  return (dispatch) => {
    if (source) {
      source();
    }
    dispatch(commonActions.getOptionsRequested());
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };
    api.OPTIONS(payload, '/code-of-account', options)
      .then((res) => {
        if (res) {
          dispatch(commonActions.getOptionsSucceeded({
            entity: 'coa',
            data: res.data,
          }));
          return res.data;
        }
      })
      .catch(() => {
        dispatch(commonActions.getOptionsFailed());
      });
  };
}

export function getAdjustmentCoaOptions(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    dispatch(commonActions.getOptionsRequested());
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };
    const data = await api.OPTIONS(payload, '/adjustment-coa', options)
      .then((res) => {
        if (res) {
          dispatch(commonActions.getOptionsSucceeded({
            entity: 'coa',
            data: res.data,
          }));
          return res.data;
        }
        return false;
      })
      .catch(() => {
        dispatch(commonActions.getOptionsFailed());
      });
    return { list: data };
  };
}

export function getRapbuCoa(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.OPTIONS(payload, '/rapbu-coa');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'coa',
        data: res.data,
      }));
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getCodeGroupOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.OPTIONS(payload, '/code-group');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'code_group',
        data: res.data,
      }));
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getBankAccountOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.OPTIONS(payload, '/bank-account');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'bank_account',
        data: res.data,
      }));
      return res.data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getUnitOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.OPTIONS(payload, '/unit');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'unit',
        data: res.data,
      }));
      return res.data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getPerwakilanOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.OPTIONS(payload, '/perwakilan');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'perwakilan',
        data: res.data,
      }));
      return res.data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getFundRequestPeriodeOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.OPTIONS(payload, '/fund-request-periode');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'fundRequestPeriode',
        data: res.data,
      }));
      return res.data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getAgamaOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM(payload, '/agama');
      const data = map(res.result, item => ({
        label: item.name,
        // 'value': item.id, //kalu pakai ini pas di console log valuenya:"undefined"

        value: item.id_agama, // kalu pakai ini pas di console log valuenya:"ada isinya 1,3,3,4,5"
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'agama',
        data,
      }));
      console.log('ini isi data agama');
      console.log(data);
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getMonthOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.OPTIONS(payload, '/month');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'month',
        data: res.data,
      }));
      return res.data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getStaffJabatanOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM(payload, '/jabatan');
      const data = map(res.result, item => ({
        label: item.name,
        value: item.id_jabatan,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'jabatan',
        data,
      }));
      // console.log("ini isi data jabatan")
      // console.log(data)
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getGolonganDarahOptions(payload = {}){
  return async (dispatch) => {
    try{
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM(payload,'/golongandarah');
      const data = map(res.result, (item) =>{
        return{
          'label' : item.name,
          'value': item.id_golongan_darah,
        }
      });

      dispatch(commonActions.getOptionsSucceeded({
        entity:'golongandarah',
        data: data
      }));
      // console.log("ini isi data cuy")

      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  }
}

export function getRegionOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM(payload, '/region');
      const data = map(res.result, item => ({
        label: item.name,
        value: item.id_region,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'region',
        data,
      }));
      // console.log("ini isi data cuy")
      // console.log(data)
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getAktifNonAktifOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM(payload, '/aktifnonaktif');
      const data = map(res.result, item => ({
        label: item.name,
        value: item.id_aktif_non_aktif,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'aktifnonaktif',
        data,
      }));
      // console.log("ini isi data cuy")
      // console.log(data)
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getStatusKepegawaianOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM(payload, '/statuskepegawaian');
      const data = map(res.result, item => ({
        label: item.name,
        value: item.id_status_kepegawaian,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'statuskepegawaian',
        data,
      }));
      // console.log("ini isi kepegawaian cuy")
      // console.log(data)
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getUnitKerjaOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM(payload, '/unitkerja');
      const data = map(res.result, item => ({
        label: item.name,
        value: item.id_unit_kerja,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'unitkerja',
        data,
      }));
      // console.log("ini isi unit kerja cuy")
      // console.log(data)
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}


export function getPangkatGolonganOptions(payload = {}){
  return async (dispatch) => {
    try{
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM(payload,'/golongan');
      const data = map(res.result, (item) =>{
        if(res.message_id==="000"){
          return{
            'label': item.golongan + '-' + item.masa_kerja,
            'value': item.id_golongan
          }
        }else{
          return{
            'label': '',
            'value': ''
          }
        }
      });
      dispatch(commonActions.getOptionsSucceeded({
        entity:'golongan',
        data: data
      }));
      // console.log("ini isi unit Golongannnnnnnnnnn cuy")
      // console.log(data)
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  }
}


export function getJenjangPendidikanOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM(payload, '/jenjangpendidikan');
      const data = map(res.result, item => ({
        label: item.name,
        value: item.id_jenjang_pendidikan,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'jenjangpendidikan',
        data,
      }));
      // console.log("ini isi data csasasuy")
      // console.log(data)
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getStatusKependidikanOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM(payload, '/statuskependidikan');
      const data = map(res.result, item => ({
        label: item.name,
        value: item.id_status_kependidikan,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'statuskependidikan',
        data,
      }));
      // console.log("ini isi data csasasuy")
      // console.log(data)
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getPekerjaanOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM(payload, '/pekerjaan');
      const data = map(res.result, item => ({
        label: item.name,
        value: item.id_pekerjaan,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'pekerjaan',
        data,
      }));
      // console.log("ini isi data csasasuy")
      // console.log(data)
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}
export function getPekerjaanPasanganOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM(payload, '/pekerjaanpasangan');
      const data = map(res.result, item => ({
        label: item.name,
        value: item.id_pekerjaan,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'pekerjaanpasangan',
        data,
      }));
      // console.log("ini isi data csasasuy")
      // console.log(data)
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getJournalCoaOptions(payload = {}) {
  return async (dispatch) => {
    try {
      if (source) {
        source();
      }
      const options = {
        cancelToken: new CancelToken(((c) => {
          source = c;
        })),
      };
      dispatch(commonActions.setLoading({ show: true }));
      dispatch(commonActions.getOptionsRequested());
      const res = await api.FINANCE_JOURNAL_POST(payload, '/coa')
        .then((result) => {
          if (result) {
            dispatch(commonActions.getOptionsSucceeded({
              entity: 'coa',
              data: result.data,
            }));
          }
          return result;
        })
        .catch(() => {
          dispatch(commonActions.getOptionsFailed());
        });
      if (res) {
        return res.data;
      }
      return {};
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getPTKPOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM(payload, '/ptkp');
      const data = map(res.result, item => ({
        label: item.keterangan,
        value: item.id_ptkp,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'ptkp',
        data,
      }));
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getJournalSourceOptions(payload = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      dispatch(commonActions.getOptionsRequested());
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      const { user_group } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      const res = await api.OPTIONS({ ...payload, user_groups_id: user_group.id, unit_id }, '/source-journals')
        .then((result) => {
          if (result) {
            dispatch(commonActions.getOptionsSucceeded({
              entity: 'journal_source',
              data: result.data,
            }));
          }
          return result;
        })
        .catch(() => {
          dispatch(commonActions.getOptionsFailed());
        });
      if (res) {
        return res.data;
      }
      return {};
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}


export function getBudgetPeriodeOptions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.OPTIONS(payload, '/budget-periode');
      const { data } = res;
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'periode',
        data,
      }));
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}


export function getPaymentOptions({ value = '', type = '' }) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, school_unit = {} } = user;
      let unit_id = school_unit ? school_unit.va_code : [];

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.attributes.va_code || [];
      }

      dispatch(commonActions.getOptionsRequested());
      const res = await api.PAYMENT_OPTIONS({ value, unit_id }, `/${type}`);
      dispatch(commonActions.getOptionsSucceeded({
        entity: type,
        res,
      }));
      return res;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getParamOptions(payload = {}, type = '') {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      // if(type === 'classes'){
      //   payload.filters = {
      //     organizations_id: 3
      //   };
      // }
      let defaultValue = {
        id: '0',
        title: 'SEMUA',
        value: '0',
        label: 'SEMUA'
      };
      const res = await api.ACADEMICS_PARAM(payload, `/param/${type}`);
      const data = map(res, item => ({
        label: item.name,
        value: item.id || item.name,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: type,
        data,
      }));
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getParamOptionsAcademics(payload = {}, type = '') {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      // if(type === 'classes'){
      //   payload.filters = {
      //     organizations_id: 3
      //   };
      // }
      let defaultValue = {
        id: '0',
        title: 'SEMUA',
        value: '0',
        label: 'SEMUA'
      };
      const res = await api.ACADEMICS_PARAM({ filters: {
         ...payload,
        }}, `/param/${type}`);
      const data = map(res, item => ({
        label: item.name,
        value: item.id || item.name,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: type,
        data,
      }));
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getStudentOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id} = user;
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM(payload, '/'+organizations_id+'/student/student')
        .then(result => result)
        .catch();
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getMsTempsSiswaOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id} = user;
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM(payload, '/'+organizations_id+'/student/ms_temps_siswa')
        .then(result => result)
        .catch();
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getStudentSPPOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id} = user;
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM(payload, '/'+organizations_id+'/student/student_spp')
        .then(result => result)
        .catch();
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getTeacherOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id} = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM({...payload}, '/'+organizations_id+'/teacher')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getClasses(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id} = user;
      dispatch(commonActions.getOptionsRequested());
      // const { filters } = payload;
      // const res = await api.PARAM_AKADEMI({
      //   //  ...filters, organizations_id: 3 }, '/classes');
      //   filters,
      // },
      // '/classes');

      const res = await api.PARAM_AKADEMI({ ...payload, organizations_id: organizations_id }, '/classes');
      const data = map(res, item => ({
        label: item.name,
        value: item.id,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'classes',
        data,
      }));
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getLevels(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const { id } = payload;
      const res = await api.PARAM_AKADEMI({
        filters: {
          id,
        },
      },
      '/levels');
      const data = map(res, item => ({
        label: item.name,
        value: item.id,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'levels',
        data,
      }));
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getFolderOptions(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/folder')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getSubjectOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id} = user;
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM(payload, '/'+organizations_id+'/subject')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getEkskulDayOptions(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/ekskul_day')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getEkskulOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id} = user;
      dispatch(commonActions.setLoading({ show: true }));
      // const res = await api.ACADEMICS(payload, '/options/ekstrakulikuler')
      const res = await api.ACADEMICS_PARAM(payload, '/'+organizations_id+'/ekstrakulikuler')

        .then(result => result)
        .catch();
      console.log(res);
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getTeacherEkskulOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id} = user;
      dispatch(commonActions.setLoading({ show: true }));
      // const res = await api.ACADEMICS(payload, '/options/teacher_ekskul')
      const res = await api.ACADEMICS_PARAM(payload, '/'+organizations_id+'/teacher_ekskul')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getCounselingPlaceOptions(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/counseling_place')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}
export function getClassesOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id} = user;
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM(payload, '/'+organizations_id+'/classes')
        .then(result => result)
        .catch();
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getClassroomsOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id} = user;
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM(payload, '/'+organizations_id+'/classrooms')
        .then(result => result)
        .catch();
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getMajorOptions(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/major')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getMajorGroupOptions(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/major-group')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}


export function getSubjectTypeOptions(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/subject_type')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}







export function getNoInvoices(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id} = user;
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM(payload, '/'+organizations_id+'/no_invoices')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getStatusJenisSPP(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/status_jenisspp')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getStudentUserIDOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id} = user;
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM(payload, '/'+organizations_id+'/student/student_userid')
        .then(result => result)
        .catch();
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getClassroomOptions(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id} = user;
      dispatch(commonActions.getOptionsRequested());
      // if(type === 'classes'){
      //   payload.filters = {
      //     organizations_id: 3
      //   };
      // }
      let defaultValue = {
        id: '0',
        title: 'SEMUA',
        value: '0',
        label: 'SEMUA'
      };
      const res = await api.ACADEMICS_PARAM({ filters: {
         ...payload,
         organizations_id: organizations_id,
        }}, `/`+organizations_id+`/prm_classrooms`);
      const data = map(res, item => ({
        label: item.name,
        value: item.id || item.name,
        attributes: item.attributes || [],
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'prm_classrooms',
        data,
      }));
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getStatusOptions(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/prm_status')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getNewStudentOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id} = user;
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM(payload, '/'+organizations_id+'/student/new_student')
        .then(result => result)
        .catch();
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getTypeExam(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/type_exam')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getTeacherSubjectsOptions(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/teacher_subjects')
        .then(result => result)
        .catch();
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getTypeFormula(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/type_formula')
        .then(result => result)
        .catch();
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getSubjectPsikAfekOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id} = user;
      dispatch(commonActions.setLoading({ show: true }));
      // const res = await api.ACADEMICS(payload, '/options/subject_psik')
      const res = await api.ACADEMICS_PARAM(payload, '/'+organizations_id+'/subject_psik')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getViolationsGroupOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id} = user;
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/'+organizations_id+'/prm_violations_group')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getAchievementGroupOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id} = user;
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/'+organizations_id+'/prm_achievement_group')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getViolationsOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id} = user;
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/'+organizations_id+'/prm_violations')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getAchievementOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id} = user;
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/'+organizations_id+'/prm_achievement')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getSubjectGroupOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id} = user;
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/'+organizations_id+'/subject_group')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getPrmPeriodsOptions(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/periods')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getRppOptions(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/rpp_types')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getKdThemeOptions(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/kd_theme_types')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getPeriodsOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id, school_unit} = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      // payload.units_id = school_unit.id;
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM({...payload, organizations_id}, '/'+organizations_id+'/periods')
        .then(result => result)
        .catch();
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getCitizenships(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM_AKADEMI(payload, '/citizenships');
      const data = map(res, item => ({
        label: item.name,
        value: item.id,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'citizenships',
        data,
      }));
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getReligions(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM_AKADEMI(payload, '/religions');
      const data = map(res, item => ({
        label: item.name,
        value: item.id,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'religions',
        data,
      }));
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getEducations(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM_AKADEMI(payload, '/educations');
      const data = map(res, item => ({
        label: item.name,
        value: item.id,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'educations',
        data,
      }));
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getOccupations(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM_AKADEMI(payload, '/occupations');
      const data = map(res, item => ({
        label: item.name,
        value: item.id,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'occupations',
        data,
      }));
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getGenders(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM_AKADEMI(payload, '/genders');
      const data = map(res, item => ({
        label: item.name,
        value: item.id,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'genders',
        data,
      }));
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getBloodTypes(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM_AKADEMI(payload, '/blood_types');
      const data = map(res, item => ({
        label: item.name,
        value: item.id,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'blood_types',
        data,
      }));
      console.log(data)
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}
export function getProvinces(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const { id } = payload;
      const res = await api.PARAM_AKADEMI({
        filters: {
          id,
        },
      },
      '/provinces');

      const data = map(res, item => ({
        label: item.name,
        value: item.id,
        data: {...item}
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'provinces',
        data,
      }));
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getCities(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const { filters } = payload;

      const res = await api.PARAM_AKADEMI({
        filters,
      },
      '/cities');
      const data = map(res, item => ({
        label: item.name,
        value: item.id,
        data: {...item}
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'cities',
        data,
      }));
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getDistricts(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const { filters } = payload;

      const res = await api.PARAM_AKADEMI({
        filters,
      },
      '/districts');
      const data = map(res, item => ({
        label: item.name,
        value: item.id,
        data: {...item}

      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'districts',
        data,
      }));
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}


export function getSubDistricts(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const { filters } = payload;

      const res = await api.PARAM_AKADEMI({
        filters,
      },
      '/sub_districts');
      const data = map(res, item => ({
        label: item.name,
        value: item.id,
        data: {...item}
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'sub_districts',
        data,
      }));
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}



export function getGuardians(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.PARAM_AKADEMI(payload, '/guardians');
      const data = map(res, item => ({
        label: item.name,
        value: item.id,
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'guardians',
        data,
      }));
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getStudentExitOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id} = user;
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM(payload, '/'+organizations_id+'/student/student_exit')
        .then(result => result)
        .catch();
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getStudentMoveOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id} = user;
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM(payload, '/'+organizations_id+'/student/student_move')
        .then(result => result)
        .catch();
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getAllStudentOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id} = user;
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM(payload, '/'+organizations_id+'/student/all_student')
        .then(result => result)
        .catch();
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getPrmClassesOptions(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/prm_classes')
        .then(result => result)
        .catch();
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getDayOptions(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/day')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getTypeExamScoreList(payload = {}) {
  return async (dispatch) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS(payload, '/options/type_exam_score_list')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}



export function getUnitsOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id} = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      // const res = await api.ACADEMICS(payload, '/options/teacher_ekskul')
      const res = await api.ACADEMICS_PARAM({...payload}, '/'+organizations_id+'/prm_units')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getMonthAcademicsOptions(payload = {}) {
  return async (dispatch) => { 
    try {
      dispatch(commonActions.getOptionsRequested());
      const res = await api.ACADEMICS_PARAM(payload, '/get_month');
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'month',
        data: res.data,
      }));
      return res;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getPrmAspectOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id} = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM({...payload}, '/'+organizations_id+'/prm_aspect')
        .then(result => result)
        .catch();
      console.log(res);
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}


export function getKDMaterial(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id} = user;
      dispatch(commonActions.getOptionsRequested());
      const res = await api.ACADEMICS_PARAM(payload, '/'+organizations_id+'/prm_kd');

      return res;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getPrmTema(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id} = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM({...payload}, '/'+organizations_id+'/prm_tema')
        .then(result => result)
        .catch();
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getYearsOfEducationsOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id, school_unit} = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      payload.units_id = school_unit.id
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM({...payload, organizations_id}, '/'+organizations_id+'/years_of_education')
        .then(result => result)
        .catch();
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getPrmSubTema(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id} = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM({...payload}, '/prm_sub_tema')
        .then(result => result)
        .catch();
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getPrmPayments(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id} = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM({...payload, organizations_id}, '/'+organizations_id+'/prm_name_payments')
        .then(result => result)
        .catch();
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getPrmPaymentsNon(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id} = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM({...payload, organizations_id}, '/'+organizations_id+'/prm_name_payments_non_rutin')
        .then(result => result)
        .catch();
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getStudentVaOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id} = user;
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM(payload, '/'+organizations_id+'/student/student_va')
        .then(result => result)
        .catch();
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getPrmLevels(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id} = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM({...payload}, '/levels')
        .then(result => result)
        .catch();
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getClassroomNextOptions(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id} = user;
      dispatch(commonActions.getOptionsRequested());
      // if(type === 'classes'){
      //   payload.filters = {
      //     organizations_id: 3
      //   };
      // }
      let defaultValue = {
        id: '0',
        title: 'SEMUA',
        value: '0',
        label: 'SEMUA'
      };
      const res = await api.ACADEMICS_PARAM(payload, `/`+organizations_id+`/classrooms_next`);
      const data = map(res, item => ({
        label: item.name,
        value: item.id || item.name,
        graduation: item.graduation,
        prev_classes_id: item.classes_id,
        attributes: item.attributes || [],
      }));
      dispatch(commonActions.getOptionsSucceeded({
        entity: 'classrooms_next',
        data,
      }));
      return data;
    } catch (err) {
      dispatch(commonActions.getOptionsFailed());
      throw err;
    }
  };
}

export function getStudentPPDB(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, school_unit} = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM({...payload}, '/student/'+school_unit.ppdb_name+'/ppdb')
        .then(result => result)
        .catch();
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getStudentNextClass(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, school_unit} = user;
      let unit_id = prm_school_units_id;
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM({...payload}, '/student/next-class')
        .then(result => result)
        .catch();
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getUsernameOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { organizations_id} = user;
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM(payload, '/'+organizations_id+'/user')
        .then(result => result)
        .catch();
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getNewStudentPPDBOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id} = user;
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM(payload, '/'+organizations_id+'/student/new_student_ppdb')
        .then(result => result)
        .catch();
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getUnitsVaCodeOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id} = user;
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM(payload, '/'+organizations_id+'/units-va-code')
        .then(result => result)
        .catch();
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}
export function getSchoolUnitsOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id} = user;
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM(payload, '/'+organizations_id+'/units')
        .then(result => result)
        .catch();
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getStudentPeriodOptions(payload = {}) {
  return async (dispatch, getState) => {
    if (source) {
      source();
    }
    const options = {
      cancelToken: new CancelToken(((c) => {
        source = c;
      })),
    };

    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id, organizations_id} = user;
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.ACADEMICS_PARAM(payload, '/'+organizations_id+'/student/student_period')
        .then(result => result)
        .catch();
      return res;
      
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}
