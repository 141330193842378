import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, map, isEqual } from "lodash";
import {
  commaFormatted,
  normalizeAmount,
  formatData,
} from "../../../utils/transformer.util";
import {
  Button,
  Pagination,
  Input,
  Select,
} from "../../../components/base/index";
import PengisianKompetensiDasarForm from "./components/PengisianKompetensiDasarForm.component";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class PengisianKompetensiDasar extends Component {
  constructor(props) {
    super(props);
    this._onFormChange = this._onFormChange.bind(this);
    this._onClickAdd = this._onClickAdd.bind(this);
    this._onClickAspek = this._onClickAspek.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._setForm = this._setForm.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this._onDeleteList = this._onDeleteList.bind(this);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._getPrmAspectOptions = this._getPrmAspectOptions.bind(this);

    const { user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    this.state = {
      content: initialContent,
      unchecked: {},
      filters: {},
      list: [],
      form: {
        value: {
          // levels_id,
          organizations_id: 3,
          kd_details: [
            {
            },
          ],
        },
        error: {
          kd_details: "",
        },
      },
      param: {},
    };
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    console.log(state);
    if ((!isEdit || !id) && !isEqual(prevProps, this.props)) {
      this._onSearchContent({ page: 1 });
    }
  }

  componentDidMount() {
    const { location, getLevels, getClasses, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id, units_id } = school_unit;
    const { state = {} } = location;
    const { isEdit = false, data } = state;
    const { id } = state.data || "";
    const { content } = this.state;
    this._getPrmAspectOptions();
  }

  async _getPrmAspectOptions() {
    const { getPrmAspectOptions } = this.props;
    const res = await getPrmAspectOptions();
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        prm_aspect: res,
      },
    }));
  }

  async handleGetData(payload = {}) {
    const { handleListAchievement, user } = this.props;
    const { units_id, workingUnit } = user;
    let unit_id = units_id;

    if (unit_id === null && workingUnit && workingUnit.id) {
      unit_id = workingUnit.id;
    }

    const res = await handleListAchievement({ ...payload });

    this.setState({
      list: res,
    });
  }

  onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    this.handleGetData({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  _onClickAdd(data) {
    const { form } = this.state;
    const { kd_details } = form.value;

    kd_details.push({
      indikatorsikap: "",
      detail_nilai: "",
      poin: "",
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          kd_details,
        },
        error: {
          ...prevState.form.error,
        },
      },
    }));
  }

  async _setForm(id) {
    const { handleGetAttitudeIndicator } = this.props;
    try {
      const payload = await handleGetAttitudeIndicator({ id });
      this.setState({
        form: {
          value: {
            id: payload.id,
            development_aspect_id: payload.development_aspect_id,
            semester: payload.semester,
            date: payload.date,
            classrooms_id: payload.classrooms_id,
            hour: payload.hour,
            minute: payload.minute,
            nis: payload.nis,
            name: payload.name,
            type_achievement: payload.type_achievement,
            subject_id: payload.subject_id,
            order_of_achievement: payload.order_of_achievement,
            score: payload.score,
            description: payload.description,
            penalty: payload.penalty,
          },
          error: {
            // details: [],
          },
        },
      });
    } catch (err) {
      // err action
    }
  }

  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    this.setState((prevState) => {
      let newList = [];
      let newListError = [];
      let formattedValue = value;

      if (inputType === "number") {
        formattedValue = normalizeAmount(value);
      }
      if (inputArray) {
        if (type === "checkbox") {
          formattedValue = checked;
        }
        newList = prevState.form.value[fieldName];
        newListError = prevState.form.error[fieldName];
        newList[arrayPosition][name] = formattedValue;
        if (name === "code_of_account") {
          newList[arrayPosition].isCredit = value.type;
        }
        if (!isEmpty(newListError[arrayPosition])) {
          newListError[arrayPosition][name] = "";
        }
      }
      return {
        form: {
          value: {
            ...prevState.form.value,
            ...(inputArray
              ? { [fieldName]: newList }
              : { [name]: formattedValue }),
          },
          error: {
            ...prevState.form.error,
            ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
          },
        },
      };
    });
  }

  async onSubmit() {
    const { history, handleSaveKompetensiDasar } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    const dataForm = form.value;
    await handleSaveKompetensiDasar(value);
    history.push("/dashboard/kurikulum/ulangan/pengisian-observasi-harian");
    history.go(0);
  }

  async onEdit() {
    const { history, handleEditAttitudeIndicator } = this.props;
    const { form } = this.state;
    const { value } = form;
    let canSaveA = false;
    let canSaveB = false;
    const dataTabel = value.pelajaran;
    const dataForm = form.value;
    let a;
    await handleEditAttitudeIndicator(value);
    history.push(
      "/dashboard/kurikulum/ulangan/tambah-penilaian-nilai-kognitif"
    );
    // }
  }

  _renderButtons(data) {
    const { user } = this.props;
    const button = {};

    return (
      <td>
        <div className="table__actions">
          {/* <Button
                  onClick={() => this.onClickEdit(data)}
                  title='Edit'
                /> */}
        </div>
      </td>
    );
  }

  _onDeleteList(idx) {
    this.setState((prevState) => {
      const { kd_details } = prevState.form.value;
      const list = prevState.form.value.kd_details;
      list.splice(idx, 1);
      return {
        ...prevState,
        kd_details: list,
      };
    });
  }

  _onClickAspek() {
    const { history } = this.props;
    history.push("/dashboard/kurikulum/ulangan/pengisian-aspek-perkembangan", {
      isEdit: true,
    });
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    const { user } = this.props;
    const { organizations_id } = user;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        if (name === "levels_id") {
          this._getParamOptionsAcademics("classrooms", {
            filters: { levels_id: value, organizations_id },
          });
        }
        this._onSearchContent();
      }
    );
  }

  async _onSearchContent(params = {}) {
    const { filters } = this.state;

    try {
      const { handleListStudentAttendance, user } = this.props;
      const { school_unit, workingUnit, id, user_group, organizations_id } =
        user;
      const { levels_id, units_id } = school_unit;
      const result = await handleListStudentAttendance({
        ...params,
        organizations_id,
        units_id,
        filters,
      });
      // if (this.isMount) {
      if (result) {
        result.forEach((item) => {
          if (!item.description) {
            item.description = "";
          }
        });
      }
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            absensiharian: result,
          },
        },
      }));

      // }
    } catch (err) {
      // if (this.isMount) {
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            absensiharian: [],
          },
        },
      }));
    }
    // }
  }

  render() {
    const { form, page, list, content, param, filters } = this.state;
    const { kd_details } = form.value;
    const semester = [
      { label: 'Ganjil', value: 'ganjil'},
      { label: 'Genap', value: 'genap'},
    ]

    return (
      
      <div className="student-list">
        <div className="student-list__header">
          <div className="student-list__button">
            <Button
              title="Buat Aspek Perkembangan"
              onClick={this._onClickAspek}
            />
          </div>
        </div>
        <br></br>
        <div className="manage-registration">
          <div className="absensi-rekap__custom-form">
            <div className="absensi-rekap__custom-form-row">
              <div className="absensi-rekap__custom-form-row__field">
                <Select
                  label="Aspek Perkembangan"
                  name="development_aspect_id"
                  onChange={this._onFormChange}
                  placeholder="Pilihan"
                  data={param.prm_aspect}
                  value={form.value.development_aspect_id}
                />
              </div>
              <div className="absensi-rekap__custom-form-column__field">
                <Select
                  name="semester"
                  label="Semester"
                  placeholder={"Pilihan"}
                  onChange={this._onFormChange}
                  data={semester}
                  value={form.value.semester}
                  error={form.error.semester || ""}
                />
              </div>
            </div>

            <PengisianKompetensiDasarForm
              onAddList={this._onClickAdd}
              list={kd_details}
              onDeleteList={this._onDeleteList}
              // onAddList={this._onClickAdd}
              onFormChange={this._onFormChange}
              param={param}
            />

            <div className="student-list__header">
              <div className="student-list__button">
                <Button type="button" title="Simpan" onClick={this.onSubmit} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
PengisianKompetensiDasar.propTypes = {
  handleGetStudent: PropTypes.func,
  handleGetclassrooms: PropTypes.func,
  history: PropTypes.object.isRequired,
};
PengisianKompetensiDasar.defaultProps = {
  handleGetStudent: noop,
  HandleGetclassrooms: noop,
};
