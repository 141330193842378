import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import budgeting from '../../../assets/img/finance/anggaran.png';
import bookkeeping from '../../../assets/img/bookkeeping/jurnal_bank.png';
import absensiharian from '../../../assets/img/akademi/absensi/absensi_harian.png';
import absensirekap from '../../../assets/img/akademi/absensi/absensi_rekap.png';
import isiperbidangstudy from '../../../assets/img/akademi/absensi/isi_perbidang_studi.png';
import laporanabsen from '../../../assets/img/akademi/absensi/laporan_absen_bidang_study.png';
export default class AbsensiSiswa extends PureComponent {
  constructor(props) {
    super(props);
    this.onClickAbsensiHarian = this.onClickAbsensiHarian.bind(this);
    this.onClickAbsensiRekap = this.onClickAbsensiRekap.bind(this);
    this.onClickIsiPerbidang = this.onClickIsiPerbidang.bind(this);
    this.onClickLaporanAbsen = this.onClickLaporanAbsen.bind(this);
  }

  onClickAbsensiHarian() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/absensi-siswa/absensi-harian');
  }

  onClickAbsensiRekap() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/absensi-siswa/absensi-rekap');
  }

  onClickIsiPerbidang() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/absensi-siswa/isi-perbidang-study');
  }

  onClickLaporanAbsen() {
    const { history } = this.props;
    history.push('/dashboard/kesiswaan/absensi-siswa/laporan-absen-bidang-study')
  }


  render() {
    return (
      <div className="akademi">
        {/* <div className="akademi__list">
          <button onClick={this.onClickAbsensiHarian}>
            <img src={absensiharian} alt="AbsensiHarian" />
            <h3>Absensi Harian</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickAbsensiRekap}>
            <img src={absensirekap} alt="AbsensiRekap" />
            <h3>Absensi Rekap</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickIsiPerbidang}>
            <img src={isiperbidangstudy} alt="IsiPerbidang" />
            <h3>Isi Perbidang Study</h3>
          </button>
        </div>
        <div className="akademi__list">
          <button onClick={this.onClickLaporanAbsen}>
            <img src={laporanabsen} alt="LaporanAbsen" />
            <h3>Laporan Absen Bidang Study</h3>
          </button>
        </div>
         */}
      
      </div>
    );
  }
}
AbsensiSiswa.propTypes = {
  history: PropTypes.object.isRequired,
};
