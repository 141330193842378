import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, noop, map, fromPairs } from 'lodash';
import StudentReportFilter from '../../../components/Filter/StudentReport.filter.component';
import ListForm from '../../../components/ListForm/ListForm.component';
import ContentTable from '../../../components/ContentTable/ContentTable.component';
import { ABSENSI_HARIAN } from '../../../constants/student.constant';
import { ABSENSI_HARIAN_TABLE } from '../../../constants/student.constant';
import { validateRequiredFields } from '../../../utils/validation.util';
import { commaFormatted, normalizeAmount, formatData } from '../../../utils/transformer.util';
import { Button, Pagination, Input } from '../../../components/base/index';
import { GROUP_PELANGGARAN_FORM_FIELDS} from '../../../constants/Student/displiner.constant';
import language from '../../../languages';
import { TABLE_LIST_GROUP_MATPEL_TABLE_FIELDS} from '../../../constants/kurikulum/kurikulum.constant';

export default class BuatGroupMatpel extends Component {
    constructor(props) {
        super(props);
        this._onClickAdd = this._onClickAdd.bind(this);
        this.onClickEdit = this.onClickEdit.bind(this);
        this._onClickAddGroup = this._onClickAddGroup.bind(this);
        this._onFormChange = this._onFormChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this._renderButtons = this._renderButtons.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this._setForm = this._setForm.bind(this);


        this.state= {
          coa: {
            loading: true,
            list: [],
    
          },
          form: {
            value: {
              organizations_id: 3,
            },
            error: {},
          },
        };
      
      }

      componentDidMount() {
        const { location } = this.props;
        const { state = {} } = location;
        const {isEdit =  false, data} = location.state;
        const { id } = state.data || '';
       
        const paramTypes = ['classrooms', 'levels', 'classes'];
        if (isEdit && id) {
          this.setState({id:id})
          this._setForm(data.id);
        }
        
        this.handleGetData();
        // if (id) {
        //   this.setState({id:id})
        //   this._handleLoadData(id)
        // }
        

      }

      async handleGetData(payload = {}) {
        const { handleListSubjectGroup, user } = this.props;
        const { units_id, workingUnit, id, user_group, profile } = user;
        let unit_id = units_id;
    
        if (unit_id === null && workingUnit && workingUnit.id) {
          unit_id = workingUnit.id;
        }
        // if(user_group.id === 2){
          const res = await handleListSubjectGroup({...payload, organizations_id: 3});
    
          this.setState({
            list: res,
          });
        //   console.log(this.state.list)
        }

        async _setForm(id) {
          const { handleGetSubjectGroup } = this.props;
          try {
            const payload = await handleGetSubjectGroup({ id });
            this.setState({
              form: {
                value: {
                  id: payload.id,
                  name: payload.name,
                  
                },
                error: {
                  // details: [],
                },
              },
            });
          } catch (err) {
            // err action
          }
        }
  

    
        _renderButtons(data) {
          const { user } = this.props;
          const button = {};
          
          return (
            <td>
              <div className="table__actions">
               
                <Button
                  onClick={() => this.onClickEdit(data)}
                  title='Edit'
                />
                <Button
                  onClick={() => this.onClickDelete(data)}
                  title='Hapus'
                />
              </div>
            </td>
          );
        }

      _onClickAdd() {
        const { history } = this.props;
        history.push('/dashboard/kurikulum/mata-pelajaran/kode-mata-pelajaran', { data: '' });
      }
    
       onClickEdit(val) {
          const { history } = this.props;
          history.push('/dashboard/kurikulum/mata-pelajaran/buat-group-mata-pelajaran', { data: val, isEdit: true });
          history.go(0)
        }
        async onClickDelete(id) {
          const { handleDeleteSubjectGroup } = this.props;
          const res = await handleDeleteSubjectGroup(id);
          if(res){
            this.handleGetData();
          }
        }
      _onClickAddGroup() {
        const { history } = this.props;
        history.push('/dashboard/kesiswaan/displiner/buat-group');
      }
      _onFormChange(event) {
        const {
          name,
          value,
          dataset,
          checked,
          type,
        } = event.target;
        const {
          inputType = 'text', inputArray = false, arrayPosition = 0,
          fieldName,
        } = dataset;
        this.setState((prevState) => {
          let newList = [];
          let newListError = [];
          let formattedValue = value;
    
          if (inputType === 'number') {
            formattedValue = normalizeAmount(value);
          }
          if (inputArray) {
            if (type === 'checkbox') {
              formattedValue = checked;
            }
            newList = prevState.form.value[fieldName];
            newListError = prevState.form.error[fieldName];
            newList[arrayPosition][name] = formattedValue;
            if (name === 'code_of_account') {
              newList[arrayPosition].isCredit = value.type;
            }
            if (!isEmpty(newListError[arrayPosition])) {
              newListError[arrayPosition][name] = '';
            }
          }
          return {
            form: {
              value: {
                ...prevState.form.value,
                ...(inputArray
                  ? { [fieldName]: newList }
                  : { [name]: formattedValue }),
              },
              error: {
                ...prevState.form.error,
                ...(inputArray
                  ? { [fieldName]: newListError }
                  : { [name]: '' }),
              },
            },
          };
        });
      }

      async onSubmit() {
        const { history, handleSaveSubjectGroup } = this.props;
        const { form } = this.state;
        const { value } = form;        
        let canSaveA = false;
        const dataForm = form.value;
        // if(dataForm.folder_code && dataForm.folder_name && dataForm.folder_description){
        //   canSaveA = true;
        // } else {
        //   canSaveA = false;
        // }
        // if (canSaveA == false){
        //   alert("Harap lengkapi data form. Bagian yang harus diisi ditandai dengan tanda bintang (*).");
        // } else {
          await handleSaveSubjectGroup(value);
          history.push('/dashboard/kurikulum/mata-pelajaran/kode-mata-pelajaran');
        // }
      }

      async onEdit() {
        const { history, handleEditSubjectGroup } = this.props;
        const { form } = this.state;
        const { value } = form;
        const res = await handleEditSubjectGroup(value);        
        history.push('/dashboard/kurikulum/mata-pelajaran/kode-mata-pelajaran');
      }
      
    
      render() {
        const {form, list = {}} = this.state;
        const { handleGetKelas, handleDeleteKelas,coa } = this.props;
        return (
            <div className="add-budget">
        
            <form onSubmit={this.onSubmit}>
              <h1 style={{color:'blue'}}>Buat Grup Pelajaran</h1>
              <hr></hr>
              {/* <ListForm
              form={form}
              formFields={GROUP_PELANGGARAN_FORM_FIELDS}
              onFormChange={this._onFormChange}
              /> */}
                <Input
                label = "Nama Grup"
                name="name"
                value={form.value.name}
                error = {form.error.name||''}
                onChange={this._onFormChange}
              />
               <div className="student-list__button">
               {this.state.id?
                <Button
                  type="button"
                  title="Update"
                  onClick={this.onEdit}
                />:
                <Button
                  type="button"
                  title="Simpan"
                  onClick={this.onSubmit}
                />}
              </div>
             
    
            {/* <div className="budget__content">
              <div className="budget__search">
              <ListForm
              coa={coa}
              form={form}
              formFields={SEARCH_FIELDS}
              onFormChange={this._onFormChange}
              />
    
              </div>
              <ContentTable
                onSearchContent={handleGetKelas}
                tableFields={TABLE_PELANGGARAN_TABLE_FIELDS}
                onClickEdit={this._onClickEdit}
                onClickDelete={handleDeleteKelas}
              />
            </div> */}
          </form>
          <div className="fund-request__content">
          <table className="table">
            <thead>
              <tr>
                {
                    map(TABLE_LIST_GROUP_MATPEL_TABLE_FIELDS.label, (field, idx) => (
                      <th key={`table_th_${idx}`}>{field}</th>
                   ))
                  }
              </tr>
            </thead>
            <tbody>
              {
                  map(list.result, (data, idx) => (
                    <tr key={`budget_row_${idx}`}>
                      {
                      map(TABLE_LIST_GROUP_MATPEL_TABLE_FIELDS.value, (field, fieldIdx) => {
                        if (fieldIdx === 0) {
                          return (
                            <td key={`table_index_${fieldIdx}_${idx}`} style={{textAlign:"center"}}>{idx + 1}</td>
                          );
                        }
                        return (
                          <td className={(field.type === 'number') ? 'nominal' : ''} key={`table_${fieldIdx}_${idx}`}>
                            {formatData(data, field)}
                          </td>
                        );
                      })
                    }
                      {this._renderButtons(data)}
                    </tr>
                  ))
                }
            </tbody>
          </table>
        </div>
        <div className="user-management__table-footer">
          <p className="user-management__page-info">
            {`${language.translate.COMPONENT__CONTENT_TABLE__PAGE} ${list.currentPage} ${language.translate.COMPONENT__CONTENT_TABLE__OF} ${list.totalPage}`}
          </p>
          <Pagination
            totalPage={list.totalPage}
            currentPage={list.currentPage}
            onClick={this.onChangePage}
          />
        </div>
          </div>
        );
      }
}
BuatGroupMatpel.propTypes = {
    
  handleGetStudent: PropTypes.func,
  handleDeleteStudent: PropTypes.func,
  history: PropTypes.object.isRequired,
};
BuatGroupMatpel.defaultProps = {
    
  handleGetStudent: noop,
  handleDeleteStudent: noop,
};
